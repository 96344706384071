import React from 'react'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  '@keyframes latidos': {
    from: { transform: 'none' },
    '50%': { transform: 'scale(1.2)' },
    to: { transform: 'none' },
  },
  animate: {
    animation: '$latidos 1.2s infinite',
  },
  root: {
    minWidth: 160,
    '& .logo-icon': {
      height: 30,
      width: 30,
      position: 'absolute',
      // marginRight: theme.spacing(),
      top: 15,
      left: 15,
      transition: theme.transitions.create(['width', 'height'], {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
    '& .react-badge, & .logo-text': {
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
  },
  reactBadge: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    color: '#61DAFB',
  },
  fullWidth: {
    width: '100% !important',
    height: 'unset !important',
  },
  logoTypo: {
    fontFamily: `'ROBOTECH GP', sans-serif`,
    position: 'absolute',
    top: 0,
    left: 55,
  },
}))

function Logo() {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, 'flex items-center')}>
      <div>
        <img className={clsx('logo-icon')} src="assets/images/logos/recmed_heart.svg" alt="logo" />
      </div>
      <Typography className={classes.logoTypo} variant="h3">
        RECMED
      </Typography>
    </div>
  )
}

export default Logo
