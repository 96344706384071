import React from 'react'

import Card from '@material-ui/core/Card'
import FuseLoading from '@recmed-do/template/lib/@fuse/core/FuseLoading'
import Typography from '@material-ui/core/Typography'
import { useStatisticByQuery } from '@recmed/appsync/lib/hooks/queries/use-statistic-by-query'

const PossibleConsultation = React.memo(({ dataset, ...props }) => {
  const { getStatisticBy, loading, statistic } = useStatisticByQuery({ type: 'card' })

  React.useEffect(() => {
    getStatisticBy({
      variables: {
        args: {
          statisticType: 'POSSIBLE_CONSULTATION',
          parameterType: dataset,
        },
      },
    })
  }, [getStatisticBy, dataset])

  if (loading) return <FuseLoading />

  return (
    statistic?.statisticBy?.map(({ title, subtitle }) => (
      <Card className="w-full rounded-8 shadow-none border-1" key={title}>
        <div className="p-8 flex flex-row flex-wrap items-end">
          <div className="">
            <Typography className="h3" color="textSecondary">
              {title}
            </Typography>
            <Typography className="text-24 font-300 leading-none mt-8">{subtitle}</Typography>
          </div>
        </div>
      </Card>
    )) ?? null
  )
})

export { PossibleConsultation }
