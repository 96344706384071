"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fragments = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

var _templateObject;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var fragments = {
  keylist: (0, _graphqlTag["default"])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    fragment KeylistFragment on Keylist {\n      id\n      name\n    }\n  "])))
};
exports.fragments = fragments;