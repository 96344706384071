"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useVideoContext = void 0;

var _react = require("react");

var _VideoProvider = require("../contexts/VideoProvider");

var useVideoContext = function useVideoContext() {
  var context = (0, _react.useContext)(_VideoProvider.VideoContext);

  if (!context) {
    throw new Error('useVideoContext must be used within a VideoProvider');
  }

  return context;
};

exports.useVideoContext = useVideoContext;