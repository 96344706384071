import React from 'react'
import gql from 'graphql-tag'

import _ from '@recmed-do/template/lib/@lodash'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { v4 } from 'uuid'
import { useQuery, useMutation } from '@apollo/client'

import { useForm, FormProvider } from 'react-hook-form'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import FuseLoading from '@recmed-do/template/lib/@fuse/core/FuseLoading'
import CircularProgress from '@material-ui/core/CircularProgress'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import CreatePatientDialog from 'components/molecules/create-patient-modal'
import {
  DialogActions,
  Button,
  FormControl,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  DialogContent,
  Popover,
} from '@material-ui/core'
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles'
import { selectMainTheme } from '@recmed-do/template/lib/app/store/fuse/settingsSlice'

import RadioGroup from 'components/atoms/form-radio'
import { SnackbarContext } from 'components/hocs/with-snackbar'

import { CREATE_EVENT } from '@recmed/appsync/lib/mutations/event'

import { CreateEventForm } from './CreateEventForm'
import { Schema, getDefaultValues } from './schema'
import { CreateAppointmentForm } from './CreateAppointmentForm'
import { yupResolver } from '@hookform/resolvers/yup'
import { RoleEnum } from 'shared/enums/user'
import { EventTypeEnum } from 'shared/enums/event.enum'

const query = gql`
  query GetDataForEventModal {
    keylist {
      eventTypes {
        id
        name
      }
    }
  }
`
const useStyles = makeStyles((theme) => ({
  root: {
    width: '40vw',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
    [theme.breakpoints.up('sm')]: {
      width: 580,
    },
    '&.fullscreen': {
      width: '100vw',
      height: '100vh',
    },
  },
  icon: {
    left: 0,
    marginRight: 20,
  },
}))
const CreateEventModal = ({
  title,
  visible,
  onClose,
  onOk,
  whiteList = [],
  event = {},
  patientId,
  patientName,
  fullScreen,
  anchorEl,
}) => {
  const classes = useStyles()
  const { data, loading: loading1 } = useQuery(query)
  const { mainTheme, roleId, userId } = useSelector((root) => ({
    roleId: root.auth.user.activeOrganization.roleId,
    userId: root.auth.user.user.id,
    mainTheme: selectMainTheme(root),
  }))
  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues: getDefaultValues(event),
  })
  const { watch, handleSubmit, setValue, register } = methods
  const eventType = watch('eventType')

  const [createVisible, setVisible] = React.useState(false)
  const [createEvent, { loading: loading2 }] = useMutation(CREATE_EVENT)
  const { displayMessage } = React.useContext(SnackbarContext)
  const loading = loading1 || loading2

  React.useEffect(() => {
    if (roleId !== RoleEnum.DOCTOR) return
    setValue('doctorId', userId)
  }, [roleId, setValue, userId])

  const handleAction = handleSubmit(async (form) => {
    const { eventType, doctorId, title, event, isVirtual, procedure } = form

    const getPayload = () => {
      switch (eventType) {
        case EventTypeEnum.MEDICAL_CONSULTATION_APPOINTMENT: {
          const { slot, patientId, description } = event
          const [startDate, endDate] = slot.split(',')
          return {
            isVirtual,
            procedure,
            appointment: {
              startDate,
              endDate,
              patientId,
              description,
            },
          }
        }
        case EventTypeEnum.EVENT: {
          const { location, description, allDay, startDate, endDate } = event
          return {
            event: {
              location,
              description,
              allDay,
              startDate: moment(startDate).format(),
              endDate: moment(endDate).format(),
            },
          }
        }
        default:
          throw new Error('Tipo de evento no encontrado')
      }
    }

    try {
      await createEvent({
        variables: {
          data: {
            ...getPayload(),
            id: v4(),
            doctorId,
            eventType,
            title,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        },
      })
      displayMessage({ message: 'Creado correctamente' })
      if (onClose) {
        onClose(true)
      }
      if (onOk) {
        onOk()
      }
    } catch (e) {
      displayMessage({
        message: e,
        variant: 'error',
      })
      console.log(e)
    }
  })

  const handleClose = (event) => {
    if (onClose) {
      onClose()
    }
  }

  const withWhitelist = React.useCallback(
    (data = []) => (whiteList.length ? data.filter(({ id }) => whiteList.includes(id)) : data),
    [whiteList],
  )

  React.useEffect(() => {
    if (!patientId) return
    register('event.patientId')
    setValue('event.patientId', patientId)
  }, [patientId, register, setValue])

  const getTitle = () => {
    if (title) return title

    return patientId ? `Creación de cita para ${patientName}` : `Creación de Eventos`
  }

  return (
    <>
      <MuiThemeProvider theme={mainTheme}>
        {createVisible && <CreatePatientDialog visible onClose={() => setVisible(false)} />}
        <Popover
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={Boolean(visible)}
          disableBackdropClick
          disableEscapeKeyDown
          onClose={handleClose}
          classes={{ paper: classes.root }}
          anchorEl={anchorEl}
        >
          <AppBar position="static" elevation={1}>
            <Toolbar className="flex">
              <Typography variant="subtitle1" color="inherit">
                {getTitle()}
              </Typography>
              <div style={{ flex: 1 }} />
              <IconButton
                content="Agregar paciente"
                size="small"
                color="secondary"
                aria-label="add"
                onClick={() => setVisible(true)}
              >
                <Icon>person_add</Icon>
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent classes={{ root: 'px-16 sm:px-24 mt-12' }}>
            {loading1 ? (
              <FuseLoading />
            ) : (
              <FormProvider {...methods}>
                <Grid container spacing={2}>
                  <FormControl variant="outlined" component={Grid} item xs={6} sm={6}>
                    <RadioGroup
                      list={withWhitelist(_.get(data, 'keylist.eventTypes', []))}
                      name="eventType"
                      title="Tipo de Evento"
                    />
                  </FormControl>
                  {eventType === 'EV' && (
                    <CreateEventForm disabledDoctor={roleId === RoleEnum.DOCTOR} />
                  )}
                  {eventType === 'CO' && (
                    <CreateAppointmentForm
                      hidePatient={Boolean(patientId)}
                      disabledDoctor={roleId === RoleEnum.DOCTOR}
                    />
                  )}
                </Grid>
              </FormProvider>
            )}
          </DialogContent>
          <DialogActions className="justify-between pl-8 sm:pl-16">
            <Button variant="contained" color="primary" onClick={onClose} disabled={loading}>
              Cerrar
            </Button>
            <Button variant="contained" color="primary" onClick={handleAction} disabled={loading}>
              {loading2 ? <CircularProgress size={22} /> : 'Ok'}
            </Button>
          </DialogActions>
        </Popover>
      </MuiThemeProvider>
    </>
  )
}

export default withMobileDialog()(CreateEventModal)
