"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLocalAudioToggle = void 0;

var _react = require("react");

var _useVideoContext2 = require("../../../../hooks/useVideoContext");

var _useIsTrackEnabled = require("../../../../hooks/useIsTrackEnabled");

var useLocalAudioToggle = function useLocalAudioToggle() {
  var _useVideoContext = (0, _useVideoContext2.useVideoContext)(),
      localTracks = _useVideoContext.localTracks;

  var audioTrack = localTracks.find(function (track) {
    return track.kind === 'audio';
  });
  var isEnabled = (0, _useIsTrackEnabled.useIsTrackEnabled)(audioTrack);
  var toggleAudioEnabled = (0, _react.useCallback)(function () {
    if (audioTrack) {
      if (audioTrack !== null && audioTrack !== void 0 && audioTrack.isEnabled) {
        audioTrack.disable();
      } else {
        audioTrack.enable();
      }
    }
  }, [audioTrack]);
  return [isEnabled, toggleAudioEnabled];
};

exports.useLocalAudioToggle = useLocalAudioToggle;