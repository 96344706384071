const FeatureFlagsEnum = Object.freeze({
  ACCOUNTING: 'ACCOUNTING',
  CLINIC_HISTORY: 'CLINIC_HISTORY',
  INVOICE: 'INVOICE',
  MEETINGS: 'MEETINGS',
  SUMMARY_INVOICE: 'SUMMARY_INVOICE',
})

const RoleEnum = Object.freeze({
  DOCTOR: 'DR',
  ASSISTANT: 'AS',
  OWNER: 'OW',
  ADMINISTRATOR: 'AD',
})

export { FeatureFlagsEnum, RoleEnum }
