import React from 'react'

import { Controller, useFormContext } from 'react-hook-form'

import cn from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { FormField } from 'shared/prop-types/form'

const useStyles = makeStyles(() => ({
  typography: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  root: {
    margin: 0,
  },
}))

const DynamicFormSwitch = ({
  id,
  leftLabel = 'No',
  rightLabel = 'Si',
  xs,
  sm,
  disabled,
  title,
  classes: c,
}) => {
  const classes = useStyles()
  const { control } = useFormContext()

  return (
    <Grid item xs={xs} sm={sm} className={c?.root}>
      <Typography className={cn(classes.typography, c?.title)}>{title}</Typography>
      <Typography component="div" className={cn(classes.typography, c?.content)}>
        {leftLabel && <Grid item>{leftLabel}</Grid>}
        <Controller
          control={control}
          name={id}
          render={({ field: { name, value = false, onChange } }) => {
            return (
              <FormControlLabel
                id={name}
                disabled={disabled}
                classes={{ root: classes.root }}
                labelPlacement="start"
                control={
                  <Switch
                    checked={value}
                    onChange={({ target: { checked } }) => {
                      onChange(checked)
                    }}
                  />
                }
                size="medium"
                name={id}
                variant="outlined"
              />
            )
          }}
        />
        {rightLabel && <Grid item>{rightLabel}</Grid>}
      </Typography>
    </Grid>
  )
}

DynamicFormSwitch.propTypes = {
  ...FormField,
}

export { DynamicFormSwitch }
