"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_TURN_TYPES = exports.GET_TURNS = exports.TURN_SCREEN_FRAGMENT = exports.TURN_FRAGMENT = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var TURN_FRAGMENT = (0, _graphqlTag["default"])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  fragment turnFragment on Turn {\n    id\n    code\n    order\n    patientId\n    patientName\n    description\n    statusName\n    statusId\n    typeName\n    typeId\n    showNextButton\n    showCancelButton\n    showAbsentButton\n  }\n"])));
exports.TURN_FRAGMENT = TURN_FRAGMENT;
var TURN_SCREEN_FRAGMENT = (0, _graphqlTag["default"])(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  fragment turnScreenFragment on TurnScreen {\n    organizationId\n    pendingTurns\n    showNextButton\n    showCreateButton\n    activeTurn {\n      ...turnFragment\n    }\n    turns {\n      ...turnFragment\n    }\n  }\n  ", "\n"])), TURN_FRAGMENT);
exports.TURN_SCREEN_FRAGMENT = TURN_SCREEN_FRAGMENT;
var GET_TURNS = (0, _graphqlTag["default"])(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query GetTurns {\n    turnScreen {\n      ...turnScreenFragment\n    }\n  }\n\n  ", "\n"])), TURN_SCREEN_FRAGMENT);
exports.GET_TURNS = GET_TURNS;
var GET_TURN_TYPES = (0, _graphqlTag["default"])(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  {\n    turnTypes {\n      id\n      name\n    }\n  }\n"])));
exports.GET_TURN_TYPES = GET_TURN_TYPES;