import React from 'react'

import { Link, useParams, useHistory } from 'react-router-dom'
import Auth from '@aws-amplify/auth'

import * as yup from 'yup'
import { useForm, FormProvider } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { AuthenticationTemplate } from 'components/templates/authentication-template'
import { DynamicFormInput } from 'components/atoms/dynamic-form-input'
import { useSnackbar } from 'components/hocs/with-snackbar'
import { yupResolver } from '@hookform/resolvers/yup'

const Schema = yup.object().shape({
  username: yup.string().email().required().label('Correo'),
  oldPassword: yup.string().required(),
  password: yup.string().required(),
  confirmationPassword: yup.string().required(),
})

const ChangeOldPassword = () => {
  const history = useHistory()
  const { username, challengeName } = useParams()
  const [loading, setLoading] = React.useState(false)
  const methods = useForm({ resolver: yupResolver(Schema) })
  const { displayMessage } = useSnackbar()
  const { setValue } = methods

  React.useEffect(() => {
    if (!username) {
      history.replace('/')
    }
    setValue('username', username)
  }, [username, setValue, history])

  const handleSubmit = methods.handleSubmit(async (form) => {
    const { username, password, confirmationPassword, oldPassword } = form
    if (confirmationPassword !== password) {
      methods.setError('confirmationPassword', 'custom_error', 'Contraseña no coincide')
      return
    }
    try {
      setLoading(true)
      if (challengeName === 'NEW_PASSWORD_REQUIRED') {
        const user = await Auth.signIn(username, oldPassword)
        await Auth.completeNewPassword(user, password, {
          name: username,
          profile: username,
        })
        displayMessage({ message: 'Contraseña cambiada con éxito.', variant: 'success' })
        history.replace('/')
        return
      }
      const currentUser = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(currentUser, oldPassword, password)
      await Auth.updateUserAttributes(currentUser, {
        name: username,
        profile: username,
        'custom:password_required': 'false',
      })
      displayMessage({ message: 'Contraseña cambiada con éxito.', variant: 'success' })
      history.replace('/')
    } catch (e) {
      console.info('error', e)
    } finally {
      setLoading(false)
    }
  })

  return (
    <AuthenticationTemplate>
      <FormProvider {...methods}>
        <Typography variant="h6" className="mt-16 mb-16">
          INGRESE SUS CREDENCIALES
        </Typography>
        <Grid container spacing={2}>
          <DynamicFormInput id="username" xs={12} sm={12} title="Usuario" />
          <DynamicFormInput
            id="oldPassword"
            xs={12}
            sm={12}
            title="Contraseña Anterior"
            type="password"
          />
          <DynamicFormInput
            id="password"
            xs={12}
            sm={12}
            title="Nueva Contraseña"
            type="password"
          />
          <DynamicFormInput
            id="confirmationPassword"
            xs={12}
            sm={12}
            title="Confirmar Contraseña"
            type="password"
          />
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
          className="w-224 mx-auto mt-16"
          fullWidth
          aria-label="LOG IN"
        >
          Cambiar Contraseña
        </Button>
        {!loading && (
          <Link to="/" className="mx-auto mt-16">
            Volver atras
          </Link>
        )}
        {loading && <CircularProgress variant="indeterminate" className="w-224 mx-auto mt-16" />}
      </FormProvider>
    </AuthenticationTemplate>
  )
}

ChangeOldPassword.propTypes = {}

export { ChangeOldPassword }
