import React from 'react'

import Typography from '@material-ui/core/Typography'
import FuseAnimate from '@recmed-do/template/lib/@fuse/core/FuseAnimate'
import { VisitorsWidget } from './widgets/visitors'
import { MedicationWidget } from './widgets/medications'
import { DiagnosisWidget } from './widgets/diagnosis'
import { AverageWidget } from './widgets/averages'
import { PossibleConsultation } from './widgets/PossibleConsultations'

const Home = () => {
  const [dataset, setDataset] = React.useState('THIS_MONTH')
  return (
    <div className="w-full">
      <VisitorsWidget dataset={dataset} setDataset={setDataset} />
      <FuseAnimate animation="transition.slideUpIn" delay={200}>
        <div className="flex flex-col md:flex-row sm:p-8 container">
          <div className="flex flex-1 flex-col min-w-0">
            <FuseAnimate delay={600}>
              <Typography className="p-16 pb-8 text-18 font-300">
                Tiempo promedio de consulta
              </Typography>
            </FuseAnimate>
            <div className="flex flex-col sm:flex sm:flex-row pb-16">
              <div className="widget flex w-full sm:w-2/3 p-16">
                <AverageWidget dataset={dataset} />
              </div>
              <div className="widget flex w-full sm:w-1/3 p-16">
                <PossibleConsultation dataset={dataset} />
              </div>
            </div>
          </div>
        </div>
      </FuseAnimate>

      <FuseAnimate animation="transition.slideUpIn" delay={200}>
        <div className="flex flex-col sm:flex sm:flex-row pb-32">
          <div className="widget flex w-full sm:w-1/2 p-16">
            <MedicationWidget dataset={dataset} />
          </div>

          <div className="widget  w-full sm:w-1/2 p-16">
            <DiagnosisWidget dataset={dataset} />
          </div>
        </div>
      </FuseAnimate>
    </div>
  )
}

export default Home
