import React from 'react'
import PropTypes from 'prop-types'

import cn from 'clsx'

import MaterialPopover from '@material-ui/core/Popover'
import { MuiThemeProvider } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}))

const Popover = ({
  children,
  className,
  mainTheme,
  delay,
  containerClassname,
  tag,
  content,
  ...props
}) => {
  const timeout = React.useRef()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const Component = React.useMemo(() => tag, [tag])

  const handlePopoverOpen = ({ currentTarget }) => {
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => setAnchorEl(currentTarget), delay)
  }

  const handlePopoverClose = () => {
    clearTimeout(timeout.current)
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <MuiThemeProvider theme={mainTheme || {}}>
      <Component
        className={containerClassname}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        {...props}
      >
        {children}
        <MaterialPopover
          className={cn(classes.popover, className)}
          classes={{
            paper: classes.paper,
          }}
          open={Boolean(open)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          {typeof content === 'function' ? content : <Typography>{content}</Typography>}
        </MaterialPopover>
      </Component>
    </MuiThemeProvider>
  )
}

Popover.propTypes = {
  tag: PropTypes.any,
  delay: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.node]).isRequired,
  content: PropTypes.node,
  className: PropTypes.string,
  containerClassname: PropTypes.string,
  mainTheme: PropTypes.object,
}

Popover.defaultProps = {
  tag: 'div',
  delay: 500,
  content: undefined,
  className: undefined,
  containerClassname: undefined,
}

export { Popover }
