import React from 'react'

import { useFormContext, Controller } from 'react-hook-form'

import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'

import Select from 'components/atoms/select'
import { FormField } from 'shared/prop-types/form'
import { useSelector } from 'react-redux'
import useUsersByOrganization from '@recmed/appsync/lib/hooks/queries/useUsersByOrganization'

const DynamicUsersDropdown = ({
  id,
  xs = 12,
  sm = 6,
  prefix,
  suffix,
  title,
  onChange,
  defaultValue,
  inputProps,
  size,
  roleId,
  disabled,
}) => {
  const { control } = useFormContext()
  const { activeOrganization } = useSelector(({ auth }) => auth.user)
  const { users } = useUsersByOrganization(activeOrganization.organizationId)

  const options = React.useMemo(() => {
    const mapper = ({ id, name }) => ({ name: name, value: id })

    if (!roleId) return users?.map(mapper) ?? []

    const filter = (user) => user.roleId === roleId

    return users?.filter(filter).map(mapper)
  }, [roleId, users])

  return (
    <Controller
      render={({ field: { value = '', ref: __, ...renderProps }, fieldState: { error } }) => {
        return (
          <>
            <FormControl
              variant="outlined"
              component={Grid}
              item
              xs={xs}
              sm={sm}
              id={`grid-${id}`}
              error={error && error.message}
              size={size}
              fullWidth
            >
              <Select
                {...renderProps}
                value={value}
                variant="outlined"
                label={title}
                id={id}
                disabled={disabled}
                error={error}
                helperText={error ? error.message : ''}
              >
                {options.map(({ value, name }) => {
                  return (
                    <MenuItem value={value} key={value}>
                      {name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </>
        )
      }}
      control={control}
      name={id}
    />
  )
}

DynamicUsersDropdown.propTypes = {
  ...FormField,
}

export { DynamicUsersDropdown }
