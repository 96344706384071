import React from 'react'

import queryString from 'query-string'

import { withRouter, useLocation } from 'react-router-dom'

import classNames from 'clsx'
import { withStyles } from '@material-ui/core'
import moment from 'moment-timezone'
import { Calendar as BigCalendar, Views, momentLocalizer } from 'react-big-calendar'
import { CalendarEvents } from './calendar.actions'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import { styles } from './style'
import CalendarHeader from './calendar.header'
import CalendarEventCard from 'components/atoms/CalendarEventCard'

const localizer = momentLocalizer(moment)
BigCalendar.tz = moment.tz.guess()

const allViews = Object.keys(Views).map((k) => Views[k])

function ScheduleViewContent({
  classes,
  setRange,
  handleOpenDialog,
  handleVisibility,
  events = [],
}) {
  const m = React.useRef(null)
  const location = useLocation()
  const search = queryString.parse(location.search)
  const { view = Views.WEEK } = search

  const CustomEvent = ({ event }) => {
    const { resource } = event
    return <CalendarEventCard resource={resource} event={event} />
  }

  const handleViewChange = (view = Views.WEEK) => {
    if (m.current) {
      window.clearTimeout(m.current)
    }

    m.current = window.setTimeout(() => {
      setRange({ view })
    })
  }

  const handleChange = (date) => {
    if (m.current) {
      window.clearTimeout(m.current)
    }

    m.current = window.setTimeout(() => {
      const start = moment(date).startOf(view).startOf()
      const end = moment(date).endOf(view)
      setRange({ start, end, view })
    })
  }

  const eventPropGetter = (event) => {
    const {
      resource: { status },
    } = event
    const styles = {
      CON: { background: 'rgb(44,187,169)' },
      PE: { background: 'rgb(255, 153, 76)', color: 'black' },
      CA: { background: '#f44336' },
      FI: { background: 'rgb(209, 231, 81)', color: 'black' },
    }

    return {
      style: { ...styles[status], borderWidth: 0 },
    }
  }

  const handleEventOpen = ({ start, box, bounds, ...args }) => {
    const getElement = () => {
      switch (view) {
        case Views.WORK_WEEK:
        case Views.DAY:
        case Views.WEEK: {
          const elements = document.elementsFromPoint(
            box?.clientX || bounds.x,
            box?.clientY || bounds.y,
          )
          if (elements.length === 1) {
            return elements[0]
          }
          return Array.from(elements).find(
            (el) =>
              el.classList.contains('rbc-timeslot-group') || el.classList.contains('rbc-event'),
          )
        }

        default:
          return document.elementFromPoint(box?.clientX || bounds.x, box?.clientY || bounds.y)
      }
    }
    const element = getElement()

    handleOpenDialog(
      {
        type: CalendarEvents.CreateEvent,
        event: { startDate: start },
      },
      { target: element },
    )
  }

  const handleOnSelectEvent = (event, e) => {
    handleOpenDialog(
      {
        event,
        type:
          event.resource.type === 'CO'
            ? CalendarEvents.UpdateAppointment
            : CalendarEvents.UpdateEvent,
      },
      e,
    )
  }

  const today = new Date() //Date for the min values or max if needed.

  return (
    <div
      className={classNames(classes.root, 'flex flex-col flex-auto relative')}
      onContextMenu={(e) => e.preventDefault()}
    >
      <BigCalendar
        onNavigate={handleChange}
        // onView={onChange}
        className="flex flex-1 container"
        selectable
        localizer={localizer}
        // onRangeChange={handleRangeChange}
        events={events}
        resizable
        min={new Date(today.getFullYear(), today.getMonth(), today.getDate(), 6)}
        formats={{ timeGutterFormat: 'hh:mm a' }}
        view={view || Views.WEEK}
        defaultView={view.WEEK}
        culture="es"
        // defaultDate={new Date()}
        // defaultDate={new Date(start)}
        timeslots={1}
        eventPropGetter={eventPropGetter}
        startAccessor="start"
        endAccessor="end"
        views={allViews}
        onView={handleViewChange}
        step={15}
        showMultiDayTimes
        components={{
          toolbar: CalendarHeader,
          event: CustomEvent,
        }}
        onSelectEvent={handleOnSelectEvent}
        onSelectSlot={handleEventOpen}
      />
    </div>
  )
}

export default withRouter(withStyles(styles, { withTheme: true })(ScheduleViewContent))
