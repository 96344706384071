import React from 'react'

import { Link, useParams } from 'react-router-dom'
import Auth from '@aws-amplify/auth'

import * as yup from 'yup'
import { useForm, FormProvider } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import { AuthenticationTemplate } from 'components/templates/authentication-template'
import { DynamicFormInput } from 'components/atoms/dynamic-form-input'
import { yupResolver } from '@hookform/resolvers/yup'

const Schema = yup.object().shape({
  username: yup.string().email().required().label('Correo'),
  code: yup.string().required(),
  password: yup.string().required(),
  confirmationPassword: yup.string().required(),
})

const ResetPassword = () => {
  const { username } = useParams()
  const [loading, setLoading] = React.useState(false)
  const methods = useForm({ resolver: yupResolver(Schema) })
  const { setValue } = methods

  React.useEffect(() => {
    setValue('username', username)
  }, [username, setValue])

  const handleSubmit = methods.handleSubmit(async (form) => {
    const { username, password, confirmationPassword, code } = form
    if (confirmationPassword !== password) {
      methods.setError('confirmationPassword', 'custom_error', 'Contraseña no coincide')
      return
    }
    try {
      setLoading(true)
      await Auth.forgotPasswordSubmit(username, code, password)
    } catch (e) {
      console.info('error', e)
    } finally {
      setLoading(false)
    }
  })

  return (
    <AuthenticationTemplate>
      <FormProvider {...methods}>
        <Typography variant="h6" className="mt-16">
          INGRESE SUS CREDENCIALES
        </Typography>
        <Typography variant="button" className="mb-16">
          Cambiar contraseña
        </Typography>
        <Grid container spacing={2}>
          <DynamicFormInput id="username" xs={12} sm={12} title="Usuario" />
          <DynamicFormInput id="code" xs={12} sm={12} title="Código de verificación" />
          <DynamicFormInput
            id="password"
            xs={12}
            sm={12}
            title="Nueva Contraseña"
            type="password"
          />
          <DynamicFormInput
            id="confirmationPassword"
            xs={12}
            sm={12}
            title="Confirmar Contraseña"
            type="password"
          />
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={loading}
          className="w-224 mx-auto mt-16"
          fullWidth
          aria-label="LOG IN"
        >
          Cambiar Contraseña
        </Button>
        {!loading && (
          <Link to="/" className="mx-auto mt-16">
            Volver atras
          </Link>
        )}
        {loading && <CircularProgress variant="indeterminate" className="w-224 mx-auto mt-16" />}
      </FormProvider>
    </AuthenticationTemplate>
  )
}

ResetPassword.propTypes = {}

export { ResetPassword }
