import React from 'react'

import _ from '@recmed-do/template/lib/@lodash'
import { useLazyQuery } from '@apollo/client'

import { GET_INSURANCE_PLANS } from '@recmed/appsync/lib/queries/keylist'

const usePlanKeylist = ({ value, insurance }) => {
  const [text, setText] = React.useState('')
  const [open, setOpen] = React.useState(false)

  const [refetch, { data = {}, loading }] = useLazyQuery(GET_INSURANCE_PLANS, {
    fetchPolicy: 'network-only',
  })
  const options = React.useMemo(() => data.insurancePlans ?? [], [data])

  React.useEffect(() => {
    if (!insurance) {
      return
    }
    refetch({
      variables: {
        args: insurance,
      },
    })
  }, [insurance, refetch])

  const validateSelected = React.useCallback(() => {
    setOpen(false)
    if (!options?.length) return
    const selected = options.find(({ id }) => id === value)
    if (!selected || typeof selected?.name !== 'string') {
      setText('')
      return
    }
    setText(selected.name)
  }, [value, options, setText])

  React.useEffect(validateSelected, [validateSelected])

  return {
    validateSelected,
    open,
    setText,
    text,
    loading,
    options,
    setOpen,
  }
}

export { usePlanKeylist }
