import React from 'react'

import _ from '@recmed-do/template/lib/@lodash'
import { useFormContext, Controller } from 'react-hook-form'

import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'

import { KeylistSelect } from 'components/atoms/keylist-select'
import { FormField } from 'shared/prop-types/form'

const FormKeylistSelect = ({ id, xs = 12, sm = 6, title, defaultValue, type, ...props }) => {
  const { control } = useFormContext()

  return (
    <FormControl
      component={Grid}
      item
      xs={xs}
      sm={sm}
      id={`grid-${id}`}
      style={{ display: 'flex' }}
    >
      <Controller
        render={({ field: { value = defaultValue, ...rest }, fieldState: { error } }) => (
          <KeylistSelect
            title={title}
            value={value}
            error={error}
            keylistName={type}
            {...props}
            {...rest}
          />
        )}
        name={id}
        control={control}
      />
    </FormControl>
  )
}

FormKeylistSelect.propTypes = {
  ...FormField,
}

export { FormKeylistSelect }
