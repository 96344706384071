import * as React from 'react'

import Calendar from './calendar'
import Analitics from './Analitics'

export const dashboardConfig = {
  setting: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/dashboard',
      viewPermission: 'DH-ES-VO',
      component: Analitics,
    },
    {
      path: '/calendar',
      viewPermission: 'DH-AG-VO',
      component: Calendar,
    },
    // {
    //   path: '/todo',
    //   viewPermission: 'DH-TD-VO',
    //   component: React.lazy(() => import('./Analitics')),
    // },
  ],
}

export const dashboardNavigation = {
  id: 'dashboard',
  title: 'Dashboard',
  viewPermission: 'DH-VO',
  type: 'collapse',
  icon: 'dashboard',
  children: [
    {
      id: 'analitics',
      title: 'Analiticas',
      type: 'item',
      viewPermission: 'DH-ES-VO',
      icon: 'show_chart',
      url: '/dashboard',
    },
    {
      id: 'calendar',
      title: 'Agenda',
      type: 'item',
      viewPermission: 'DH-AG-VO',
      icon: 'today',
      url: '/calendar',
    },
    // {
    //   id: 'todo',
    //   title: 'Todo',
    //   type: 'item',
    //   viewPermission: 'DH-TD-VO',
    //   icon: 'assignment',
    //   url: '/todo',
    // },
  ],
}
