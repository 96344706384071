import { ThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectNavbarTheme } from '@recmed-do/template/lib/app/store/fuse/settingsSlice'

import NavbarStyle1 from '../molecules/NavbarStyle'

function NavbarWrapperLayout1(props) {
  const navbarTheme = useSelector(selectNavbarTheme)

  return (
    <>
      <ThemeProvider theme={navbarTheme}>
        <NavbarStyle1 />
      </ThemeProvider>
    </>
  )
}

export default React.memo(NavbarWrapperLayout1)
