import * as yup from 'yup'
import moment from 'moment'

const Schema = yup.object().shape({
  doctorId: yup.string().required(),
  eventType: yup.string().required().default('CO'),
  title: yup.mixed().when('eventType', {
    is: 'CO',
    then: yup.string(),
    otherwise: yup.string().required(),
  }),
  event: yup
    .mixed()
    .when('eventType', {
      is: 'EV',
      then: yup.object().shape({
        location: yup.string().required(),
        description: yup.string().required(),
        allDay: yup.boolean(),
        startDate: yup.mixed().when('allDay', {
          is: (value) => Boolean(value),
          then: yup.string().required(),
          otherwise: yup.date().min(moment(new Date()).startOf('day').toDate()).required(),
        }),
        endDate: yup.mixed().when('allDay', {
          is: (value) => Boolean(value),
          then: yup.string(),
          otherwise: yup
            .date()
            .min(moment(new Date()).startOf('day').toDate())
            .required()
            .nullable(),
        }),
      }),
      otherwise: yup.object().shape({
        patientId: yup.string().required(),
        procedure: yup.string(),
        description: yup.string(),
        date: yup.date().min(moment(new Date()).startOf('day').toDate()).required(),
        slot: yup.string().required(),
      }),
    })
    .required()
    .default({}),
})

const getDefaultValues = ({ eventType = 'CO', startDate = new Date(), patientId }) => ({
  ...Schema.default(),
  eventType,
  event: {
    date: startDate,
    startDate,
    patientId,
  },
})

export { Schema, getDefaultValues }
