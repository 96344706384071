"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CREATE_PATIENT_LABORATORY_PRESCRIPTION = exports.CREATE_PATIENT_MEDICAL_PRESCRIPTION = exports.UPSERT_PATIENT_NOTE = exports.UPLOAD_PATIENT_FILE = exports.UPDATE_PATIENT = exports.CREATE_MEDICAL_HISTORY = exports.CREATE_PATIENT = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var CREATE_PATIENT = (0, _graphqlTag["default"])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation createPatient($args: CreatePatientInput!) {\n    recmedCreatePatient(args: $args) {\n      id\n    }\n  }\n"])));
exports.CREATE_PATIENT = CREATE_PATIENT;
var CREATE_MEDICAL_HISTORY = (0, _graphqlTag["default"])(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation CreateMedicalHistory($data: MedicalHistoryCreateInput!) {\n    createMedicalHistory(data: $data) {\n      id\n      uid\n    }\n  }\n"])));
exports.CREATE_MEDICAL_HISTORY = CREATE_MEDICAL_HISTORY;
var UPDATE_PATIENT = (0, _graphqlTag["default"])(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation updatePatient($args: UpdatePatientInput!) {\n    updatePatient(args: $args) {\n      id\n    }\n  }\n"])));
exports.UPDATE_PATIENT = UPDATE_PATIENT;
var UPLOAD_PATIENT_FILE = (0, _graphqlTag["default"])(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation uploadPatientFile($args: PatientUploadFileInput!) {\n    uploadPatientFile(args: $args)\n  }\n"])));
exports.UPLOAD_PATIENT_FILE = UPLOAD_PATIENT_FILE;
var UPSERT_PATIENT_NOTE = (0, _graphqlTag["default"])(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation UpsertNote($args: UpsertPatientNoteInput!) {\n    upsertPatientNote(args: $args)\n  }\n"])));
exports.UPSERT_PATIENT_NOTE = UPSERT_PATIENT_NOTE;
var CREATE_PATIENT_MEDICAL_PRESCRIPTION = (0, _graphqlTag["default"])(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  mutation CreatePatientMedicalPrescription(\n    $patientId: ID!\n    $args: SubmitMedicalConsultationPrescription!\n  ) {\n    createPatientMedicalPrescription(patientId: $patientId, args: $args)\n  }\n"])));
exports.CREATE_PATIENT_MEDICAL_PRESCRIPTION = CREATE_PATIENT_MEDICAL_PRESCRIPTION;
var CREATE_PATIENT_LABORATORY_PRESCRIPTION = (0, _graphqlTag["default"])(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  mutation CreatePatientLaboratoryPrescription(\n    $patientId: ID!\n    $args: SubmitMedicalConsultationLaboratoriesInput!\n  ) {\n    createPatientLaboratoryPrescription(patientId: $patientId, args: $args)\n  }\n"])));
exports.CREATE_PATIENT_LABORATORY_PRESCRIPTION = CREATE_PATIENT_LABORATORY_PRESCRIPTION;