"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SUBMIT_MEDICAL_CONSULTATION = exports.START_MEDICAL_CONSULTATION = exports.CREATE_MEDICAL_CONSULTATION = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

var _templateObject, _templateObject2, _templateObject3;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var CREATE_MEDICAL_CONSULTATION = (0, _graphqlTag["default"])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation CreateMedicalConsultation($args: CreateMedicalConsultationInput!) {\n    createMedicalConsultation(args: $args)\n  }\n"])));
exports.CREATE_MEDICAL_CONSULTATION = CREATE_MEDICAL_CONSULTATION;
var START_MEDICAL_CONSULTATION = (0, _graphqlTag["default"])(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation StartMedicalConsultation($args: StartMedicalConsultationInput!) {\n    startMedicalConsultation(args: $args)\n  }\n"])));
exports.START_MEDICAL_CONSULTATION = START_MEDICAL_CONSULTATION;
var SUBMIT_MEDICAL_CONSULTATION = (0, _graphqlTag["default"])(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation SubmitMedicalConsultation($args: SubmitMedicalConsultationInput!) {\n    submitMedicalConsultation(args: $args)\n  }\n"])));
exports.SUBMIT_MEDICAL_CONSULTATION = SUBMIT_MEDICAL_CONSULTATION;