"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useHandleOnDisconnect = void 0;

var _react = require("react");

var useHandleOnDisconnect = function useHandleOnDisconnect(room, onDisconnect) {
  (0, _react.useEffect)(function () {
    room.on('disconnected', onDisconnect);
    return function () {
      room.off('disconnected', onDisconnect);
    };
  }, [room, onDisconnect]);
};

exports.useHandleOnDisconnect = useHandleOnDisconnect;