import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment-timezone'
import cn from 'clsx'

import { red, green } from '@material-ui/core/colors'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import AssignmentIcon from '@material-ui/icons/Assignment'

import { Popover } from 'components/atoms/Popover'
import { PopConfirm } from 'components/atoms/PopConfirm'
import { Countdown } from 'components/organisisms/countdown'
import { FloatingButton } from 'components/atoms/flating-button'

import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom'
import { useFinishTurn } from 'components/hooks/use-finish-turn'

const useStyles = makeStyles((theme) => ({
  countdown: {
    position: 'absolute',
    right: 0,
    top: 140,
    opacity: 0.9,
    zIndex: 999,
    '&:hover': {
      opacity: 1,
    },
    '& h5': {
      color: 'white',
    },
  },
  button: {
    position: 'absolute',
    right: 0,
    top: 200,
    minWidth: 48,
    width: 48,
    height: 48,
    opacity: 0.9,
    padding: 0,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    zIndex: 999,
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[500],
      opacity: 1,
    },
  },
  turnButton: {
    top: 248,
  },
  green: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[500],
      opacity: 1,
    },
    '& svg': {
      color: 'white',
    },
  },
}))

const whiteList = ['/patient/medical-consultation/', '/turns/display']

const PendingMedicalConsultationLayout = ({ id, status, startedAt, duration, turnId }) => {
  const classes = useStyles()
  const history = useHistory()
  const [visible, setVisible] = React.useState(false)
  const { finishTurn } = useFinishTurn({ id: turnId })

  React.useEffect(() => {
    setVisible(
      () => (id || turnId) && !whiteList.some((path) => history.location.pathname.startsWith(path)),
    )
    history.listen(({ pathname }) => {
      setVisible(() => (id || turnId) && !whiteList.some((path) => pathname.startsWith(path)))
    })
  }, [history, id, turnId])

  if (!visible) return null

  return (
    <>
      {startedAt && (
        <div className={classes.countdown}>
          <Countdown
            start={Boolean(startedAt)}
            endDate={moment(startedAt || new Date()).add(duration, 'minutes')}
          />
        </div>
      )}
      {id && (
        <PopConfirm
          tag={Button}
          question="Desea ir a la vista de consulta médica?"
          onOk={() => history.push(`/patient/medical-consultation/${id}`)}
          id="fuse-settings"
          containerClassname={cn(classes.button, {
            [classes.green]: status === 'STARTED',
          })}
          variant="contained"
        >
          <Popover content="Ir Consulta Médica">
            <AssignmentIcon />
          </Popover>
        </PopConfirm>
      )}
      {turnId && (
        <FloatingButton
          onOk={finishTurn}
          className={cn(classes.button, classes.turnButton)}
          title="Finalizar consulta"
          question={
            id
              ? 'Desea finalizar esta consulta sin llenar ningun formulario?'
              : 'Desea finalizar este turno?'
          }
        >
          <Icon>close</Icon>
        </FloatingButton>
      )}
    </>
  )
}

PendingMedicalConsultationLayout.propTypes = {
  id: PropTypes.string,
  turnId: PropTypes.string,
  status: PropTypes.string,
  startedAt: PropTypes.string,
  duration: PropTypes.number,
}

export { PendingMedicalConsultationLayout }
