import React from 'react'

import { useFormContext, Controller } from 'react-hook-form'

import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'

import Select from 'components/atoms/select'
import { FormField } from 'shared/prop-types/form'

const DynamicFormDropdown = ({
  id,
  xs = 12,
  sm = 6,
  prefix,
  suffix,
  title,
  onChange,
  defaultValue,
  inputProps,
  options = [],
  size,
}) => {
  const { control } = useFormContext()

  return (
    <Controller
      render={({ fieldState: { error }, field: { value = '', ref: __, ...renderProps } }) => {
        return (
          <FormControl
            variant="outlined"
            component={Grid}
            item
            xs={xs}
            sm={sm}
            id={`grid-${id}`}
            error={error && error.message}
            size={size}
          >
            <Select
              {...renderProps}
              value={value}
              variant="outlined"
              label={title}
              id={id}
              error={error}
              helperText={error ? error.message : ''}
            >
              {options.map(({ value, name }) => {
                return (
                  <MenuItem value={value} key={value}>
                    {name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        )
      }}
      control={control}
      name={id}
    />
  )
}

DynamicFormDropdown.propTypes = {
  ...FormField,
}

export { DynamicFormDropdown }
