import React from 'react'

import { FormHelperText } from '@material-ui/core'
import { useFormContext, Controller } from 'react-hook-form'

import { FormControl, Grid } from '@material-ui/core'

import withError from 'components/hocs/with-errors'
import { PatientLookup } from 'components/atoms/patient-lookup'

const FormPatientLookup = ({ helperText, registerOptions, xs = 12, sm = 12, ...props }) => {
  const { name } = props
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl component={Grid} item xs={xs} sm={sm} error={helperText}>
          <PatientLookup onChange={onChange} value={value} {...props} error={error} />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

export default withError(FormPatientLookup)
