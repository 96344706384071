import FuseLoadable from 'shared/utils/FuseLoadable'

export const turnDisplayConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: '/turns/display',
      component: FuseLoadable({
        loader: () => import('./display'),
      }),
    },
  ],
}

export const turnsConfig = {
  setting: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/turns/general',
      viewPermission: 'TU-GE-VO',
      component: FuseLoadable({
        loader: () => import('./general'),
      }),
    },
    // {
    //   path: '/turns/display',
    //   component: FuseLoadable({
    //     loader: () => import('./display'),
    //   }),
    // },
    // {
    //   path: '/turns/history',
    //   component: FuseLoadable({
    //     loader: () => import('./history'),
    //   }),
    // },
  ],
}

export const turnNavigation = {
  id: 'turns',
  title: 'Turnos',
  type: 'collapse',
  viewPermission: 'TU-VO',
  icon: 'group',
  children: [
    {
      id: 'turns-general',
      title: 'General',
      type: 'item',
      viewPermission: 'TU-GE-VO',
      icon: 'group',
      url: '/turns/general',
    },
    {
      id: 'turns-display',
      title: 'Pantalla',
      type: 'item',
      viewPermission: 'TU-GE-VO',
      icon: 'desktop_windows',
      url: '/turns/display',
    },
    // {
    //   id: 'turns-history',
    //   title: 'Historial',
    //   type: 'item',
    //   icon: 'group',
    //   url: '/turns/history',
    // },
  ],
}
