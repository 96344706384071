import AppBar from '@material-ui/core/AppBar'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import NavbarToggleButton from '@recmed-do/template/lib/app/fuse-layouts/shared-components/NavbarToggleButton'
import UserMenu from 'core/Layout/components/organisisms/UserMenu'
import clsx from 'clsx'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectToolbarTheme } from '@recmed-do/template/lib/app/store/fuse/settingsSlice'

const useStyles = makeStyles(() => ({
  root: {},
}))

function ToolbarLayout1(props) {
  const config = useSelector(({ fuse }) => fuse.settings.current.layout.config)
  const navbar = useSelector(({ fuse }) => fuse.navbar)
  const toolbarTheme = useSelector(selectToolbarTheme)

  const classes = useStyles(props)

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx(classes.root, 'flex relative z-20 shadow-1', props.className)}
        color="default"
        style={{ backgroundColor: toolbarTheme.palette.background.paper }}
        position="static"
      >
        <Toolbar className="p-0 min-h-48 md:min-h-64">
          <div className="flex flex-1 px-16">
            {config.navbar.display && config.navbar.position === 'left' && (
              <>
                <Hidden mdDown>
                  {(config.navbar.style === 'style-3' ||
                    config.navbar.style === 'style-3-dense') && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}

                  {config.navbar.style === 'style-1' && !navbar.open && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                </Hidden>
              </>
            )}
          </div>

          <div className="flex items-center px-8 h-full overflow-x-auto">
            <UserMenu />
          </div>

          {config.navbar.display && config.navbar.position === 'right' && (
            <>
              <Hidden mdDown>
                {!navbar.open && <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />}
              </Hidden>

              <Hidden lgUp>
                <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
              </Hidden>
            </>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  )
}

export default React.memo(ToolbarLayout1)
