import React from 'react'

import _ from '@recmed-do/template/lib/@lodash'

import { makeStyles } from '@material-ui/core/styles'
import { useFormContext, Controller } from 'react-hook-form'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'

import { FormField } from 'shared/prop-types/form'

const useStyles = makeStyles((theme) => ({
  secondary: {
    '& svg': {
      color: theme.palette.secondary.main,
    },
  },
}))

const DynamicFormDatePicker = React.memo(
  ({ id, xs = 12, sm = 6, title, defaultValue, rules, ...props }) => {
    const classes = useStyles()
    const { control } = useFormContext()

    return (
      <FormControl
        component={Grid}
        item
        xs={xs}
        sm={sm}
        className={classes.secondary}
        id={`grid-${id}`}
        style={{ display: 'flex' }}
      >
        <Controller
          render={({ fieldState: { error }, field: { value = null, ref: __, ...rest } }) => {
            return (
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <KeyboardDatePicker
                  value={value}
                  inputVariant="outlined"
                  error={Boolean(error)}
                  invalidDateMessage="Fecha Invalida"
                  showTodayButton
                  helperText={error ? error.message : ''}
                  label={title}
                  format="dd/MM/yyyy"
                  ampm
                  todayLabel="Hoy"
                  clearLabel="Borrar fecha"
                  cancelLabel="Cancelar"
                  {..._.omit(props, ['parentPrefix'])}
                  {...rest}
                />
              </MuiPickersUtilsProvider>
            )
          }}
          control={control}
          name={id}
        />
      </FormControl>
    )
  },
  ({ id }, { id: id2 }) => id !== id2,
)

DynamicFormDatePicker.displayName = 'DynamicFormDatePicker'

DynamicFormDatePicker.propTypes = {
  ...FormField,
}

export { DynamicFormDatePicker }
