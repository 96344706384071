import React, { useState, useCallback, useEffect, useContext } from 'react'
import Auth from '@aws-amplify/auth'

import {
  Avatar,
  Button,
  Icon,
  ListItemIcon,
  ListItemText,
  Popover,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { useMutation } from '@apollo/client'

import { useSelector } from 'react-redux'

import { SET_DEFAULT_ORGANIZATION } from '@recmed/appsync/lib/mutations/organization'
import { makeStyles } from '@material-ui/core/styles'
import _ from '@recmed-do/template/lib/@lodash'

import AuthContext from 'components/contexts/auth-context'
import { SnackbarContext } from 'components/hocs/with-snackbar'
import OrganizationList from 'core/Layout/components/molecules/OrganizationList'

const useStyles = makeStyles((theme) => ({
  avatar: {
    background: theme.palette.background.default,
    '& > img': {
      borderRadius: '50%',
      objectFit: 'contain',
    },
  },
}))

function UserMenu() {
  const { displayMessage } = useContext(SnackbarContext)
  const { organizationId } = useContext(AuthContext)
  const { user, data, activeOrganization, organizations } = useSelector(({ auth }) => auth.user)
  const classes = useStyles()

  const [setDefaultOrganization] = useMutation(SET_DEFAULT_ORGANIZATION)

  const [userMenu, setUserMenu] = useState(null)

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget)
  }

  const userMenuClose = () => {
    setUserMenu(null)
  }

  const selected = organizations?.find((organization) => organization.default)
  const uid = _.get(selected, 'organization.uid')

  const handleSetOrganizationId = useCallback(
    async (id) => {
      try {
        await setDefaultOrganization({
          variables: {
            args: id,
          },
        })
        window.location.reload()
      } catch (e) {
        displayMessage({ message: e, variant: 'error' })
      }
    },
    [displayMessage, setDefaultOrganization],
  )

  useEffect(() => {
    if (!uid || uid === organizationId) {
      return
    }
    handleSetOrganizationId(uid)
  }, [handleSetOrganizationId, organizationId, uid])

  return (
    <>
      {organizations?.length && (
        <OrganizationList
          organizations={organizations}
          selected={_.get(activeOrganization, 'name')}
          setOrganization={handleSetOrganizationId}
        />
      )}
      <Button onClick={userMenuClick}>
        {user?.avatar ? (
          <Avatar className={classes.avatar} alt="user photo" src={user.avatar} />
        ) : (
          <Avatar className="">{data?.displayName?.[0]}</Avatar>
        )}
        <div className="md:flex flex-col ml-12 items-start">
          <Typography component="span" className="normal-case font-600 flex">
            {user?.name ?? ''} {user?.lastName ?? ''}
          </Typography>
          <Typography className="text-11 capitalize" color="textSecondary">
            {activeOrganization?.roleName ?? ''}
          </Typography>
        </div>
        <Icon className="text-16 ml-12 sm:flex" variant="action">
          keyboard_arrow_down
        </Icon>
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        <MenuItem
          onClick={async () => {
            await Auth.signOut()
            userMenuClose()
          }}
        >
          <ListItemIcon className="min-w-40">
            <Icon>exit_to_app</Icon>
          </ListItemIcon>
          <ListItemText className="pl-0" primary="Cerrar Sesión" />
        </MenuItem>
      </Popover>
    </>
  )
}

export default UserMenu
