import { dashboardNavigation } from 'modules/dashboard/module'
import { patientNavigation } from 'modules/patient/module'
import { turnNavigation } from 'modules/turns/module'
import { permissionNavigation } from 'modules/configuration/module'
import { administrativeNavigation } from 'modules/administrative/module'
import { accountingNavigation } from 'modules/accounting/module'
import { invoicingNavigation } from 'modules/invoicing/module'
import { fiscalNavigation } from 'modules/fiscal/module'
import { accountReceivableNavigation } from 'modules/account-receivable/module'
import { maintenanceNavigation } from 'modules/maintenance/module'

const navigationConfig = [
  {
    id: 'recmed',
    title: 'Menú',
    type: 'group',
    icon: 'apps',
    children: [
      permissionNavigation,
      maintenanceNavigation,
      dashboardNavigation,
      patientNavigation,
      turnNavigation,
    ],
  },
  {
    id: 'financial',
    title: 'Gestión Financiera',
    type: 'group',
    icon: 'monetization_on',
    children: [
      administrativeNavigation,
      accountingNavigation,
      invoicingNavigation,
      accountReceivableNavigation,
      fiscalNavigation,
    ],
  },
]

export default navigationConfig
