import React from 'react'
import PropTypes from 'prop-types'

import cn from 'clsx'

import MaterialPopover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  question: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  warningIcon: {
    color: theme.palette.warning.main,
    marginRight: theme.spacing(1),
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(1),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  button: {
    fontSize: 12,
  },
}))

const PopConfirm = React.forwardRef(
  (
    {
      children,
      okTextProps,
      className,
      containerClassname,
      tag,
      disabledPopover,
      question = 'Desea Realizar esta accion?',
      onCancel,
      onOk,
      cancelText = 'Cancelar',
      okText = 'Ok',
      questionComponent,
      ...props
    },
    ref,
  ) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const Component = React.useMemo(() => tag, [tag])

    const handlePopoverOpen = (event) => {
      if (disabledPopover) {
        if (onOk) {
          onOk(event)
        }
        return
      }
      setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
      setAnchorEl(null)
    }

    const handleCancel = () => {
      setAnchorEl(null)
      if (onCancel) {
        onCancel()
      }
    }

    const handleOk = async () => {
      if (!onOk) return
      handlePopoverClose()
      await onOk()
    }

    const open = Boolean(anchorEl)

    return (
      <>
        <Component ref={ref} className={containerClassname} onClick={handlePopoverOpen} {...props}>
          {children}
        </Component>
        <MaterialPopover
          className={cn(className)}
          classes={{
            paper: classes.paper,
          }}
          open={Boolean(open)}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {questionComponent || (
            <Typography variant="body1" className={classes.question}>
              <Icon className={classes.warningIcon}>warning</Icon>
              {question}
            </Typography>
          )}
          <div className={classes.buttonWrapper}>
            <Button
              variant="text"
              color="primary"
              onClick={handleCancel}
              className={cn(classes.button, classes.cancelButton)}
            >
              {cancelText}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOk}
              className={classes.button}
              {...okTextProps}
            >
              {okText}
            </Button>
          </div>
        </MaterialPopover>
      </>
    )
  },
)
PopConfirm.displayName = 'PopConfirm'

PopConfirm.propTypes = {
  tag: PropTypes.any,
  disabledPopover: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node]).isRequired,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  question: PropTypes.string,
  className: PropTypes.string,
  containerClassname: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  questionComponent: PropTypes.element,
  okTextProps: PropTypes.object,
}

PopConfirm.defaultProps = {
  tag: 'div',
}

export { PopConfirm }
