"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _client = require("@apollo/client");

var _user = require("../../queries/user");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var useUsersByOrganization = function useUsersByOrganization(organizationId) {
  var _useQuery = (0, _client.useQuery)(_user.GET_USERS_BY_ORGANIZATION, {
    variables: {
      args: organizationId
    }
  }),
      data = _useQuery.data,
      loading = _useQuery.loading,
      refetch = _useQuery.refetch;

  var users = _react["default"].useMemo(function () {
    var _data$usersByOrganiza;

    return (_data$usersByOrganiza = data === null || data === void 0 ? void 0 : data.usersByOrganization) !== null && _data$usersByOrganiza !== void 0 ? _data$usersByOrganiza : [];
  }, [data]);

  return {
    refetchUsers: refetch,
    loading: loading,
    users: users
  };
};

var _default = useUsersByOrganization;
exports["default"] = _default;