import FuseUtils from '@recmed-do/template/lib/@fuse/utils'

import { administrativeConfig } from 'modules/administrative/module'
import { accountingConfig } from 'modules/accounting/module'
import { dashboardConfig } from 'modules/dashboard/module'
import { turnsConfig, turnDisplayConfig } from 'modules/turns/module'
import { patientConfig } from 'modules/patient/module'
import { permissionConfig } from 'modules/configuration/module'
import { invoicingConfig } from 'modules/invoicing/module'
import { fiscalConfig } from 'modules/fiscal/module'
import { accountReceivableConfig } from 'modules/account-receivable/module'
import { maintenanceConfig } from 'modules/maintenance/module'
// import { templatesConfig } from 'modules/configuration/Templates/module'

const routeConfigs = [
  administrativeConfig,
  dashboardConfig,
  turnsConfig,
  turnDisplayConfig,
  patientConfig,
  permissionConfig,
  accountingConfig,
  invoicingConfig,
  fiscalConfig,
  accountReceivableConfig,
  maintenanceConfig,
  // templatesConfig,
]

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: '/',
    component: dashboardConfig.routes.find((x) => x.path === '/calendar').component,
  },
]

export default routes
