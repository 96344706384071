export const CalendarActions = Object.freeze({
  SetRange: 'calendar/SET_RANGE',
  SetAppointmentDialogVisible: 'calendar/SET_APPOINTMENT_DIALOG_VISIBLE',
  SetEventDialogVisible: 'calendar/SET_EVENT_DIALOG_VISIBLE',
  SetEvent: 'calendar/SET_EVENT',
})

export const CalendarEvents = Object.freeze({
  CreateEvent: 'calendar/CREATE_EVENT',
  UpdateAppointment: 'calendar/UPDATE_APPOINTMENT',
  UpdateEvent: 'calendar/UPDATE_EVENT',
})
