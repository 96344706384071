"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_USER = exports.UPDATE_USER_ORGANIZATION = exports.SET_DEFAULT_ORGANIZATION = exports.CREATE_USER_ORGANIZATION = exports.CREATE_ORGANIZATION = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var CREATE_ORGANIZATION = (0, _graphqlTag["default"])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation CreateOrganization($args: CreateOrganizationInput!) {\n    recmedCreateOrganization(args: $args)\n  }\n"])));
exports.CREATE_ORGANIZATION = CREATE_ORGANIZATION;
var CREATE_USER_ORGANIZATION = (0, _graphqlTag["default"])(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation CreateUserOrganization($args: CreateUserOrganizationInput!) {\n    createUserOrganization(args: $args)\n  }\n"])));
exports.CREATE_USER_ORGANIZATION = CREATE_USER_ORGANIZATION;
var SET_DEFAULT_ORGANIZATION = (0, _graphqlTag["default"])(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation SetDefaultOrganizationo($args: String!) {\n    setDefaultOrganization(args: $args)\n  }\n"])));
exports.SET_DEFAULT_ORGANIZATION = SET_DEFAULT_ORGANIZATION;
var UPDATE_USER_ORGANIZATION = (0, _graphqlTag["default"])(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation UpdateUserOrganization($args: UpdateUserOrganizationInput!) {\n    updateUserOrganization(args: $args)\n  }\n"])));
exports.UPDATE_USER_ORGANIZATION = UPDATE_USER_ORGANIZATION;
var UPDATE_USER = (0, _graphqlTag["default"])(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation UpdateUser($where: UserWhereUniqueInput!, $data: UserUpdateInput!) {\n    updateUser(data: $data, where: $where) {\n      uid\n    }\n  }\n"])));
exports.UPDATE_USER = UPDATE_USER;