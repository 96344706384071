"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useHandleTrackPublicationFailed = void 0;

var _react = require("react");

var useHandleTrackPublicationFailed = function useHandleTrackPublicationFailed(room, onError) {
  var localParticipant = room.localParticipant;
  (0, _react.useEffect)(function () {
    if (localParticipant) {
      localParticipant.on('trackPublicationFailed', onError);
      return function () {
        localParticipant.off('trackPublicationFailed', onError);
      };
    }

    return function () {};
  }, [localParticipant, onError]);
};

exports.useHandleTrackPublicationFailed = useHandleTrackPublicationFailed;