import { createContext } from 'react'

const AuthContext = createContext({
  logout: () => undefined,
  setOrganizationId: () => undefined,
  userId: undefined,
  organizationId: undefined,
})

export default AuthContext
