"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UPDATE_STATUS = exports.UPDATE_EVENT = exports.UPDATE_APPOINTMENT = exports.CREATE_EVENT = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var CREATE_EVENT = (0, _graphqlTag["default"])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation CreateEvent($data: EventCreateInput!) {\n    createEvent(args: $data) {\n      id\n    }\n  }\n"])));
exports.CREATE_EVENT = CREATE_EVENT;
var UPDATE_APPOINTMENT = (0, _graphqlTag["default"])(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation UpdateAppointmentEvent($data: EventAppointmentUpdateInput!) {\n    updateAppointment(args: $data) {\n      id\n    }\n  }\n"])));
exports.UPDATE_APPOINTMENT = UPDATE_APPOINTMENT;
var UPDATE_EVENT = (0, _graphqlTag["default"])(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation UpdateEvent($data: EventUpdateInput!) {\n    updateEvent(args: $data) {\n      id\n    }\n  }\n"])));
exports.UPDATE_EVENT = UPDATE_EVENT;
var UPDATE_STATUS = (0, _graphqlTag["default"])(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation UpdateStatus($data: EventUpdateStatusInput!) {\n    updateEventStatus(args: $data) {\n      id\n    }\n  }\n"])));
exports.UPDATE_STATUS = UPDATE_STATUS;