import { isApolloError } from '@apollo/client'

export function formatError(error) {
  let message = typeof error === 'object' ? error.message || 'Internal Error' : error

  if (isApolloError(error)) {
    message = error.graphQLErrors.map((v) => `${v.message}`).join('\n')
  }

  return message
}
