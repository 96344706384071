import React from 'react'

import Auth from '@aws-amplify/auth'
import _ from '@recmed-do/template/lib/@lodash'

import { Hub } from '@aws-amplify/core'

import { Switch, Route, useHistory } from 'react-router-dom'

import { SignIn } from 'modules/authorization/SignIn'
import { ChangeOldPassword } from 'modules/authorization/ChangeOldPassword'
import { ResetPassword } from 'modules/authorization/ResetPassword'
import { PatientAppointment } from 'modules/authorization/PatientAppointment'

import { VideoConferenceJoin } from 'modules/authorization/VideoJoin'
import { useSnackbar } from './with-snackbar'

const checkSignInWrapper = ({ history, displayMessage }) => (user) => {
  const { attributes, challengeName, username } = user

  if (_.get(attributes, 'custom:password_required') === 'true') {
    displayMessage({
      message: 'Por razones de seguridad, debe cambiar su contraseña actual.',
      variant: 'warning',
    })
    history.push(`/changeoldpassword/${_.get(attributes, 'email')}`)
    return false
  }

  switch (challengeName) {
    case 'NEW_PASSWORD_REQUIRED':
      displayMessage({
        message: 'Por razones de seguridad, debe cambiar su contraseña actual.',
        variant: 'warning',
      })
      history.push(`/changeoldpassword/${username}/${challengeName}`)
      return false
    default:
      break
  }

  return true
}

const withAuthenticator = (Component) => {
  const Enhancer = (props) => {
    const [isAuthenticated, setAuthenticated] = React.useState(false)
    const history = useHistory()
    const { displayMessage } = useSnackbar()

    const checkSign = React.useCallback(
      (user) => checkSignInWrapper({ history, displayMessage })(user),
      [history, displayMessage],
    )

    React.useEffect(() => {
      Auth.currentAuthenticatedUser()
        .then(() => {
          setAuthenticated(true)
        })
        .catch(() => {
          if (window.innerWidth > 769) {
            displayMessage({
              variant: 'info',
              message:
                'Por razones de seguridad, lo recomendable es no guardar la contraseña en el navegador de forma automática.',
            })
          }
        })
    }, [setAuthenticated, displayMessage])

    const onEvent = React.useCallback(
      (payload) => {
        try {
          const { event, data } = payload
          switch (event) {
            case 'signOut': {
              localStorage.removeItem('organization')
              setAuthenticated(false)
              break
            }
            case 'signIn':
              setAuthenticated(checkSign(payload?.data))
              break
            case 'forgotPassword':
              displayMessage({
                message: 'Se ha enviado un código de recuperación a su correo, favor verificar.',
                variant: 'warning',
              })
              break
            case 'forgotPasswordSubmit':
              displayMessage({
                message: 'Contraseña cambiada con éxito, favor iniciar sesión.',
              })
              history.push('/')
              break
            case 'signIn_failure':
            case 'forgotPasswordSubmit_failure':
              if (data?.code) {
                displayMessage({
                  message: 'Usuario/Contraseña inválido',
                  variant: 'error',
                })
              } else {
                displayMessage({
                  message: payload.data?.message,
                  variant: 'error',
                })
              }
              break
            default:
              break
          }
        } catch (e) {
          console.info(e)
        }
      },
      [displayMessage, checkSign, setAuthenticated, history],
    )

    const onException = React.useCallback(
      (payload) => {
        const { data } = payload
        switch (data?.code) {
          case 'PasswordResetRequiredException':
            history.push(`/resetpassword`)
            break
          default:
            break
        }
      },
      [history],
    )

    React.useEffect(() => {
      Hub.listen('auth', (data) => {
        const { payload } = data
        if (!payload) return

        onEvent(payload)
        if (payload.data?.code) {
          onException(payload)
        }
      })
    }, [onEvent, onException])

    if (isAuthenticated) {
      return (
        <Switch>
          <Route path="/patientappointment">
            <PatientAppointment />
          </Route>
          <Route path="/video/patient/:conferenceId">
            <VideoConferenceJoin />
          </Route>
          <Route path="*">
            <Component {...props} />
          </Route>
        </Switch>
      )
    }

    return (
      <Switch>
        <Route path="/patientappointment">
          <PatientAppointment />
        </Route>
        <Route path="/video/patient/:conferenceId">
          <VideoConferenceJoin />
        </Route>
        <Route path="/changeoldpassword/:username/:challengeName?">
          <ChangeOldPassword />
        </Route>
        <Route path="/resetpassword/:username?">
          <ResetPassword />
        </Route>
        <Route path="*">
          <SignIn checkSign={checkSign} />
        </Route>
      </Switch>
    )
  }

  return Enhancer
}

export { withAuthenticator }
