import FuseLoadable from 'shared/utils/FuseLoadable'

export const patientNavigation = {
  id: 'patient',
  title: 'Paciente',
  type: 'collapse',
  viewPermission: 'PA-VO',
  icon: 'perm_identity',
  children: [
    {
      id: 'patient-search',
      title: 'Búsqueda',
      type: 'item',
      viewPermission: 'PA-BU-VO',
      icon: 'list',
      url: '/patient/search',
    },
  ],
}

export const patientConfig = {
  setting: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/patient/search',
      viewPermission: 'PA-BU-VO',
      component: FuseLoadable({
        loader: () => import('./PatientSearch'),
      }),
    },
    {
      path: '/patient/:patientId/medical-consultation/details/:medicalId',
      exact: true,
      viewPermission: 'PA-MC-VO',
      component: FuseLoadable({
        loader: () => import('./MedicalConsultationView'),
      }),
    },
    {
      path: '/patient/medical-consultation/:id',
      exact: true,
      viewPermission: 'PA-MC-VO',
      component: FuseLoadable({
        loader: () => import('./MedicalConsultation'),
      }),
    },
    {
      path: '/patient/:patientId/:tabId?',
      exact: true,
      viewPermission: 'PA-DE-VO',
      component: FuseLoadable({
        loader: () => import('./PatientProfile'),
      }),
    },
  ],
}
