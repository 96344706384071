"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLocalTracks = void 0;

var _react = require("react");

var _twilioVideo = _interopRequireDefault(require("twilio-video"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var DEFAULT_VIDEO_CONSTRAINTS = {
  width: 1280,
  height: 720,
  frameRate: 24
};

var useLocalTracks = function useLocalTracks() {
  var _useState = (0, _react.useState)(),
      _useState2 = _slicedToArray(_useState, 2),
      audioTrack = _useState2[0],
      setAudioTrack = _useState2[1];

  var _useState3 = (0, _react.useState)(),
      _useState4 = _slicedToArray(_useState3, 2),
      videoTrack = _useState4[0],
      setVideoTrack = _useState4[1];

  var _useState5 = (0, _react.useState)(false),
      _useState6 = _slicedToArray(_useState5, 2),
      isAcquiringLocalTracks = _useState6[0],
      setIsAcquiringLocalTracks = _useState6[1];

  var getLocalAudioTrack = (0, _react.useCallback)(function (deviceId) {
    var options = {};

    if (deviceId) {
      options.deviceId = {
        exact: deviceId
      };
    }

    return _twilioVideo["default"].createLocalAudioTrack(options).then(function (newTrack) {
      setAudioTrack(newTrack);
      return newTrack;
    });
  }, []);
  var getLocalVideoTrack = (0, _react.useCallback)(function (newOptions) {
    // In the DeviceSelector and FlipCameraButton components, a new video track is created,
    // then the old track is unpublished and the new track is published. Unpublishing the old
    // track and publishing the new track at the same time sometimes causes a conflict when the
    // track name is 'camera', so here we append a timestamp to the track name to avoid the
    // conflict.
    var options = _objectSpread(_objectSpread({}, DEFAULT_VIDEO_CONSTRAINTS), {}, {
      name: "camera-".concat(Date.now())
    }, newOptions);

    return _twilioVideo["default"].createLocalVideoTrack(options).then(function (newTrack) {
      setVideoTrack(newTrack);
      return newTrack;
    });
  }, []);
  var removeLocalVideoTrack = (0, _react.useCallback)(function () {
    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(undefined);
    }
  }, [videoTrack]);
  (0, _react.useEffect)(function () {
    setIsAcquiringLocalTracks(true);

    _twilioVideo["default"].createLocalTracks({
      video: _objectSpread(_objectSpread({}, DEFAULT_VIDEO_CONSTRAINTS), {}, {
        name: "camera-".concat(Date.now())
      }),
      audio: true
    }).then(function (tracks) {
      var videoTrack = tracks.find(function (track) {
        return track.kind === 'video';
      });
      var audioTrack = tracks.find(function (track) {
        return track.kind === 'audio';
      });

      if (videoTrack) {
        setVideoTrack(videoTrack);
      }

      if (audioTrack) {
        setAudioTrack(audioTrack);
      }
    })["finally"](function () {
      return setIsAcquiringLocalTracks(false);
    });
  }, []);
  var localTracks = [audioTrack, videoTrack].filter(function (track) {
    return track !== undefined;
  });
  return {
    localTracks: localTracks,
    getLocalVideoTrack: getLocalVideoTrack,
    getLocalAudioTrack: getLocalAudioTrack,
    isAcquiringLocalTracks: isAcquiringLocalTracks,
    removeLocalVideoTrack: removeLocalVideoTrack
  };
};

exports.useLocalTracks = useLocalTracks;