"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ensureMediaPermissions = exports.generateConnectionOptions = exports.isMobile = void 0;

var _pickBy = _interopRequireDefault(require("lodash/pickBy"));

var _identity = _interopRequireDefault(require("lodash/identity"));

var _settings = require("./settings");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var isMobile = function () {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }

  return /Mobile/.test(navigator.userAgent);
}(); // This function ensures that the user has granted the browser permission to use audio and video
// devices. If permission has not been granted, it will cause the browser to ask for permission
// for audio and video at the same time (as opposed to separate requests).


exports.isMobile = isMobile;

var ensureMediaPermissions = function ensureMediaPermissions() {
  return navigator.mediaDevices.enumerateDevices().then(function (devices) {
    return devices.every(function (device) {
      return !(device.deviceId && device.label);
    });
  }).then(function (shouldAskForMediaPermissions) {
    if (shouldAskForMediaPermissions) {
      return navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true
      }).then(function (mediaStream) {
        return mediaStream.getTracks().forEach(function (track) {
          return track.stop();
        });
      });
    }
  });
}; // Recursively removes any object keys with a value of undefined


exports.ensureMediaPermissions = ensureMediaPermissions;

var removeUndefineds = function removeUndefineds(obj) {
  return (0, _pickBy["default"])(obj, _identity["default"]);
};

var generateConnectionOptions = function generateConnectionOptions(settings) {
  var _connectionOptions$ba;

  // See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
  // for available connection options.
  var connectionOptions = {
    // Bandwidth Profile, Dominant Speaker, and Network Quality
    // features are only available in Small Group or Group Rooms.
    // Please set "Room Type" to "Group" or "Small Group" in your
    // Twilio Console: https://www.twilio.com/console/video/configure
    bandwidthProfile: {
      video: {
        mode: settings.bandwidthProfileMode,
        dominantSpeakerPriority: settings.dominantSpeakerPriority,
        renderDimensions: {
          low: (0, _settings.getResolution)(settings.renderDimensionLow),
          standard: (0, _settings.getResolution)(settings.renderDimensionStandard),
          high: (0, _settings.getResolution)(settings.renderDimensionHigh)
        },
        maxTracks: Number(settings.maxTracks)
      }
    },
    dominantSpeaker: true,
    networkQuality: {
      local: 1,
      remote: 1
    },
    // Comment this line if you are playing music.
    maxAudioBitrate: Number(settings.maxAudioBitrate),
    // VP8 simulcast enables the media server in a Small Group or Group Room
    // to adapt your encoded video quality for each RemoteParticipant based on
    // their individual bandwidth constraints. This has no effect if you are
    // using Peer-to-Peer Rooms.
    preferredVideoCodecs: [{
      codec: 'VP8',
      simulcast: true
    }]
  }; // For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.

  if (isMobile && connectionOptions !== null && connectionOptions !== void 0 && (_connectionOptions$ba = connectionOptions.bandwidthProfile) !== null && _connectionOptions$ba !== void 0 && _connectionOptions$ba.video) {
    connectionOptions.bandwidthProfile.video.maxSubscriptionBitrate = 2500000;
  } // Here we remove any 'undefined' values. The twilio-video SDK will only use defaults
  // when no value is passed for an option. It will throw an error when 'undefined' is passed.


  return removeUndefineds(connectionOptions);
};

exports.generateConnectionOptions = generateConnectionOptions;