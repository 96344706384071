import { useReducer } from 'react'
import { CalendarActions } from './calendar.actions'

import moment from 'moment-timezone'

const initialState = Object.freeze({
  start: null,
  end: null,
  eventVisible: null,
  event: {},
})

const reducer = (state, action) => {
  switch (action.type) {
    case CalendarActions.SetEvent:
      return {
        ...state,
        event: action.payload.event,
        eventVisible: action.payload.type,
      }
    case CalendarActions.SetRange:
      const { start, end } = action.payload
      return {
        ...state,
        start: moment(start).isValid() ? moment(start).startOf('day') : null,
        end: moment(end).isValid() ? moment(start).endOf('day') : null,
      }
    default:
      return state
  }
}

export const useCalendarReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return [state, dispatch]
}
