import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import { formatError } from 'shared/utils/format-error'

import { useSelector } from 'react-redux'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles'
import { selectMainTheme } from '@recmed-do/template/lib/app/store/fuse/settingsSlice'

import { amber, green, red, blueGrey } from '@material-ui/core/colors'

export const SnackbarContext = createContext({
  open: false,
  displayMessage: ({ message = 'message', variant = 'success' }) => console.info(message, variant),
})

const useStyles = makeStyles({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: red[500],
  },
  info: {
    backgroundColor: blueGrey[500],
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    // fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: '2rem',
  },
  message: {
    fontSize: 15,
    display: 'flex',
    alignItems: 'center',
  },
})

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

function SnackbarContentWrapper(props) {
  const { className, message, onClose, variant, ...other } = props

  const classes = useStyles()
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon fontSize="large" />
        </IconButton>,
      ]}
      {...other}
    />
  )
}

export default function SnackbarWrapper({ children }) {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [variant, setVariant] = useState('success')
  const mainTheme = useSelector((root) => selectMainTheme(root))

  function handleClose(event, reason) {
    if (reason === 'clickaway') return

    setOpen(false)
  }

  const displayMessage = React.useCallback(({ message = '', variant = 'success' }) => {
    setMessage(formatError(message))
    setVariant(variant)
    setOpen(true)
  }, [])

  return (
    <>
      <SnackbarContext.Provider value={{ open, displayMessage }}>
        <MuiThemeProvider theme={mainTheme}>
          <Snackbar
            open={open}
            autoHideDuration={variant === 'success' ? 6000 : null}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <SnackbarContentWrapper variant={variant} onClose={handleClose} message={message} />
          </Snackbar>
        </MuiThemeProvider>
        {children}
      </SnackbarContext.Provider>
    </>
  )
}

export const useSnackbar = () => {
  return React.useContext(SnackbarContext)
}

SnackbarWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}
