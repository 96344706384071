import React, { useContext } from 'react'

import { makeStyles } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'

import CreateEvent from 'components/organisisms/create-event-modal'
import UpdateAppointment from 'components/organisisms/update-appointment-modal'
import UpdateEvent from 'components/organisisms/update-event-modal'

import AuthContext from 'components/contexts/auth-context'
import useCalendar from './use-calendar'

import { CalendarEvents } from './calendar.actions'
import Content from './calendar.content'

const useStyles = makeStyles((theme) => ({
  fab: {
    zIndex: 1000,
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
}))

function ScheduleView() {
  const classes = useStyles()
  const { userId } = useContext(AuthContext)
  const [state, actions] = useCalendar({ doctorId: userId })

  return (
    <>
      {state.eventVisible === CalendarEvents.CreateEvent && (
        <CreateEvent
          event={state.event}
          visible={state.eventVisible}
          onClose={(success) => {
            actions.handleOpenDialog({ event: null, type: null, success })
          }}
          anchorEl={state.anchorEl.current}
        />
      )}
      {state.eventVisible === CalendarEvents.UpdateAppointment && (
        <UpdateAppointment
          initialValues={state.event}
          visible
          onClose={(success) => {
            actions.handleOpenDialog({ event: null, type: null, success })
          }}
          anchorEl={state.anchorEl.current}
        />
      )}
      {state.eventVisible === CalendarEvents.UpdateEvent && (
        <UpdateEvent
          anchorEl={state.anchorEl.current}
          initialValues={state.event}
          visible
          onClose={(success) => {
            actions.handleOpenDialog({ event: null, type: null, success })
          }}
        />
      )}
      <Content
        handleOpenDialog={actions.handleOpenDialog}
        events={state.events}
        setRange={actions.setRange}
      />
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={(event) =>
          actions.handleOpenDialog(
            {
              type: CalendarEvents.CreateEvent,
              event: { eventType: 'EV', startDate: new Date() },
            },
            event,
          )
        }
      >
        <AddIcon />
      </Fab>
    </>
  )
}

export default ScheduleView
