import React from 'react'
import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useKeylistSelect } from './use-keylist-select'

const KeylistSelect = ({
  title,
  keylistName,
  multiple,
  value = multiple ? [] : '',
  onChange,
  inputProps,
  whiteList,
  error,
  ...props
}) => {
  const {
    setOpen,
    loading,
    text,
    options,
    validateSelected,
    open,
    setText: onInputChange,
  } = useKeylistSelect({
    name: keylistName,
    value,
  })

  const handleChange = (ignored, value) => {
    if (!value) {
      onChange(null)
      return
    }

    onChange(value.id)
  }

  return (
    <Autocomplete
      {...props}
      open={open}
      noOptionsText="Sin Resultados"
      onOpen={() => {
        onInputChange('')
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      getOptionSelected={(option) => {
        return option.id === value
      }}
      options={options}
      getOptionDisabled={Array.isArray(whiteList) ? ({ id }) => !whiteList.includes(id) : undefined}
      multiple={multiple}
      value={multiple ? `${value || ''}`.split(',').filter(Boolean) : value || null}
      onBlur={() => validateSelected()}
      onChange={handleChange}
      onInputChange={(_, text) => onInputChange(text)}
      inputValue={text}
      getOptionLabel={({ name = '' }) => name}
      loading={loading}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={title}
            variant="outlined"
            error={error}
            helperText={error ? error.message : ''}
            InputProps={{
              ...params.InputProps,
              ...inputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )
      }}
    />
  )
}

KeylistSelect.propTypes = {
  title: PropTypes.string,
  keylistName: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
  error: PropTypes.object,
  multiple: PropTypes.bool,
}

export { KeylistSelect }
