"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.STATISTIC_BY_CARD = exports.STATISTIC_BY_CHART = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

var _templateObject, _templateObject2;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var STATISTIC_BY_CHART = (0, _graphqlTag["default"])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query StatisticByChart($args: StatisticByInput!) {\n    statisticBy(args: $args) {\n      __typename\n      ... on ChartWidget {\n        title\n        subtitle\n        type\n        labels\n        datasets {\n          order\n          key\n          data {\n            data\n            label\n          }\n        }\n      }\n    }\n  }\n"])));
exports.STATISTIC_BY_CHART = STATISTIC_BY_CHART;
var STATISTIC_BY_CARD = (0, _graphqlTag["default"])(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query StatisticByCard($args: StatisticByInput!) {\n    statisticBy(args: $args) {\n      __typename\n      ... on CardWidget {\n        title\n        subtitle\n      }\n    }\n  }\n"])));
exports.STATISTIC_BY_CARD = STATISTIC_BY_CARD;