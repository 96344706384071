import React from 'react'

import FuseAnimate from '@recmed-do/template/lib/@fuse/core/FuseAnimate'
import FuseLoading from '@recmed-do/template/lib/@fuse/core/FuseLoading'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Button from '@material-ui/core/Button'
import { makeStyles, ThemeProvider, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Line } from 'react-chartjs-2'
import { useSelector } from 'react-redux'
import { useStatisticByQuery } from '@recmed/appsync/lib/hooks/queries/use-statistic-by-query'
import { selectMainThemeDark } from '@recmed-do/template/lib/app/store/fuse/settingsSlice'

const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
  },
}))

const SetEnum = Object.freeze({
  TODAY: 'Hoy',
  YESTERDAY: 'Ayer',
  THIS_MONTH: 'Este Mes',
  THIS_YEAR: 'Este Año',
})

const VisitorsWidget = React.memo(({ dataset, setDataset, ...props }) => {
  const { getStatisticBy, loading, statistic } = useStatisticByQuery()
  const mainThemeDark = useSelector((state) => selectMainThemeDark(state))

  const classes = useStyles(props)
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSet = (dataset) => () => {
    setDataset(dataset)
    handleClose()
  }

  React.useEffect(() => {
    getStatisticBy({
      variables: {
        args: {
          statisticType: 'CONSULTATION',
          parameterType: dataset,
        },
      },
    })
  }, [getStatisticBy, dataset])

  return (
    <ThemeProvider theme={mainThemeDark}>
      <div className={classes.root}>
        <div className="container relative p-16 sm:p-24 flex flex-row justify-between items-center">
          <FuseAnimate delay={100}>
            <div className="flex-col">
              <Typography className="h2" color="textPrimary">
                {statistic?.statisticBy[0]?.title}
              </Typography>
              <Typography className="h5" color="textSecondary">
                {statistic?.statisticBy[0]?.subtitle}
              </Typography>
            </div>
          </FuseAnimate>

          <div className="flex flex-row items-center">
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
              {SetEnum[dataset]}
            </Button>
            <Paper>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleSet('TODAY')}>Hoy</MenuItem>
                <MenuItem onClick={handleSet('YESTERDAY')}>Ayer</MenuItem>
                <MenuItem onClick={handleSet('THIS_MONTH')}>Este mes</MenuItem>
                <MenuItem onClick={handleSet('THIS_YEAR')}>Este año</MenuItem>
              </Menu>
            </Paper>
          </div>
        </div>
        <div className="container relative h-200 sm:h-256 pb-16">
          {loading && <FuseLoading />}
          {!loading && (
            <Line
              data={{
                type: 'line',
                labels: statistic?.statisticBy[0]?.labels,
                datasets: statistic?.statisticBy[0]?.datasets[0]?.data?.map((obj) => ({
                  ...obj,
                  borderColor: theme.palette.secondary.main,
                  backgroundColor: theme.palette.secondary.main,
                  pointBackgroundColor: theme.palette.secondary.dark,
                  pointHoverBackgroundColor: theme.palette.secondary.main,
                  pointBorderColor: theme.palette.secondary.contrastText,
                  pointHoverBorderColor: theme.palette.secondary.contrastText,
                })),
              }}
              options={{
                spanGaps: false,
                legend: {
                  display: false,
                },
                maintainAspectRatio: false,
                layout: {
                  padding: {
                    top: 32,
                    left: 32,
                    right: 32,
                  },
                },
                elements: {
                  point: {
                    radius: 4,
                    borderWidth: 2,
                    hoverRadius: 4,
                    hoverBorderWidth: 2,
                  },
                  // line: {
                  //   tension: 0,
                  // },
                },
                scales: {
                  xAxes: [
                    {
                      gridLines: {
                        display: false,
                        drawBorder: false,
                        tickMarkLength: 18,
                      },
                      ticks: {
                        min: 0,
                        fontColor: '#ffffff',
                      },
                    },
                  ],
                  yAxes: [
                    {
                      gridLines: {
                        drawBorder: false,
                        color: 'white',
                        tickMarkLength: 18,
                      },
                      ticks: {
                        min: 0,
                        stepSize: 3,
                        fontColor: '#ffffff',
                      },
                    },
                  ],
                },
                plugins: {
                  filler: {
                    propagate: false,
                  },
                  // xLabelsOnTop: {
                  //   active: true,
                  // },
                },
              }}
            />
          )}
        </div>
      </div>
    </ThemeProvider>
  )
})

VisitorsWidget.displayName = 'VisitorsWidget'

export { VisitorsWidget }
