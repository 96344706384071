import Auth from '@aws-amplify/auth'

const { REACT_APP_API_ENVIRONMENT } = process.env

const ApiServiceEnum = Object.freeze({
  FINANCIAL: 'financial',
  EXTERNAL: 'external',
  GENERAL: 'general',
  VIDEO: 'video',
})

const API_CONFIG = Object.freeze([
  {
    name: ApiServiceEnum.FINANCIAL,
    endpoint: `/api/${REACT_APP_API_ENVIRONMENT}-financial`,
    custom_header: async () => {
      return {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      }
    },
  },
  {
    name: ApiServiceEnum.VIDEO,
    endpoint: `/api/${REACT_APP_API_ENVIRONMENT}-video`,
    custom_header: async () => {
      return {
        Authorization: ``,
      }
    },
  },
  {
    name: ApiServiceEnum.EXTERNAL,
    endpoint: `/api-${REACT_APP_API_ENVIRONMENT}/${REACT_APP_API_ENVIRONMENT}-external`,
    custom_header: async () => {
      return {
        Authorization: '',
      }
    },
  },
])

export { API_CONFIG, ApiServiceEnum }
