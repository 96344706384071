import * as React from 'react'

import { templatesConfig } from './Templates/module'

export const MaintenanceIconEnum = Object.freeze({
  DRUGS:
    'M4.22,11.29L11.29,4.22C13.64,1.88 17.43,1.88 19.78,4.22C22.12,6.56 22.12,10.36 19.78,12.71L12.71,19.78C10.36,22.12 6.56,22.12 4.22,19.78C1.88,17.43 1.88,13.64 4.22,11.29M5.64,12.71C4.59,13.75 4.24,15.24 4.6,16.57L10.59,10.59L14.83,14.83L18.36,11.29C19.93,9.73 19.93,7.2 18.36,5.64C16.8,4.07 14.27,4.07 12.71,5.64L5.64,12.71Z',
  TEMPLATES:
    'M19,3H5C3.897,3,3,3.897,3,5v14c0,1.103,0.897,2,2,2h14c1.103,0,2-0.897,2-2V5C21,3.897,20.103,3,19,3z M19,5l0.001,4H15h-2 H5V5H19z M5,11h8v8H5V11z M15,19v-8h4.001l0.001,8H15z',
  laboratory:
    'M20.759 20.498c-2.342-3.663-5.575-6.958-5.743-11.498h-2.016c.173 5.212 3.512 8.539 5.953 12.356.143.302-.068.644-.377.644h-1.264l-4.734-7h-3.52c.873-1.665 1.85-3.414 1.936-6h-2.01c-.169 4.543-3.421 7.864-5.743 11.498-.165.347-.241.707-.241 1.057 0 1.283 1.023 2.445 2.423 2.445h13.153c1.4 0 2.424-1.162 2.424-2.446 0-.35-.076-.709-.241-1.056zm-4.759-15.498c0 1.105-.896 2-2 2s-2-.895-2-2 .896-2 2-2 2 .895 2 2zm-5-1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5.672-1.5 1.5-1.5 1.5.671 1.5 1.5zm0 3.5c0 .552-.447 1-1 1s-1-.448-1-1 .447-1 1-1 1 .448 1 1zm3-6c0 .552-.447 1-1 1s-1-.448-1-1 .447-1 1-1 1 .448 1 1z',
})

export const maintenanceConfig = {
  setting: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/maintenance/drugs',
      // viewPermission: 'CF-DR-VO',
      component: React.lazy(() => import('modules/maintenance/Drugs')),
    },
    {
      path: '/maintenance/laboratory',
      // viewPermission: 'GA-SE-VO',
      component: React.lazy(() => import('modules/maintenance/Laboratory')),
    },
    {
      path: '/maintenance/templates',
      // viewPermission: 'CF-DR-VO',
      component: React.lazy(() => import('modules/maintenance/Templates/TemplateList')),
    },
    ...templatesConfig.routes,
  ],
}

export const maintenanceNavigation = {
  id: 'maintenance',
  title: 'Mantenimientos',
  type: 'collapse',
  icon: 'category',
  children: [
    {
      id: 'templates',
      title: 'Plantillas',
      type: 'item',
      url: '/maintenance/templates',
      svgIcon: MaintenanceIconEnum.TEMPLATES,
    },
    {
      id: 'drugs',
      title: 'Medicamentos',
      type: 'item',
      url: '/maintenance/drugs',
      // viewPermission: 'CF-DR-VO',
      svgIcon: MaintenanceIconEnum.DRUGS,
    },
    {
      id: 'laboratory',
      title: 'Laboratorios',
      // viewPermission: 'GA-SE-VO',
      type: 'item',
      svgIcon: MaintenanceIconEnum.laboratory,
      url: '/maintenance/laboratory',
    },
  ],
}
