"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CREATE_VIDEO = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

var _templateObject;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var CREATE_VIDEO = (0, _graphqlTag["default"])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation CreateVideo($args: CreateVideoInput!) {\n    createVideo(args: $args) {\n      id\n      eventId\n      eventName\n      serviceId\n      token\n    }\n  }\n"])));
exports.CREATE_VIDEO = CREATE_VIDEO;