import * as React from 'react'

const templatesConfig = {
  setting: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/maintenance/template/edit/:id',
      viewPermission: 'FA-VO',
      component: React.lazy(() => import('modules/maintenance/Templates/TemplateEdit')),
    },
    {
      path: '/maintenance/template/create',
      viewPermission: 'FA-VO',
      component: React.lazy(() => import('modules/maintenance/Templates/TemplateEdit')),
    },
  ],
}
export { templatesConfig }
