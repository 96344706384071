import React from 'react'

import _ from '@recmed-do/template/lib/@lodash'
import Auth from '@aws-amplify/auth'

import { useDispatch } from 'react-redux'
import navigationConfig from 'shared/config/navigation'
import FuseSplashScreen from '@recmed-do/template/lib/@fuse/core/FuseSplashScreen'
import { useLazyQuery } from '@apollo/client'

import { setUser } from '@recmed-do/template/lib/app/auth/store/userSlice'
import { setNavigation } from '@recmed-do/template/lib/app/store/fuse/navigationSlice'
// import { setUserAction } from '@recmed-do/template/lib/app/auth/store/actions/user.actions'
// import { setNavigation as setNavigationAction } from '@recmed-do/template/lib/app/store/actions/fuse'

import { GET_USER_INFORMATION } from '@recmed/appsync/lib/queries/user'

import { PendingMedicalConsultationLayout } from 'components/organisisms/pending-medical-consultation-layout'
import { useSnackbar } from './with-snackbar'

const filterNavigationByPermissions = (permissions = []) => {
  const reducer = (acc, item) => {
    const newItem = { ...item }
    if (Array.isArray(newItem.children)) {
      const children = item.children.reduce(reducer, [])
      newItem.children = children
    }

    if (!newItem.viewPermission || permissions.includes(newItem.viewPermission)) {
      return [...acc, newItem]
    }

    return acc
  }

  return navigationConfig.reduce(reducer, [])
}

const withUserInformation = (Component) => {
  return (props) => {
    const dispatch = useDispatch()
    const [done, setDone] = React.useState(false)

    // const [loading, setLoading] = React.useState(true)
    // const [userId, setUserId] = React.useState(null)
    const { displayMessage } = useSnackbar()
    const [refetch, { called, error, loading, data }] = useLazyQuery(GET_USER_INFORMATION, {
      onError: (error) => {
        Auth.signOut({ global: true }).then(() => {
          displayMessage({ message: error, variant: 'error' })
        })
      },
      onCompleted: ({ userInformation }) => {
        localStorage.setItem('organization', userInformation?.activeOrganization?.organizationId)
        localStorage.setItem('parentOrganization', userInformation?.activeOrganization?.parentId)
        const permissions = _.get(userInformation, 'activeOrganization.permissions') || []
        dispatch(setUser(userInformation))
        dispatch(setNavigation(filterNavigationByPermissions(permissions)))
        setTimeout(() => setDone(true), 100)
      },
    })

    React.useEffect(() => {
      if (!refetch) return
      Auth.currentAuthenticatedUser().then(() => {
        refetch()
      })
    }, [refetch])

    if (((loading || !called) && !data?.userInformation) || error || !done) {
      return <FuseSplashScreen />
    }

    return (
      <>
        <Component {...props} />
        <PendingMedicalConsultationLayout
          id={_.get(data, 'userInformation.activeMedicalConsultation.id')}
          turnId={_.get(data, 'userInformation.activeMedicalConsultation.turnId')}
          status={_.get(data, 'userInformation.activeMedicalConsultation.status')}
          duration={_.get(data, 'userInformation.activeMedicalConsultation.duration')}
          startedAt={_.get(data, 'userInformation.activeMedicalConsultation.startedAt')}
        />
      </>
    )
  }
}

export default withUserInformation
