"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_PATIENT_LABORATORY_PRESCRIPTIONS = exports.GET_PATIENT_MEDICAL_PRESCRIPTIONS = exports.PATIENT_PROFILE = exports.GET_PATIENT_SEARCH = exports.PATIENT_FRAGMENT = exports.PATIENT_LOOKUP_AUTOCOMPLETE = exports.PATIENT_KEYLIST = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var PATIENT_KEYLIST = (0, _graphqlTag["default"])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query getPatientKeylist {\n    keylist {\n      genders {\n        id\n        name\n      }\n      documentTypes {\n        id\n        name\n      }\n      insurances {\n        id\n        name\n        rnc\n      }\n    }\n  }\n"])));
exports.PATIENT_KEYLIST = PATIENT_KEYLIST;
var PATIENT_LOOKUP_AUTOCOMPLETE = (0, _graphqlTag["default"])(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query GetPatientSearch($where: PatientWhereInput) {\n    patients(where: $where) {\n      uid\n      name\n      last_name\n      document\n      created_at\n      document_type {\n        name\n      }\n    }\n  }\n"])));
exports.PATIENT_LOOKUP_AUTOCOMPLETE = PATIENT_LOOKUP_AUTOCOMPLETE;
var PATIENT_FRAGMENT = (0, _graphqlTag["default"])(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  fragment patientFragment on Patient {\n    id\n    completeName\n    lastName\n    name\n    gender\n    genderId\n    documentType\n    documentTypeId\n    insurancePlan\n    insurancePlanId\n    document\n    email\n    dateOfBirth\n    address\n    phone\n    cellphone\n    insurance\n    insuranceId\n    affiliationNo\n    isNewPatient\n    legalTutorDocument\n    legalTutorName\n    sequence\n    legacyCode\n  }\n"])));
exports.PATIENT_FRAGMENT = PATIENT_FRAGMENT;
var GET_PATIENT_SEARCH = (0, _graphqlTag["default"])(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query GetPatientSearch($args: PatientSearchWhereInput!) {\n    patientSearch(args: $args) {\n      page\n      total\n      results {\n        ...patientFragment\n      }\n    }\n  }\n  ", "\n"])), PATIENT_FRAGMENT);
exports.GET_PATIENT_SEARCH = GET_PATIENT_SEARCH;
var PATIENT_PROFILE = (0, _graphqlTag["default"])(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  query PatientProfile($args: GetPatientProfileInput!) {\n    patientProfile(args: $args) {\n      profile {\n        ...patientFragment\n        notes {\n          id\n          note\n          doctorName\n          date\n        }\n      }\n      documents {\n        id\n        name\n        description\n        type\n        level\n        createdBy\n        contentType\n        updatedAt\n        size\n      }\n      medicalConsultations {\n        id\n        startedAt\n        duration\n        reason\n      }\n    }\n  }\n\n  ", "\n"])), PATIENT_FRAGMENT);
exports.PATIENT_PROFILE = PATIENT_PROFILE;
var GET_PATIENT_MEDICAL_PRESCRIPTIONS = (0, _graphqlTag["default"])(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query GetPatientMedicalPrescriptions($args: PrescriptionsWhereInput!) {\n    prescriptions(args: $args) {\n      id\n      note\n      effectiveDate\n      fileId\n      createdAt\n      medicalConsultationId\n      drugs {\n        id\n        drug\n        dose\n        frequency\n        duration\n        note\n      }\n    }\n  }\n"])));
exports.GET_PATIENT_MEDICAL_PRESCRIPTIONS = GET_PATIENT_MEDICAL_PRESCRIPTIONS;
var GET_PATIENT_LABORATORY_PRESCRIPTIONS = (0, _graphqlTag["default"])(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  query GetPatientMedicalPrescriptionLaboratories($args: PrescriptionsWhereInput!) {\n    laboratoryPrescriptions(args: $args) {\n      id\n      note\n      effectiveDate\n      medicalConsultationId\n      createdAt\n      fileId\n      laboratories {\n        id\n        categoryId\n        categoryName\n        note\n        checked\n        name\n      }\n    }\n  }\n"])));
exports.GET_PATIENT_LABORATORY_PRESCRIPTIONS = GET_PATIENT_LABORATORY_PRESCRIPTIONS;