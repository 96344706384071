import React from 'react'

import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'

const OrganizationList = ({ setOrganization, organizations = [], selected }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  return (
    <>
      <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
        <div className="md:flex flex-col ml-12 items-start">
          <Typography component="span" className="normal-case font-600 flex">
            {selected}
          </Typography>
        </div>

        <Icon className="text-16 ml-12 hidden sm:flex" variant="action">
          keyboard_arrow_down
        </Icon>
      </Button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        {organizations.map(({ name, organizationId }) => (
          <MenuItem
            key={organizationId}
            value={organizationId}
            onClick={() => {
              setAnchorEl(null)
              setOrganization(organizationId)
            }}
          >
            {name}
          </MenuItem>
        ))}
      </Popover>
    </>
  )
}

export default OrganizationList
