import React from 'react'
import _ from '@recmed-do/template/lib/@lodash'
import { useFormContext } from 'react-hook-form'

const withErrors = (Component) => {
  const getErrorMessage = ({ type, message } = {}) => {
    const getMessage = () => {
      return message || ''
    }
    switch (type) {
      case 'maxDate':
        return 'Fecha invalida'
      case 'required':
        return 'Este campo es requerido'
      case 'minLength':
        return 'Este campo no tiene el mínimo de caracteres necesarios'
      case 'maxLength':
        return 'Este campo sobrepasa al máximo de caracteres necesarios'
      default:
        return getMessage()
    }
  }
  return React.forwardRef(({ ...props }, ref) => {
    const { name } = props
    const methods = useFormContext()

    const errors = _.get(methods, ['errors'], {})
    const error = _.get(errors, name, {})

    return <Component {...props} ref={ref} error={error.type} helperText={getErrorMessage(error)} />
  })
}

export default withErrors
