import * as React from 'react'

export const PermissionIconEnum = Object.freeze({
  USERS:
    'M14.84,16.26C17.86,16.83 20,18.29 20,20V22H4V20C4,18.29 6.14,16.83 9.16,16.26L12,21L14.84,16.26M8,8H16V10A4,4 0 0,1 12,14A4,4 0 0,1 8,10V8M8,7L8.41,2.9C8.46,2.39 8.89,2 9.41,2H14.6C15.11,2 15.54,2.39 15.59,2.9L16,7H8M12,3H11V4H10V5H11V6H12V5H13V4H12V3Z',
  PROFILE:
    'M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z',
  ORGANIZATION:
    'M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M15.6,8.34C16.67,8.34 17.53,9.2 17.53,10.27C17.53,11.34 16.67,12.2 15.6,12.2A1.93,1.93 0 0,1 13.67,10.27C13.66,9.2 14.53,8.34 15.6,8.34M9.6,6.76C10.9,6.76 11.96,7.82 11.96,9.12C11.96,10.42 10.9,11.5 9.6,11.5C8.3,11.5 7.24,10.42 7.24,9.12C7.24,7.81 8.29,6.76 9.6,6.76M9.6,15.89V19.64C7.2,18.89 5.3,17.04 4.46,14.68C5.5,13.56 8.13,13 9.6,13C10.13,13 10.8,13.07 11.5,13.21C9.86,14.08 9.6,15.23 9.6,15.89M12,20C11.72,20 11.46,20 11.2,19.96V15.89C11.2,14.47 14.14,13.76 15.6,13.76C16.67,13.76 18.5,14.15 19.44,14.91C18.27,17.88 15.38,20 12,20Z',
  CONFIGURATIONS:
    'M22.7 19l-9.1-9.1c.9-2.3.4-5-1.5-6.9-2-2-5-2.4-7.4-1.3L9 6 6 9 1.6 4.7C.4 7.1.9 10.1 2.9 12.1c1.9 1.9 4.6 2.4 6.9 1.5l9.1 9.1c.4.4 1 .4 1.4 0l2.3-2.3c.5-.4.5-1.1.1-1.4z',
  CONFIGURATION:
    'M21.71 20.29L20.29 21.71A1 1 0 0 1 18.88 21.71L7 9.85A3.81 3.81 0 0 1 6 10A4 4 0 0 1 2.22 4.7L4.76 7.24L5.29 6.71L6.71 5.29L7.24 4.76L4.7 2.22A4 4 0 0 1 10 6A3.81 3.81 0 0 1 9.85 7L21.71 18.88A1 1 0 0 1 21.71 20.29M2.29 18.88A1 1 0 0 0 2.29 20.29L3.71 21.71A1 1 0 0 0 5.12 21.71L10.59 16.25L7.76 13.42M20 2L16 4V6L13.83 8.17L15.83 10.17L18 8H20L22 4Z',
})

export const permissionConfig = {
  setting: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/configuration/:uid?',
      component: React.lazy(() => import('modules/configuration/Configuration')),
    },
    {
      path: '/profiles/:uid?',
      viewPermission: 'CF-PE-VO',
      component: React.lazy(() => import('modules/configuration/Profiles')),
    },
    {
      path: '/users',
      viewPermission: 'CF-US-VO',
      component: React.lazy(() => import('modules/configuration/Users')),
    },
    {
      path: '/organizations/:uid?',
      viewPermission: 'CF-OR-VO',
      component: React.lazy(() => import('modules/configuration/Organization')),
    },
  ],
}

export const permissionNavigation = {
  id: 'configuration',
  title: 'Configuración',
  type: 'collapse',
  viewPermission: 'CF-VO',
  svgIcon: PermissionIconEnum.CONFIGURATION,
  children: [
    {
      id: 'organizations',
      title: 'Organizaciones',
      type: 'item',
      viewPermission: 'CF-OR-VO',
      url: '/organizations',
      svgIcon: PermissionIconEnum.ORGANIZATION,
    },
    {
      id: 'profiles',
      title: 'Perfiles',
      type: 'item',
      viewPermission: 'CF-PE-VO',
      url: '/profiles',
      svgIcon: PermissionIconEnum.PROFILE,
    },
    {
      id: 'users',
      title: 'Usuarios',
      type: 'item',
      viewPermission: 'CF-US-VO',
      url: '/users',
      svgIcon: PermissionIconEnum.USERS,
    },
    {
      id: 'configuration',
      title: 'Configuraciones',
      type: 'item',
      url: '/configuration',
      svgIcon: PermissionIconEnum.CONFIGURATIONS,
    },
  ],
}
