import React from 'react'

import { useFormContext, Controller } from 'react-hook-form'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'

import { FormField } from 'shared/prop-types/form'

const DynamicFormDateTimePicker = ({ id, xs = 12, sm = 6, title, defaultValue, ...props }) => {
  const { control } = useFormContext()

  return (
    <FormControl
      component={Grid}
      item
      xs={xs}
      sm={sm}
      id={`grid-${id}`}
      style={{ display: 'flex' }}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <Controller
          control={control}
          render={({
            field: { value = defaultValue || null, ref: _, ...rest },
            fieldState: { error },
          }) => (
            <KeyboardDateTimePicker
              value={value}
              inputVariant="outlined"
              invalidDateMessage="Fecha Invalida"
              error={Boolean(error)}
              helperText={error ? error.message : ''}
              showTodayButton
              label={title}
              format="dd/MM/yyyy hh:mm a"
              ampm
              {...props}
              {...rest}
            />
          )}
          name={id}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  )
}

DynamicFormDateTimePicker.propTypes = {
  ...FormField,
}

export { DynamicFormDateTimePicker }
