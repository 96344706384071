"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EVENTS = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

var _fragments = require("./fragments");

var _templateObject;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var EVENTS = (0, _graphqlTag["default"])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query Events($args: EventCalendarWhereInput!) {\n    eventCalendar(args: $args) {\n      eventStatuses {\n        ...KeylistFragment\n      }\n      events {\n        id\n        title\n        start\n        end\n        allDay\n        metadata {\n          doctorId\n          doctorName\n        }\n        resource {\n          allowUpdate\n          allowedStatus\n          patientName\n          description\n          duration\n          status\n          type\n          timeZone\n          location\n          procedure\n          patient {\n            id\n            completeName\n            insurance\n            insurancePlan\n            affiliationNo\n          }\n          isVideoConfererence\n        }\n      }\n    }\n  }\n  ", "\n"])), _fragments.fragments.keylist);
exports.EVENTS = EVENTS;