import FuseLoadable from 'shared/utils/FuseLoadable'

export const AdministrativeIconEnum = Object.freeze({
  // insurance:
  //   'M12,1L3,5V11C3,16.5 6.8,21.7 12,23C17.2,21.7 21,16.5 21,11V5L12,1M16,10H13V18H11V10H8V8H11V5H13V8H16V10Z',
  service:
    'M19 3H14.82C14.4 1.84 13.3 1 12 1S9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M7 8H9V12H8V9H7V8M10 17V18H7V17.08L9 15H7V14H9.25C9.66 14 10 14.34 10 14.75C10 14.95 9.92 15.14 9.79 15.27L8.12 17H10M11 4C11 3.45 11.45 3 12 3S13 3.45 13 4 12.55 5 12 5 11 4.55 11 4M17 17H12V15H17V17M17 11H12V9H17V11Z',
  catalog:
    'M11 8C11 10.21 9.21 12 7 12C4.79 12 3 10.21 3 8C3 5.79 4.79 4 7 4C9.21 4 11 5.79 11 8M11 14.72V20H0V18C0 15.79 3.13 14 7 14C8.5 14 9.87 14.27 11 14.72M24 20H13V3H24V20M16 11.5C16 10.12 17.12 9 18.5 9C19.88 9 21 10.12 21 11.5C21 12.88 19.88 14 18.5 14C17.12 14 16 12.88 16 11.5M22 7C20.9 7 20 6.11 20 5H17C17 6.11 16.11 7 15 7V16C16.11 16 17 16.9 17 18H20C20 16.9 20.9 16 22 16V7Z',
})

export const administrativeConfig = {
  setting: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/accounts',
      viewPermission: 'GA-CC-VO',
      component: FuseLoadable({
        loader: () => import('modules/administrative/AccountCatalog'),
      }),
    },
    // {
    //   path: '/insurance',
    //   viewPermission: 'GA-AS-VO',
    //   component: FuseLoadable({
    //     loader: () => import('./doctor-insurance'),
    //   }),
    // },
    {
      path: '/services',
      viewPermission: 'GA-SE-VO',
      component: FuseLoadable({
        loader: () => import('modules/administrative/Service'),
      }),
    },
  ],
}

export const administrativeNavigation = {
  id: 'administrative',
  title: 'Gestion Administrativa',
  viewPermission: 'GA-VO',
  type: 'collapse',
  svgIcon:
    'M10,2H14A2,2 0 0,1 16,4V6H20A2,2 0 0,1 22,8V19A2,2 0 0,1 20,21H4C2.89,21 2,20.1 2,19V8C2,6.89 2.89,6 4,6H8V4C8,2.89 8.89,2 10,2M14,6V4H10V6H14Z',
  children: [
    {
      id: 'acc-catalog',
      title: 'Catalogo de Cuentas',
      viewPermission: 'GA-CC-VO',
      type: 'item',
      svgIcon: AdministrativeIconEnum.catalog,
      url: '/accounts',
    },
    // {
    //   id: 'insurance',
    //   title: 'Aseguradoras',
    //   type: 'item',
    //   viewPermission: 'GA-AS-VO',
    //   svgIcon: AdministrativeIconEnum.insurance,
    //   url: '/insurance',
    // },
    {
      id: 'services',
      title: 'Servicios',
      viewPermission: 'GA-SE-VO',
      type: 'item',
      svgIcon: AdministrativeIconEnum.service,
      url: '/services',
    },
  ],
}
