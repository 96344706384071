import React from 'react'
import moment from 'moment-timezone'
import _ from '@recmed-do/template/lib/@lodash'
import { useQuery } from '@apollo/client'
import queryString from 'querystring'
import { useLocation, useHistory } from 'react-router-dom'

import { EVENTS } from '@recmed/appsync/lib/queries/event'

import { useCalendarReducer } from './calendar.reducer'
import { CalendarActions } from './calendar.actions'

const eventParse = (event) => {
  const parsed = {
    ...event,
    start: moment(event.start).toDate(),
    end: moment(event.end).toDate(),
  }

  return parsed
}

export default function useCalendar() {
  const [state, dispatch] = useCalendarReducer()
  const { visible, event } = state
  const location = useLocation()
  const history = useHistory()
  const anchorEl = React.useRef()

  const search = queryString.parse(location.search)

  const {
    start = moment().startOf('weeks').format(),
    end = moment().endOf('weeks').format(),
  } = search

  const handleSetRange = ({ start: s = start, end: e = end, view }) => {
    history.push({
      search: queryString.stringify({
        view,
        start: moment(s).format(),
        end: moment(e).format(),
      }),
    })
  }

  const payload = {
    args: {
      start,
      end,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  }

  const { refetch, data: { eventCalendar = {} } = {} } = useQuery(EVENTS, {
    fetchPolicy: 'network-only',
    variables: payload,
  })

  const handleOpenDialog = ({ type, event, success } = {}, e) => {
    anchorEl.current = e?.target
    dispatch({ type: CalendarActions.SetEvent, payload: { type, event } })
    if (success) {
      refetch()
    }
  }

  return [
    {
      events: (eventCalendar?.events ?? []).map(eventParse),
      visible,
      event,
      eventVisible: state.eventVisible,
      statuses: _.get(eventCalendar, 'eventStatuses', []),
      anchorEl,
    },
    {
      handleOpenDialog,
      setRange: handleSetRange,
    },
  ]
}
