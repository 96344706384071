"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AudioTrack = void 0;

var _react = require("react");

var _VideoStateProvider = require("../../../contexts/VideoStateProvider");

var AudioTrack = function AudioTrack(_ref) {
  var track = _ref.track;

  var _useVideoAppState = (0, _VideoStateProvider.useVideoAppState)(),
      activeSinkId = _useVideoAppState.activeSinkId;

  var audioEl = (0, _react.useRef)();
  (0, _react.useEffect)(function () {
    audioEl.current = track.attach();
    audioEl.current.setAttribute('data-cy-audio-track-name', track.name);
    document.body.appendChild(audioEl.current);
    return function () {
      return track.detach().forEach(function (el) {
        return el.remove();
      });
    };
  }, [track]);
  (0, _react.useEffect)(function () {
    var _audioEl$current, _audioEl$current$setS;

    // eslint-disable-next-line no-unused-expressions
    (_audioEl$current = audioEl.current) === null || _audioEl$current === void 0 ? void 0 : (_audioEl$current$setS = _audioEl$current.setSinkId) === null || _audioEl$current$setS === void 0 ? void 0 : _audioEl$current$setS.call(_audioEl$current, activeSinkId);
  }, [activeSinkId]);
  return null;
};

exports.AudioTrack = AudioTrack;