import React from 'react'

import clsx from 'clsx'
import _ from '@recmed-do/template/lib/@lodash'
import moment from 'moment-timezone'
import * as yup from 'yup'

import { useMutation, useQuery } from '@apollo/client'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import FormControl from '@material-ui/core/FormControl'
import DialogActions from '@material-ui/core/DialogActions'
import Popover from '@material-ui/core/Popover'
import DialogContent from '@material-ui/core/DialogContent'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import FaceIcon from '@material-ui/icons/Face'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import InfoIcon from '@material-ui/icons/Info'
import TitleIcon from '@material-ui/icons/Title'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import HourglassFullIcon from '@material-ui/icons/HourglassFull'
import NoteIcon from '@material-ui/icons/Note'
import Box from '@material-ui/core/Box'
import Datepicker from 'components/atoms/form-datepicker'
import TextField from 'components/atoms/text-field'

import { SnackbarContext } from 'components/hocs/with-snackbar'

import { UPDATE_EVENT, UPDATE_STATUS } from '@recmed/appsync/lib/mutations/event'
import { GET_KEYLIST } from '@recmed/appsync/lib/queries'
import { DynamicUsersDropdown } from 'components/atoms/DynamicUsersDropdown'
import { DynamicFormInput } from 'components/atoms/dynamic-form-input'
import { DynamicFormDatePicker } from 'components/atoms/dynamic-form-date-picker'

const validationSchema = yup.object().shape({
  description: yup.string().required(),
  title: yup.string().required(),
  location: yup.string(),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
})

const getDefaultValues = ({
  start,
  end,
  title,
  metadata: { doctorId },
  resource: { timeZone, description, location },
}) => ({
  title,
  description,
  location,
  doctorId,
  startDate: moment.tz(start, timeZone).toDate(),
  endDate: moment.tz(end, timeZone).toDate(),
})
const useStyles = makeStyles((theme) => ({
  root: {
    width: '40vw',
    [theme.breakpoints.up('lg')]: {
      width: 580,
    },
    '&.fullscreen': {
      width: '100vw',
      height: '100vh',
    },
  },
  icon: {
    left: 0,
    marginRight: 20,
  },
}))

const UpdateEventModal = ({ fullScreen, visible, onClose, initialValues, anchorEl }) => {
  const classes = useStyles()

  const methods = useForm({
    validationSchema,
    defaultValues: getDefaultValues(initialValues),
  })
  const {
    id,
    resource: { allowUpdate, allowedStatus = [], status },
  } = initialValues
  const { displayMessage } = React.useContext(SnackbarContext)

  const { control, handleSubmit } = methods
  const [updateEvent] = useMutation(UPDATE_EVENT)
  const [updateStatus] = useMutation(UPDATE_STATUS)

  const { data, loading } = useQuery(GET_KEYLIST('eventStatuses'), {})

  const eventStatuses = _.get(data, 'keylist.eventStatuses', [])

  const handleUpdateStatus = (statusId) => async () => {
    try {
      await updateStatus({
        variables: {
          data: {
            id,
            status: statusId,
          },
        },
      })
      displayMessage({
        message: 'Estado actualizado existosamente',
      })

      if (onClose) {
        onClose(true)
      }
    } catch (e) {
      displayMessage({
        message: e,
        variant: 'error',
      })
      console.log(e)
    }
  }

  const handleAction = handleSubmit(async (payload) => {
    try {
      await updateEvent({
        variables: {
          data: {
            ...payload,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            id: _.get(initialValues, 'id'),
          },
        },
      })
      displayMessage({
        message: 'Evento Actualizada Correctamente',
      })
      if (onClose) {
        onClose(true)
      }
    } catch (e) {
      displayMessage({
        message: e,
        variant: 'error',
      })
    }
  })

  return (
    <Popover
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={visible}
      onClose={onClose}
      classes={{
        paper: clsx(classes.root, { fullscreen: fullScreen }),
      }}
      anchorEl={anchorEl}
    >
      <AppBar position="static" elevation={1}>
        <Toolbar className="flex">
          <Typography variant="subtitle1" color="inherit">
            Actualizar Evento
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <FormProvider {...methods}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Box display="flex" alignItems="center">
                <InfoIcon className={classes.icon} color="primary" />
                <Typography variant="h6" color="inherit">
                  <strong>
                    {_.get(
                      eventStatuses.find(({ id }) => id === status),
                      'name',
                      status,
                    )}
                  </strong>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box display="flex" alignItems="center">
                <FaceIcon className={classes.icon} color="primary" />
                <DynamicUsersDropdown
                  id="doctorId"
                  title="Doctor"
                  disabled={true}
                  variant="outlined"
                  xs={12}
                  sm={12}
                />
              </Box>
            </Grid>
            <FormControl component={Grid} item xs={12} sm={12}>
              <Box display="flex" alignItems="center">
                <TitleIcon className={classes.icon} color="primary" />
                <DynamicFormInput id="title" title="Título" xs={12} sm={12} />
                {/* <Controller
                  render={({ field }) => <TextField {...field} />}
                  control={control}
                  autoComplete="false"
                  label="Titulo"
                  name="title"
                  variant="outlined"
                  fullWidth
                /> */}
              </Box>
            </FormControl>
            <FormControl component={Grid} item xs={12} sm={12}>
              <Box display="flex" alignItems="center">
                <LocationOnIcon className={classes.icon} color="primary" />
                <DynamicFormInput id="location" title="Ubicación del evento" xs={12} sm={12} />
                {/* <Controller
                  render={({ field }) => <TextField {...field} />}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>place_circle</Icon>
                      </InputAdornment>
                    ),
                  }}
                  control={control}
                  autoComplete="false"
                  label="Ubicación del evento"
                  name="location"
                  variant="outlined"
                /> */}
              </Box>
            </FormControl>
            <FormControl variant="outlined" component={Grid} item xs={12} sm={6}>
              <Box display="flex" alignItems="center">
                <HourglassEmptyIcon className={classes.icon} color="primary" />
                <DynamicFormDatePicker xs={12} sm={12} id="startDate" title="Fecha de Inicio" />
                {/* <Datepicker mask="datetime" label="Fecha de Inicio" name="startDate" fullWidth /> */}
              </Box>
            </FormControl>
            <FormControl variant="outlined" component={Grid} item xs={12} sm={6}>
              <Box display="flex" alignItems="center">
                <HourglassFullIcon className={classes.icon} color="primary" />
                <DynamicFormDatePicker xs={12} sm={12} id="endDate" title="Fecha de Finalización" />
                {/* <Datepicker
                  mask="datetime"
                  label="Fecha de Finalización"
                  name="endDate"
                  fullWidth
                /> */}
              </Box>
            </FormControl>
            <FormControl component={Grid} item xs={12} sm={12} className="sm:pb-8">
              <Box display="flex" alignItems="center">
                <NoteIcon className={classes.icon} color="primary" />
                <DynamicFormInput id="description" title="Descripción" xs={12} sm={12} />
                {/* <Controller
                  render={({ field }) => <TextField {...field} />}
                  control={control}
                  multiline
                  rows={3}
                  fullWidth
                  autoComplete="false"
                  label="Descripción"
                  name="description"
                  variant="outlined"
                /> */}
              </Box>
            </FormControl>
            {!loading && allowedStatus.length > 0 && (
              <Grid xs={12} sm={12} item>
                <Typography>Cambiar estado:</Typography>
              </Grid>
            )}
            {!loading &&
              allowedStatus.map((status) => (
                <Grid xs={12} sm={6} item key={status}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    onClick={handleUpdateStatus(status)}
                  >
                    {_.get(
                      eventStatuses.find(({ id }) => id === status),
                      'name',
                      status,
                    )}
                  </Button>
                </Grid>
              ))}
          </Grid>
        </FormProvider>
      </DialogContent>
      <DialogActions className="justify-between pl-8 sm:pl-16">
        <Button variant="contained" color="primary" onClick={onClose}>
          Cerrar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAction}
          disabled={!allowUpdate || loading}
        >
          Actualizar Evento
        </Button>
      </DialogActions>
    </Popover>
  )
}

export default withMobileDialog()(UpdateEventModal)
