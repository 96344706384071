import React from 'react'

import moment from 'moment-timezone'
import { useFormContext } from 'react-hook-form'
import { Grid, FormControl } from '@material-ui/core'

import Datepicker from 'components/atoms/form-datepicker'
import { FormKeylistSelect } from 'components/molecules/form-keylist-select'
import { DynamicFormInput } from 'components/atoms/dynamic-form-input'
import { PlanFormKeyList } from '../FormPlanKeylistSelect'

function PatientForm() {
  const { watch } = useFormContext()
  const documentType = watch('documentType')
  const insurance = watch('insurance')

  return (
    <>
      <Grid container spacing={2}>
        <DynamicFormInput id="legacyCode" title="Código legacy del paciente" size="small" />
      </Grid>
      <Grid container spacing={2}>
        <FormKeylistSelect
          id="documentType"
          title="Tipo de documento"
          xs={12}
          sm={6}
          type="patientDocumentTypes"
          size="small"
        />
        <DynamicFormInput
          id="document"
          title="Documento"
          mask={documentType === 'CE' ? 'identification' : undefined}
          disabled={documentType === 'IS_UNDER_AGE'}
          size="small"
        />
        <DynamicFormInput id="name" title="Nombre" size="small" />
        <DynamicFormInput id="lastName" title="Apellido" size="small" />
        <FormKeylistSelect id="gender" title="Sexo" type="genders" size="small" />
        <FormControl variant="outlined" required component={Grid} item xs={12} sm={6}>
          <Datepicker
            label="Fecha de Nacimiento (DD/MM/YYYY)"
            name="dateOfBirth"
            disableFuture
            size="small"
            registerOptions={{
              required: true,
              validate: {
                maxDate: (values) => {
                  return moment(values).isSameOrBefore(moment().endOf('day'))
                },
              },
            }}
          />
        </FormControl>
        {documentType === 'IS_UNDER_AGE' && (
          <>
            <DynamicFormInput
              id="legalTutorDocument"
              title="Documento tutor legal"
              mask="identification"
              size="small"
            />
            <DynamicFormInput id="legalTutorName" title="Nombre del tutor legal" size="small" />
          </>
        )}
        <DynamicFormInput id="phone" title="Teléfono" mask="phone" size="small" />
        <FormKeylistSelect id="insurance" title="Seguro" type="insurances" size="small" />

        {insurance && insurance !== 'PP' && (
          <>
            <PlanFormKeyList id="insurancePlan" title="Plan" size="small" insurance={insurance} />
            <DynamicFormInput
              id="affiliationNo"
              title="Número de afiliado"
              size="small"
              inputProps={{ maxLength: 15 }}
            />
          </>
        )}
        <DynamicFormInput id="email" title="Correo Electrónico" size="small" />
        <DynamicFormInput xs={12} sm={12} id="address" title="Dirección" multiline size="small" />
      </Grid>
    </>
  )
}

export default PatientForm
