import React from 'react'
import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'

import { usePatientLookup } from './use-patient-lookup'

const PatientLookup = ({
  title = 'Buscar paciente por',
  value,
  onChange,
  initialOptions = [],
  inputProps,
  error,
  ...props
}) => {
  const { setOpen, options, loading, open, onChange: onInputChange } = usePatientLookup({
    initialOptions,
  })

  const handleChange = (_, value) => {
    onChange(value?.id)
  }

  const id = typeof value === 'object' ? value?.id : value

  return (
    <Autocomplete
      {...props}
      open={open}
      noOptionsText="Sin Resultados"
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      renderOption={({
        completeName,
        documentType,
        document,
        insurance,
        affiliationNo,
        insurancePlan,
      }) => (
        <Typography variant="subtitle2" style={{ fontWeight: 700, paddingLeft: 8 }}>
          {completeName}
          <Typography variant="body2">{`${documentType}: ${document}`}</Typography>
          <Typography variant="body2">{`Seguro: ${insurance} - ${insurancePlan ?? 'N/A'} - ${
            affiliationNo || ''
          }`}</Typography>
        </Typography>
      )}
      getOptionSelected={(option) => option.id === id}
      options={options}
      value={value}
      onChange={handleChange}
      onInputChange={onInputChange}
      getOptionLabel={({ completeName = '' }) => completeName}
      loading={loading}
      loadingText="Cargando, un momento por favor..."
      renderInput={(params) => (
        <TextField
          {...params}
          label={title}
          variant="outlined"
          error={Boolean(error)}
          helperText={error ? error.message : ''}
          InputProps={{
            ...params.InputProps,
            ...inputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

PatientLookup.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
  initialOptions: PropTypes.array,
  error: PropTypes.object,
}

export { PatientLookup }
