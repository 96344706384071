import * as React from 'react'

export const FiscalIconEnum = Object.freeze({
  FISCAL:
    'M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14M7,15V17H9C9.14,18.55 9.8,19.94 10.81,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H19A2,2 0 0,1 21,5V13.03C19.85,11.21 17.82,10 15.5,10C14.23,10 13.04,10.37 12.04,11H7V13H10C9.64,13.6 9.34,14.28 9.17,15H7M17,9V7H7V9H17Z',
  FISCAL_VOUCHERS: 'line_weight',
  FISCAL_SEQUENCE: 'notes',
})

const fiscalConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/accounting/fiscal/sequence',
      viewPermission: 'MF-VO',
      component: React.lazy(() => import('modules/fiscal/InvoiceSequence')),
    },
    {
      path: '/accounting/fiscal/authorization',
      viewPermission: 'MF-VO',
      component: React.lazy(() => import('modules/fiscal/FiscalVoucher')),
    },
  ],
}

const fiscalNavigation = {
  id: 'fiscal',
  title: 'Menú Fiscal',
  viewPermission: 'MF-VO',
  type: 'collapse',
  svgIcon: FiscalIconEnum.FISCAL,
  children: [
    {
      id: 'vouchers',
      title: 'Comprobantes',
      type: 'item',
      viewPermission: 'MF-CO-VO',
      icon: FiscalIconEnum.FISCAL_VOUCHERS,
      url: '/accounting/fiscal/authorization',
    },
    {
      id: 'invoices',
      title: 'Facturación',
      viewPermission: 'MF-FA-VO',
      type: 'item',
      icon: FiscalIconEnum.FISCAL_SEQUENCE,
      url: '/accounting/fiscal/sequence',
    },
  ],
}

export { fiscalConfig, fiscalNavigation }
