import React from 'react'

import FormHelperText from '@material-ui/core/FormHelperText'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import MaterialSelect from '@material-ui/core/Select'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  inputLabel: {
    top: 'unset',
    left: 'unset',
  },
})

const Select = ({ name, htmlFor = name, label, defaultValue, helperText, ...props }) => {
  const styles = useStyles()
  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth)
  }, [inputLabel])

  return (
    <>
      <InputLabel
        ref={inputLabel}
        id={htmlFor}
        className={styles.inputLabel}
        // shrink={`${defaultValue}`}
        htmlFor={htmlFor}
      >
        {label}
      </InputLabel>
      <MaterialSelect
        {...props}
        labelId={htmlFor}
        defaultValue={defaultValue}
        name={name}
        input={<OutlinedInput labelWidth={labelWidth} name={name} id={htmlFor} />}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  )
}

export default Select
