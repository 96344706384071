import React from 'react'

import _ from '@recmed-do/template/lib/@lodash'
import { useApolloClient } from '@apollo/client'
import { GET_PATIENT_SEARCH } from '@recmed/appsync/lib/queries/patient'

const usePatientLookup = ({ initialOptions = [] }) => {
  const timeout = React.useRef(null)
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [options, setOptions] = React.useState(initialOptions)
  const client = useApolloClient()

  const fetch = React.useCallback(
    (query) => {
      return client.query({
        query: GET_PATIENT_SEARCH,
        variables: {
          args: {
            query,
            limit: 30,
            page: 1,
          },
        },
      })
    },
    [client],
  )

  const onChange = React.useCallback(
    (arg, value = '') => {
      clearTimeout(timeout.current)

      timeout.current = setTimeout(async () => {
        try {
          setOptions([])
          setLoading(true)
          if (value.length < 3) return
          const { data } = await fetch(value)
          setOptions(_.get(data, 'patientSearch.results') || [])
        } catch (e) {
          console.info(e)
        } finally {
          setLoading(false)
        }
      }, 500)
    },
    [fetch],
  )

  React.useEffect(() => {
    onChange(null, '')
  }, [onChange])

  return {
    open,
    loading,
    options,
    setOpen,
    onChange,
  }
}

export { usePatientLookup }
