"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CANCEL_TURN = exports.ABSENT_TURN = exports.FINISH_TURN = exports.SWAP_TURN = exports.CREATE_TURN = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

var _turn = require("../queries/turn");

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var CREATE_TURN = (0, _graphqlTag["default"])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation createTurn($organizationId: ID!, $args: CreateTurnInput!) {\n    createTurn(organizationId: $organizationId, args: $args) {\n      ...turnScreenFragment\n    }\n  }\n\n  ", "\n"])), _turn.TURN_SCREEN_FRAGMENT);
exports.CREATE_TURN = CREATE_TURN;
var SWAP_TURN = (0, _graphqlTag["default"])(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation swapTurn($organizationId: ID!, $args: SwapTurnInput!) {\n    swapTurn(organizationId: $organizationId, args: $args) {\n      ...turnScreenFragment\n    }\n  }\n  ", "\n"])), _turn.TURN_SCREEN_FRAGMENT);
exports.SWAP_TURN = SWAP_TURN;
var FINISH_TURN = (0, _graphqlTag["default"])(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation finishTurn($organizationId: ID!, $args: FinishTurnInput!) {\n    finishTurn(organizationId: $organizationId, args: $args) {\n      ...turnScreenFragment\n    }\n  }\n  ", "\n"])), _turn.TURN_SCREEN_FRAGMENT);
exports.FINISH_TURN = FINISH_TURN;
var ABSENT_TURN = (0, _graphqlTag["default"])(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation absentTurn($organizationId: ID!, $args: AbsentTurnInput!) {\n    absentTurn(organizationId: $organizationId, args: $args) {\n      ...turnScreenFragment\n    }\n  }\n  ", "\n"])), _turn.TURN_SCREEN_FRAGMENT);
exports.ABSENT_TURN = ABSENT_TURN;
var CANCEL_TURN = (0, _graphqlTag["default"])(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation cancelTurn($organizationId: ID!, $args: CancelTurnInput!) {\n    cancelTurn(organizationId: $organizationId, args: $args) {\n      ...turnScreenFragment\n    }\n  }\n  ", "\n"])), _turn.TURN_SCREEN_FRAGMENT);
exports.CANCEL_TURN = CANCEL_TURN;