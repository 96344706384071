import FuseDialog from '@recmed-do/template/lib/@fuse/core/FuseDialog'
import FuseMessage from '@recmed-do/template/lib/@fuse/core/FuseMessage'
// import FuseScrollbars from '@recmed-do/template/lib/@fuse/core/FuseScrollbars'
import FuseSuspense from '@recmed-do/template/lib/@fuse/core/FuseSuspense'
import { makeStyles } from '@material-ui/core/styles'
import AppContext from '@recmed-do/template/lib/app/AppContext'
import clsx from 'clsx'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { renderRoutes } from 'react-router-config'
import LeftSideLayout1 from './components/atoms/LeftSideLayout'
import NavbarWrapperLayout1 from './components/organisisms/NavbarWrapperLayout'
import RightSideLayout1 from './components/atoms/RightSideLayout'
import ToolbarLayout1 from './components/organisisms/ToolbarLayout'

const useStyles = makeStyles((theme) => ({
  root: {
    '&.boxed': {
      clipPath: 'inset(0)',
      // maxWidth: (props) => `${props.config.containerWidth}px`,
      margin: '0 auto',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    },
    '&.container': {
      '& .container': {
        // maxWidth: (props) => `${props.config.containerWidth}px`,
        width: '100%',
        margin: '0 auto',
      },
    },
  },
}))
const Layout = React.memo((props) => {
  const config = useSelector(({ fuse }) => fuse.settings.current.layout.config)
  const appContext = useContext(AppContext)
  const { routes } = appContext
  const classes = useStyles({ ...props, config })

  return (
    <div id="fuse-layout" className={clsx(classes.root, config.mode, 'w-full flex')}>
      {config.leftSidePanel.display && <LeftSideLayout1 />}

      <div className="flex flex-auto min-w-0">
        {config.navbar.display && config.navbar.position === 'left' && <NavbarWrapperLayout1 />}

        <main id="fuse-main" className="flex flex-col flex-auto min-h-screen min-w-0 relative z-10">
          {config.toolbar.display && (
            <ToolbarLayout1 className={config.toolbar.style === 'fixed' && 'sticky top-0'} />
          )}

          <div className="flex flex-col flex-auto min-h-0 relative z-10">
            <FuseDialog />

            <FuseSuspense>{renderRoutes(routes)}</FuseSuspense>

            {props.children}
          </div>
        </main>

        {config.navbar.display && config.navbar.position === 'right' && <NavbarWrapperLayout1 />}
      </div>

      {config.rightSidePanel.display && <RightSideLayout1 />}
      <FuseMessage />
    </div>
  )
})

Layout.displayName = 'Layout'

export { Layout }
