import React from 'react'

import Auth from '@aws-amplify/auth'
import useIdle from 'react-use/lib/useIdle'

import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'components/hocs/with-snackbar'

const whiteList = ['/turns/display']

const withIdle = (Component) => {
  const Enhancer = (props) => {
    const { location } = useHistory()
    const { displayMessage } = useSnackbar()

    const isIdle = useIdle(15 * 60e3, false)

    React.useEffect(() => {
      if (whiteList.some((item) => location.pathname.startsWith(item)) || !isIdle) return

      Auth.signOut().then(() => {
        displayMessage({
          variant: 'warning',
          message:
            'Tu sesión ha expirado debido a un período de inactividad. Puedes iniciar una nueva sesión a continuación.',
        })
      })
    }, [isIdle, location.pathname, displayMessage])

    return <Component {...props} />
  }

  Enhancer.displayName = `WithIdle(${Component.displayName})`

  return Enhancer
}

export { withIdle }
