import React from 'react'
import PropTypes from 'prop-types'

import cn from 'clsx'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import { darken } from '@material-ui/core/styles/colorManipulator'

import FuseAnimate from '@recmed-do/template/lib/@fuse/core/FuseAnimate'

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    height: '100vh !important',
    width: '100% !important',
    background: `radial-gradient(${darken(theme.palette.primary.dark, 0.5)} 0%, ${
      theme.palette.primary.dark
    } 80%)`,
    color: theme.palette.primary.contrastText,
  },
}))

const AuthenticationTemplate = ({ children }) => {
  const classes = useStyles()
  return (
    <div
      className={cn(
        classes.root,
        'flex flex-col flex-auto flex-no-shrink items-center justify-center p-32',
      )}
    >
      <div className="flex flex-col items-center justify-center w-full">
        <FuseAnimate animation="transition.expandIn">
          <Card className="w-full max-w-384">
            <CardContent className="flex flex-col items-center justify-center p-32">
              <div className="p-24">
                <img src="assets/images/logos/recmed_complete_logo.png" alt="logo" />
              </div>
              {children}
            </CardContent>
          </Card>
        </FuseAnimate>
      </div>
    </div>
  )
}

AuthenticationTemplate.propTypes = {
  children: PropTypes.node,
}

export { AuthenticationTemplate }
