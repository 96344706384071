import React from 'react'
import { Router } from 'react-router-dom'
import ReactDOM from 'react-dom'

import 'typeface-muli'
import '@recmed-do/template/lib/i18n'
import '@recmed-do/template/lib/react-chartjs-2-defaults'
import '@recmed-do/template/lib/styles/app-base.css'
import '@recmed-do/template/lib/styles/app-components.css'
import '@recmed-do/template/lib/styles/app-utilities.css'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

import App from './App'

import 'moment/locale/es'
import moment from 'moment-timezone'
import FuseTheme from '@recmed-do/template/lib/@fuse/core/FuseTheme'
import { Provider } from 'react-redux'
import store from '@recmed-do/template/lib/app/store'
import history from '@recmed-do/template/lib/@history'
import { ApolloProvider } from '@apollo/client'
import SnackbarWrapper from './components/hocs/with-snackbar'
import routes from './shared/config/routes'
import { yupSetLocale } from './shared/utils/yup-schemas'
import AppContext from '@recmed-do/template/lib/app/AppContext'
import { Auth } from '@recmed-do/template/lib/app/auth'
import jssExtend from 'jss-plugin-extend'
import { create } from 'jss'
import { StylesProvider, jssPreset, createGenerateClassName } from '@material-ui/core/styles'
import { setupApollo } from '@recmed/appsync'

import { SplashScreenWrapper } from 'components/hocs/with-splash-screen'
import { amplifyConfigure } from './shared/amplify'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()],
  insertionPoint: document.getElementById('jss-insertion-point')!,
})

const generateClassName = createGenerateClassName()

const apolloClient = setupApollo()

const rootEl = document.getElementById('root')

const { REACT_APP_LOCALE, REACT_APP_TIMEZONE } = process.env

moment.locale(REACT_APP_LOCALE)
moment.tz(REACT_APP_TIMEZONE!)

amplifyConfigure()
yupSetLocale()
const renderApp = (Component: React.ComponentType) => {
  ReactDOM.render(
    <AppContext.Provider
      value={{
        routes,
      }}
    >
      <ApolloProvider client={apolloClient}>
        <StylesProvider jss={jss} generateClassName={generateClassName}>
          <Provider store={store}>
            <Auth>
              <Router history={history}>
                <SnackbarWrapper>
                  <FuseTheme>
                    <SplashScreenWrapper>
                      <Component />
                    </SplashScreenWrapper>
                  </FuseTheme>
                </SnackbarWrapper>
              </Router>
            </Auth>
          </Provider>
        </StylesProvider>
      </ApolloProvider>
    </AppContext.Provider>,
    rootEl,
  )
}

renderApp(App)

if ((module as any).hot) {
  ;(module as any).hot.accept('./App', () => {
    import('./App').then((NextApp) => renderApp(NextApp.default))
  })
}

reportWebVitals()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
