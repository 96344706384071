import React from 'react'

import _ from '@recmed-do/template/lib/@lodash'
import { useQuery } from '@apollo/client'

import { GET_KEYLIST } from '@recmed/appsync/lib/queries'

const useKeylistSelect = ({ name, value }) => {
  const [text, setText] = React.useState('')
  const [open, setOpen] = React.useState(false)

  const { data = {}, loading } = useQuery(GET_KEYLIST(name), {
    fetchPolicy: 'network-only',
  })

  const options = React.useMemo(() => data.keylist?.[name] ?? [], [data.keylist, name])

  const validateSelected = React.useCallback(() => {
    setOpen(false)
    if (!options?.length) return
    const selected = options.find(({ id }) => id === value)
    if (!selected || typeof selected?.name !== 'string') {
      setText('')
      return
    }
    setText(selected.name)
  }, [value, options, setText])

  React.useEffect(validateSelected, [validateSelected])

  return {
    validateSelected,
    open,
    setText,
    text,
    loading,
    options,
    setOpen,
  }
}

export { useKeylistSelect }
