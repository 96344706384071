import React from 'react'
import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'
import Auth from '@aws-amplify/auth'

import * as yup from 'yup'
import { useForm, FormProvider } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import { AuthenticationTemplate } from 'components/templates/authentication-template'
import { DynamicFormInput } from 'components/atoms/dynamic-form-input'
import { useSnackbar } from 'components/hocs/with-snackbar'
import { yupResolver } from '@hookform/resolvers/yup'

const Schema = yup.object().shape({
  username: yup.string().email().required().label('Correo'),
  password: yup.string().required(),
})

const ForgotSchema = yup.object().shape({
  username: yup.string().email().required().label('Correo'),
})

const SignIn = ({ checkSign }) => {
  const history = useHistory()
  const [loading, setLoading] = React.useState(false)
  const [forgot, setForgot] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)
  const methods = useForm({ resolver: yupResolver(forgot ? ForgotSchema : Schema) })
  // const { displayMessage } = useSnackbar()

  const handleSubmit = methods.handleSubmit(async (form) => {
    const { username, password } = form
    try {
      setLoading(true)

      if (forgot) {
        await Auth.forgotPassword(username)
        history.push(`/resetpassword/${username}`)
        return
      }

      const user = await Auth.signIn(username, password)
      switch (user.challengeName) {
        case 'NEW_PASSWORD_REQUIRED':
          checkSign({
            username: user.challengeParam?.userAttributes?.email,
            attributes: user.attributes,
            challengeName: user.challengeName,
          })
          break
        default:
          break
      }
    } catch (e) {
      console.info('error', e)
    } finally {
      setLoading(false)
    }
  })

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      // const { username, password } = methods.getValues({ nest: false })
      // if (username && password) {
      handleSubmit()
      // }
    }
  }

  return (
    <AuthenticationTemplate>
      <FormProvider {...methods}>
        <Typography variant="h6" className="mt-16 mb-16">
          INGRESE SUS CREDENCIALES
        </Typography>
        <Grid container spacing={2} onKeyPress={onKeyPress}>
          <DynamicFormInput id="username" xs={12} sm={12} title="Usuario" />
          {!forgot && (
            <DynamicFormInput
              id="password"
              xs={12}
              sm={12}
              title="Contraseña"
              type={showPassword ? undefined : 'password'}
              inputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(event) => event.preventDefault()}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
            className="w-224 mx-auto mt-16 mb-16"
            aria-label="LOG IN"
          >
            {forgot ? 'Enviar Código de recuperación' : 'Iniciar Sesión'}
          </Button>
          {!loading && (
            <Button
              variant="outlined"
              className="mx-auto"
              onClick={() => setForgot(!forgot)}
              style={{ borderWidth: 0 }}
            >
              {forgot ? 'Volver Atras' : 'Olvidé mi contraseña'}
            </Button>
          )}
        </Grid>

        {loading && <CircularProgress variant="indeterminate" className="w-224 mx-auto mt-16" />}
      </FormProvider>
    </AuthenticationTemplate>
  )
}

SignIn.propTypes = {
  checkSign: PropTypes.func.isRequired,
}

export { SignIn }
