import React from 'react'

import Card from '@material-ui/core/Card'
import FuseAnimate from '@recmed-do/template/lib/@fuse/core/FuseAnimate'
import FuseLoading from '@recmed-do/template/lib/@fuse/core/FuseLoading'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Bar } from 'react-chartjs-2'
import { useStatisticByQuery } from '@recmed/appsync/lib/hooks/queries/use-statistic-by-query'

const DiagnosisWidget = React.memo(({ dataset }) => {
  const { getStatisticBy, loading, statistic } = useStatisticByQuery()

  const theme = useTheme()

  React.useEffect(() => {
    getStatisticBy({
      variables: {
        args: {
          statisticType: 'DIAGNOSIS_RATE',
          parameterType: dataset,
        },
      },
    })
  }, [getStatisticBy, dataset])

  return (
    <Card className="w-full rounded-8 shadow-none border-1">
      <div className="p-16 pb-0 flex flex-row flex-wrap items-end">
        <FuseAnimate delay={100}>
          <div className="flex-col">
            <Typography className="h2" color="textPrimary">
              {statistic?.statisticBy[0]?.title}
            </Typography>
            <Typography className="h5" color="textSecondary">
              {statistic?.statisticBy[0]?.subtitle}
            </Typography>
          </div>
        </FuseAnimate>
      </div>
      <div className="container relative h-200 sm:h-256 pb-16">
        {loading && <FuseLoading />}
        {!loading && (
          <Bar
            data={{
              labels: statistic?.statisticBy[0]?.labels,
              datasets: statistic?.statisticBy[0]?.datasets[0]?.data?.map((obj) => ({
                ...obj,
                backgroundColor: [
                  theme.palette.primary.main,
                  theme.palette.secondary.main,
                  '#FF6384',
                  '#36A2EB',
                  '#FFCE56',
                ],
                hoverBackgroundColor: [
                  theme.palette.primary.main,
                  theme.palette.secondary.main,
                  '#FF6384',
                  '#36A2EB',
                  '#FFCE56',
                ],
              })),
            }}
            options={{
              spanGaps: false,
              legend: {
                display: false,
              },
              maintainAspectRatio: false,
              layout: {
                padding: {
                  top: 32,
                  left: 32,
                  right: 32,
                },
              },
              elements: {
                point: {
                  radius: 4,
                  borderWidth: 2,
                  hoverRadius: 4,
                  hoverBorderWidth: 2,
                },
              },
              scales: {
                xAxes: [
                  {
                    display: false,
                  },
                ],
                yAxes: [
                  {
                    gridLines: {
                      drawBorder: false,
                    },
                    ticks: {
                      min: 0,
                      stepSize: 1,
                    },
                  },
                ],
              },
              plugins: {
                filler: {
                  propagate: false,
                },
                // xLabelsOnTop: {
                //   active: true,
                // },
              },
            }}
          />
        )}
      </div>
    </Card>
  )
})

DiagnosisWidget.displayName = 'DiagnosisWidget'

export { DiagnosisWidget }
