import PubSub from '@aws-amplify/pubsub'
import API from '@aws-amplify/api'

import Amplify from '@aws-amplify/core'
import Storage from '@aws-amplify/storage'

import { AUTH_TYPE } from 'aws-appsync'
import { API_CONFIG } from 'shared/api/config'

const {
  NODE_ENV,
  REACT_APP_COGNITO_USER_POOL,
  REACT_APP_COGNITO_APP_ID,
  REACT_APP_IDENTITY_POOL_ID,
  REACT_APP_BUCKET,
  REACT_APP_REGION,
  REACT_APP_GRAPHQL_URL,
} = process.env

const amplifyConfigure = () => {
  Amplify.configure({
    Auth: {
      identityPoolId: REACT_APP_IDENTITY_POOL_ID,
      region: REACT_APP_REGION,
      userPoolId: REACT_APP_COGNITO_USER_POOL,
      userPoolWebClientId: REACT_APP_COGNITO_APP_ID,
    },
    API: {
      aws_appsync_graphqlEndpoint: REACT_APP_GRAPHQL_URL,
      aws_appsync_region: REACT_APP_REGION,
      aws_appsync_authenticationType: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      endpoints: API_CONFIG,
    },
    aws_appsync_graphqlEndpoint: REACT_APP_GRAPHQL_URL,
    aws_appsync_region: REACT_APP_REGION,
    aws_appsync_authenticationType: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  })

  API.configure({
    aws_appsync_graphqlEndpoint: REACT_APP_GRAPHQL_URL,
    aws_appsync_region: REACT_APP_REGION,
    aws_appsync_authenticationType: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    endpoints: API_CONFIG,
  })
  PubSub.configure({
    aws_appsync_graphqlEndpoint: REACT_APP_GRAPHQL_URL,
    aws_appsync_region: REACT_APP_REGION,
    aws_appsync_authenticationType: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  })

  Storage.configure({
    track: NODE_ENV === 'development',
    AWSS3: {
      bucket: REACT_APP_BUCKET,
      region: REACT_APP_REGION,
    },
  })
}

export { amplifyConfigure }
