import React from 'react'

import { useParams } from 'react-router-dom'

import * as yup from 'yup'
import { useForm, FormProvider } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import { AuthenticationTemplate } from 'components/templates/authentication-template'
import { DynamicFormInput } from 'components/atoms/dynamic-form-input'
import { useSnackbar } from 'components/hocs/with-snackbar'
import { VideoApp } from '@recmed/video'
import { yupResolver } from '@hookform/resolvers/yup'

const { REACT_APP_API_ENVIRONMENT } = process.env

const Schema = yup.object().shape({
  pass: yup.string().required(),
})

const VideoConferenceJoin = () => {
  const { displayMessage } = useSnackbar()
  const { conferenceId } = useParams()
  const [loading, setLoading] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)
  const [data, setData] = React.useState(null)
  const methods = useForm({ resolver: yupResolver(Schema) })

  const handleSubmit = methods.handleSubmit(async ({ pass: password }) => {
    try {
      setLoading(true)
      const resp = await fetch(`/api/${REACT_APP_API_ENVIRONMENT}-video/v2/patient/join`, {
        method: 'POST',
        body: JSON.stringify({
          password,
          conferenceId,
        }),
      })

      const data = await resp.json()

      if (resp.status >= 400) {
        displayMessage({
          message: data.message,
          variant: 'error',
        })
        return
      }
      setData(data)
    } finally {
      setLoading(false)
    }
  })

  if (data?.token) {
    return <VideoApp data={data} />
  }

  return (
    <AuthenticationTemplate>
      <FormProvider {...methods}>
        <Typography variant="h6" className="mt-16">
          ACCESO A VIDEO CONSULTA
        </Typography>
        <Typography variant="subtitle1" className="mb-16">
          Favor ingrese la contraseña suministrada.
        </Typography>
        <Grid container spacing={2}>
          <DynamicFormInput
            id="pass"
            xs={12}
            sm={12}
            title="Contraseña"
            type={showPassword ? undefined : 'password'}
            inputProps={{
              autoComplete: 'new-password',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(event) => event.preventDefault()}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
            className="w-224 mx-auto mt-16 mb-16"
            aria-label="access"
          >
            Acceder
          </Button>
        </Grid>
        {loading && <CircularProgress variant="indeterminate" className="w-224 mx-auto mt-16" />}
      </FormProvider>
    </AuthenticationTemplate>
  )
}

export { VideoConferenceJoin }
