import React from 'react'
import PropTypes from 'prop-types'

import cn from 'clsx'

import { red } from '@material-ui/core/colors'
import Button from '@material-ui/core/Button'

import { Popover } from 'components/atoms/Popover'
import { PopConfirm } from 'components/atoms/PopConfirm'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  button: {
    position: 'absolute',
    right: 0,
    top: 160,
    minWidth: 48,
    width: 48,
    height: 48,
    opacity: 0.9,
    padding: 0,
    // borderBottomRightRadius: 0,
    // borderTopRightRadius: 0,
    zIndex: 999,
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[500],
      opacity: 1,
    },
  },
}))

const FloatingButton = ({
  question,
  onOk,
  component = Button,
  className,
  children,
  title,
  disabledPopover,
  ...props
}) => {
  const classes = useStyles()

  return (
    <PopConfirm
      {...props}
      tag={component}
      disabledPopover={disabledPopover}
      question={question}
      onOk={onOk}
      containerClassname={cn(classes.button, className)}
      variant="contained"
    >
      <Popover content={title} containerClassname="flex">
        {children}
      </Popover>
    </PopConfirm>
  )
}

FloatingButton.propTypes = {
  disabledPopover: PropTypes.bool,
  question: PropTypes.string,
  component: PropTypes.func,
  onOk: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export { FloatingButton }
