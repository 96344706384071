import React from 'react'

import { useFormContext, Controller } from 'react-hook-form'

import Radio from '@material-ui/core/Radio'
import MUIRadioGroup from '@material-ui/core/RadioGroup'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

import withErrors from 'components/hocs/with-errors'

const RadioGroup = ({ title, list = [], helperText, defaultValue, row = true, ...props }) => {
  const { control } = useFormContext()

  return (
    <>
      <FormLabel component="legend">{title}</FormLabel>
      <Controller
        {...props}
        control={control}
        render={({ field: { value = (defaultValue = ''), ...rest } }) => (
          <MUIRadioGroup row value={value}>
            {list.map(({ id, name }) => (
              <FormControlLabel key={id} value={id} control={<Radio />} label={name} {...rest} />
            ))}
          </MUIRadioGroup>
        )}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  )
}

export default withErrors(RadioGroup)
