"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_USER_INFORMATION = exports.GET_EVENT_DOCTORS_BY_ORGANIZATION = exports.GET_USERS_BY_ORGANIZATION = exports.USER_ORGANIZATION_FRAGMENT = exports.FIND_USERS = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var FIND_USERS = (0, _graphqlTag["default"])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query findUsers($args: UsersInput!) {\n    users(args: $args) {\n      id\n      email\n      name\n      lastName\n      dateOfBirth\n      avatar\n      isActive\n      featureFlags\n      lastLogin\n    }\n  }\n"])));
exports.FIND_USERS = FIND_USERS;
var USER_ORGANIZATION_FRAGMENT = (0, _graphqlTag["default"])(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  fragment partialUserOrganization on UserOrganization {\n    organizationId\n    name\n    parentName\n    parentId\n    roleName\n    roleId\n    default\n    permissions\n  }\n"])));
exports.USER_ORGANIZATION_FRAGMENT = USER_ORGANIZATION_FRAGMENT;
var GET_USERS_BY_ORGANIZATION = (0, _graphqlTag["default"])(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query usersByOrganization($args: String!) {\n    usersByOrganization(args: $args) {\n      avatar\n      id\n      email\n      name\n      roleId\n      roleName\n    }\n  }\n"])));
exports.GET_USERS_BY_ORGANIZATION = GET_USERS_BY_ORGANIZATION;
var GET_EVENT_DOCTORS_BY_ORGANIZATION = (0, _graphqlTag["default"])(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query getDoctorsByOrganizationAndSchedue($args: EventDoctorsByOrganizationInput!) {\n    eventDoctorsByOrganization(args: $args) {\n      id\n      schedule {\n        id\n        from\n        to\n      }\n    }\n  }\n"])));
exports.GET_EVENT_DOCTORS_BY_ORGANIZATION = GET_EVENT_DOCTORS_BY_ORGANIZATION;
var GET_USER_INFORMATION = (0, _graphqlTag["default"])(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  query getUserInformation {\n    userInformation {\n      user {\n        id\n        email\n        name\n        lastName\n        dateOfBirth\n        avatar\n        featureFlags\n      }\n      activeOrganization {\n        ...partialUserOrganization\n      }\n      organizations {\n        organizationId\n        name\n      }\n      activeMedicalConsultation {\n        id\n        patientName\n        turn\n        turnId\n        status\n        statusName\n        duration\n        startedAt\n      }\n    }\n  }\n\n  ", "\n"])), USER_ORGANIZATION_FRAGMENT);
exports.GET_USER_INFORMATION = GET_USER_INFORMATION;