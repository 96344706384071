import * as React from 'react'

export const InvoicingIconEnum = Object.freeze({
  INVOICE:
    'M17,18C15.89,18 15,18.89 15,20A2,2 0 0,0 17,22A2,2 0 0,0 19,20C19,18.89 18.1,18 17,18M1,2V4H3L6.6,11.59L5.24,14.04C5.09,14.32 5,14.65 5,15A2,2 0 0,0 7,17H19V15H7.42A0.25,0.25 0 0,1 7.17,14.75C7.17,14.7 7.18,14.66 7.2,14.63L8.1,13H15.55C16.3,13 16.96,12.58 17.3,11.97L20.88,5.5C20.95,5.34 21,5.17 21,5A1,1 0 0,0 20,4H5.21L4.27,2M7,18C5.89,18 5,18.89 5,20A2,2 0 0,0 7,22A2,2 0 0,0 9,20C9,18.89 8.1,18 7,18Z',
})

const invoicingConfig = {
  setting: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/invoice/list',
      viewPermission: 'FA-VO',
      component: React.lazy(() => import('modules/invoicing/Invoices')),
    },
    {
      path: '/invoice/view/:uid',
      viewPermission: 'FA-VO',
      component: React.lazy(() => import('modules/invoicing/InvoiceView')),
    },
    {
      path: '/invoice/edit/:uid',
      viewPermission: 'FA-VO',
      component: React.lazy(() => import('modules/invoicing/InvoiceUpsert')),
    },
    {
      path: '/invoice/create',
      viewPermission: 'FA-VO',
      component: React.lazy(() => import('modules/invoicing/InvoiceUpsert')),
    },
  ],
}

const invoicingNavigation = {
  id: 'invoices',
  title: 'Facturas',
  type: 'collapse',
  icon: 'receipt',
  viewPermission: 'FA-VO',
  children: [
    {
      id: 'invoice',
      title: 'Facturas',
      type: 'item',
      icon: 'receipt',
      url: '/invoice/list',
    },
    {
      id: 'create-invoice',
      title: 'Crear Factura',
      type: 'item',
      svgIcon: InvoicingIconEnum.INVOICE,
      url: '/invoice/create',
    },
  ],
}

export { invoicingConfig, invoicingNavigation }
