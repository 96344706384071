import React, { useCallback, useEffect, useRef, useState } from 'react'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  title: {
    color: 'white',
  },
}))

const Countdown = React.memo(({ start = true, ...props }) => {
  const classes = useStyles()
  const { onComplete } = props

  const [endDate, setEndDate] = useState(
    moment.isMoment(props.endDate) ? props.endDate : moment(props.endDate),
  )

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })
  const intervalRef = useRef()

  const complete = useCallback(() => {
    window.clearInterval(intervalRef.current)
    if (onComplete) {
      onComplete()
    }
  }, [onComplete])

  useEffect(() => {
    setEndDate(moment.isMoment(props.endDate) ? props.endDate : moment(props.endDate))
  }, [setEndDate, props.endDate])

  const tick = useCallback(() => {
    const currDate = moment()
    const diff = endDate.diff(currDate, 'seconds')
    if (diff < 0) {
      complete()
      return
    }
    const timeLeft = moment.duration(Number.isNaN(diff) ? 0 : diff, 'seconds')
    setCountdown({
      days: timeLeft.asDays().toFixed(0),
      hours: timeLeft.hours(),
      minutes: timeLeft.minutes(),
      seconds: timeLeft.seconds(),
    })
  }, [complete, endDate])

  useEffect(() => {
    if (!start) {
      tick()
      return () => undefined
    }
    intervalRef.current = setInterval(tick, 1000)
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [tick, start])

  const { days, hours, minutes, seconds } = countdown

  return (
    <div className={clsx('flex items-center', props.className)}>
      {days > 0 && (
        <div className="flex flex-col items-center justify-center px-6">
          <Typography variant="h5" className="mb-4">
            {days}
          </Typography>
          <Typography variant="caption" color="textSecondary" className={classes.title}>
            Dias
          </Typography>
        </div>
      )}
      {hours > 0 && (
        <div className="flex flex-col items-center justify-center px-6">
          <Typography variant="h5" className="mb-4">
            {hours}
          </Typography>
          <Typography variant="caption" color="textSecondary" className={classes.title}>
            Horas
          </Typography>
        </div>
      )}
      <div className="flex flex-col items-center justify-center px-6">
        <Typography variant="h5" className="mb-4">
          {minutes}
        </Typography>
        <Typography variant="caption" color="textSecondary" className={classes.title}>
          Minutos
        </Typography>
      </div>
      <div className="flex flex-col items-center justify-center px-6">
        <Typography variant="h5" className="mb-4">
          {seconds}
        </Typography>
        <Typography variant="caption" color="textSecondary" className={classes.title}>
          Segundos
        </Typography>
      </div>
    </div>
  )
})

Countdown.propTypes = {
  start: PropTypes.bool,
  className: PropTypes.string,
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onComplete: PropTypes.func,
}

export { Countdown }
