"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useRoom = void 0;

var _react = require("react");

var _events = _interopRequireDefault(require("events"));

var _twilioVideo = _interopRequireDefault(require("twilio-video"));

var _utils = require("../shared/utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

window.TwilioVideo = _twilioVideo["default"];

var useRoom = function useRoom(localTracks, onError, options) {
  var _useState = (0, _react.useState)(new _events["default"]()),
      _useState2 = _slicedToArray(_useState, 2),
      room = _useState2[0],
      setRoom = _useState2[1];

  var _useState3 = (0, _react.useState)(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isConnecting = _useState4[0],
      setIsConnecting = _useState4[1];

  var localTracksRef = (0, _react.useRef)([]);
  (0, _react.useEffect)(function () {
    // It can take a moment for Video.connect to connect to a room. During this time, the user may have enabled or disabled their
    // local audio or video tracks. If this happens, we store the localTracks in this ref, so that they are correctly published
    // once the user is connected to the room.
    localTracksRef.current = localTracks;
  }, [localTracks]);
  var connect = (0, _react.useCallback)(function (data) {
    setIsConnecting(true);
    return _twilioVideo["default"].connect(data.token, _objectSpread(_objectSpread({}, options), {}, {
      name: data.id,
      tracks: []
    })).then(function (newRoom) {
      setRoom(newRoom);

      var disconnect = function disconnect() {
        return newRoom.disconnect();
      };

      newRoom.once('disconnected', function () {
        // Reset the room only after all other `disconnected` listeners have been called.
        setTimeout(function () {
          return setRoom(new _events["default"]());
        });
        window.removeEventListener('beforeunload', disconnect);

        if (_utils.isMobile) {
          window.removeEventListener('pagehide', disconnect);
        }
      }); // @ts-ignore

      window.twilioRoom = newRoom;
      localTracksRef.current.forEach(function (track) {
        return (// Tracks can be supplied as arguments to the Video.connect() function and they will automatically be published.
          // However, tracks must be published manually in order to set the priority on them.
          // All video tracks are published with 'low' priority. This works because the video
          // track that is displayed in the 'MainParticipant' component will have it's priority
          // set to 'high' via track.setPriority()
          newRoom.localParticipant.publishTrack(track, {
            priority: track.kind === 'video' ? 'low' : 'standard'
          })
        );
      });
      setIsConnecting(false); // Add a listener to disconnect from the room when a user closes their browser

      window.addEventListener('beforeunload', disconnect);

      if (_utils.isMobile) {
        // Add a listener to disconnect from the room when a mobile user closes their browser
        window.addEventListener('pagehide', disconnect);
      }
    }, function (error) {
      onError(error);
      setIsConnecting(false);
    });
  }, [options, onError]);
  return {
    room: room,
    isConnecting: isConnecting,
    connect: connect
  };
};

exports.useRoom = useRoom;