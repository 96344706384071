"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useHandleRoomDisconnectionErrors = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var useHandleRoomDisconnectionErrors = function useHandleRoomDisconnectionErrors(room, onError) {
  _react["default"].useEffect(function () {
    var onDisconnected = function onDisconnected(room, error) {
      if (error) {
        onError(error);
      }
    };

    room.on('disconnected', onDisconnected);
    return function () {
      room.off('disconnected', onDisconnected);
    };
  }, [room, onError]);
};

exports.useHandleRoomDisconnectionErrors = useHandleRoomDisconnectionErrors;