"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_LABORATORY_TEST = exports.GET_CIE_10 = exports.GET_KEYLIST = exports.GET_CUSTOM_KEYS = exports.getKeys = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

var _fragments = require("./fragments");

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var getKeys = function getKeys(name) {
  return "\n    ".concat(name, " {\n      ...KeylistFragment\n    }\n  ");
};

exports.getKeys = getKeys;

var GET_CUSTOM_KEYS = function GET_CUSTOM_KEYS() {
  for (var _len = arguments.length, names = new Array(_len), _key = 0; _key < _len; _key++) {
    names[_key] = arguments[_key];
  }

  return (0, _graphqlTag["default"])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  {\n    ", "\n  }\n"])), names.map(getKeys).join('\n'));
};

exports.GET_CUSTOM_KEYS = GET_CUSTOM_KEYS;

var GET_KEYLIST = function GET_KEYLIST() {
  for (var _len2 = arguments.length, name = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    name[_key2] = arguments[_key2];
  }

  return (0, _graphqlTag["default"])(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  query GetKeylists {\n    keylist {\n      ", "\n    }\n  }\n\n  ", "\n"])), name.map(getKeys).join('\n'), _fragments.fragments.keylist);
};

exports.GET_KEYLIST = GET_KEYLIST;
var GET_CIE_10 = (0, _graphqlTag["default"])(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query GetCie10s($args: QueryInput!) {\n    cie10Search(args: $args) {\n      id\n      name\n    }\n  }\n"])));
exports.GET_CIE_10 = GET_CIE_10;
var GET_LABORATORY_TEST = (0, _graphqlTag["default"])(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query GetLaboratoryTest($args: QueryInput!) {\n    laboratoryTestSearch(args: $args) {\n      id\n      name\n      category\n    }\n  }\n"])));
exports.GET_LABORATORY_TEST = GET_LABORATORY_TEST;