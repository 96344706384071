import React from 'react'
import { useMutation } from '@apollo/client'
import { FINISH_TURN } from '@recmed/appsync/lib/mutations/turn'
import { useSelector } from 'react-redux'

import { useSnackbar } from 'components/hocs/with-snackbar'

const useFinishTurn = ({ id, refetchQueries = [] }) => {
  const { activeOrganization } = useSelector(({ auth }) => auth.user)
  const [finishTurn, { loading }] = useMutation(FINISH_TURN)
  const { displayMessage } = useSnackbar()

  const submit = React.useCallback(async () => {
    try {
      await finishTurn({
        awaitRefetchQueries: true,
        refetchQueries: [...refetchQueries, 'getUserInformation'],
        variables: {
          organizationId: activeOrganization.organizationId,
          args: {
            id,
          },
        },
      })
    } catch (e) {
      displayMessage({ message: e, variant: 'error' })
      throw e
    }
  }, [activeOrganization.organizationId, displayMessage, finishTurn, id, refetchQueries])

  return {
    finishTurn: submit,
    loading,
  }
}

export { useFinishTurn }
