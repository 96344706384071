import React from 'react'

import qs from 'query-string'
import cn from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import { darken } from '@material-ui/core/styles/colorManipulator'
import FuseAnimate from '@recmed-do/template/lib/@fuse/core/FuseAnimate'
import Typography from '@material-ui/core/Typography'
import FuseLoading from '@recmed-do/template/lib/@fuse/core/FuseLoading'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

const { REACT_APP_API_ENVIRONMENT } = process.env

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    height: '100vh !important',
    width: '100% !important',
    color: theme.palette.primary.contrastText,
    background: `radial-gradient(${darken(theme.palette.primary.dark, 0.5)} 0%, ${
      theme.palette.primary.dark
    } 80%)`,
    '& p': {
      color: theme.palette.primary.contrastText,
    },
  },
  divider: {
    backgroundColor: theme.palette.divider,
  },
}))

const PatientAppointment = () => {
  const [loading, setLoading] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const location = useLocation()
  const classes = useStyles()

  React.useEffect(() => {
    const refetch = async () => {
      if (!location.search) return
      try {
        setLoading(true)
        const path = `/api/${REACT_APP_API_ENVIRONMENT}-external/v2/updateStatus`
        const data = await fetch(path, {
          method: 'POST',
          body: JSON.stringify(qs.parse(location.search)),
        }).then((data) => data.json())
        setMessage(data?.message)
      } catch (e) {
        setMessage(e.message)
      } finally {
        setLoading(false)
      }
    }
    refetch()
  }, [location.search])

  return (
    <div
      className={cn(
        classes.root,
        'flex flex-col flex-auto flex-no-shrink items-center justify-center p-32',
      )}
    >
      <div className="flex flex-col items-center justify-center w-full">
        {loading && <FuseLoading />}
        {!loading && (
          <>
            <FuseAnimate animation={{ translateY: [0, '100%'] }} duration={600}>
              <Card className="w-full max-w-384">
                <CardContent className="flex flex-col items-center justify-center p-32">
                  <Typography variant="subtitle1">{message}</Typography>
                </CardContent>
              </Card>
            </FuseAnimate>
          </>
        )}
      </div>
    </div>
  )
}

export { PatientAppointment }
