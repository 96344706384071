import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import { useCreateMedicalConsultation } from 'components/hooks/use-create-medical-consultation'
import { useClickAway } from 'react-use'

const useStyles = makeStyles((theme) => ({
  eventTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  eventText: {
    fontSize: 11,
  },
  patientName: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
  },
  icon: {
    marginRight: 5,
  },
}))

const CalendarEventCard = ({ resource, event }) => {
  const ref = React.useRef()
  const classes = useStyles()
  const type = resource.isVideoConfererence ? 'Video Consulta' : 'Consulta'
  const { patientName, patient } = resource
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { createMedicalConsultation } = useCreateMedicalConsultation({ patientId: patient?.id })

  useClickAway(ref, () => {
    setAnchorEl(null)
  })

  const handleRightClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = (e) => {
    setAnchorEl(null)
  }

  const handleCreateMedicalConsultation = async (e) => {
    await createMedicalConsultation()
    setAnchorEl(null)
  }

  return resource.type === 'EV' ? (
    <div ref={ref}>
      <Box onContextMenu={handleRightClick}>
        <Box key={event.id}>
          <Typography className={classes.eventTitle}>{event?.title}</Typography>
          <Typography className={classes.eventText}>{event?.resource?.description}</Typography>
        </Box>
      </Box>
      <Menu
        id="event-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={handleClose}
      >
        <MenuItem>
          <EditIcon fontSize="small" color="primary" className={classes.icon} />
          Editar
        </MenuItem>
      </Menu>
    </div>
  ) : (
    <div ref={ref}>
      <Box onContextMenu={handleRightClick}>
        <Box key={event.id}>
          <Typography className={classes.patientName}>{`${type} - ${patientName}`}</Typography>
          <Typography className={classes.eventText}>{`${patient?.insurance} ${
            patient?.insurancePlan ? `- ${patient?.insurancePlan}` : ``
          }
      ${patient?.affiliationNo ? `- ${patient?.affiliationNo}` : ``}
      `}</Typography>
        </Box>
      </Box>
      <Menu
        id="consulta-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleCreateMedicalConsultation}>
          <PlayCircleFilledIcon fontSize="small" color="primary" className={classes.icon} />
          Iniciar consulta
        </MenuItem>
        <MenuItem>
          <EditIcon fontSize="small" color="primary" className={classes.icon} />
          Editar
        </MenuItem>
      </Menu>
    </div>
  )
}

export default CalendarEventCard
