import * as yup from 'yup'

import _ from '@recmed-do/template/lib/@lodash'

import moment from 'moment-timezone'

const momentDateTransform = (date) => {
  if (!date) return undefined
  return moment(date).format('YYYY-MM-DD')
}

const yupDate = () => yup.string().transform(momentDateTransform)

const yupSetLocale = () => {
  yup.setLocale({
    mixed: {
      nullable: 'Este campo es requerido.',
      required: 'Este campo es requerido.',
      null: 'Este campo es requerido.',
      cast: 'Este campo es requerido.',
      default: 'Este campo es requerido.',
      typeError: 'Este campo es requerido.',
      type: 'Este campo es requerido.',
      nan: 'Este campo es requerido.',
      NaN: 'Este campo es requerido.',
      isType: 'Este campo es requerido.',
      max: ({ max }) => `Este campo tiene que ser menor que ${max}`,
      min: ({ min }) => `Este campo tiene que ser mayor que ${min}`,
    },
    string: {
      email: 'Este campo tiene que ser un email valido.',
      max: ({ max }) => `Este campo tiene que tener un máximo de ${max} caracteres.`,
      min: ({ min }) => `Este campo tiene que tener un mínimo de ${min} caracteres.`,
    },
    number: {
      cast: 'Este campo es requerido.',
      type: 'Este campo es requerido.',
      NaN: 'Este campo es requerido.',
      min: ({ min }) => `Este campo tiene que ser mayor que ${min}`,
      max: ({ max }) => `Este campo tiene que ser menor que ${max}`,
    },
    date: {
      required: 'Este campo es requerido.',
      cast: 'Este campo es requerido.',
      default: 'Este campo es requerido.',
      typeError: 'Este campo es requerido.',
      type: 'Este campo es requerido.',
      nan: 'Este campo es requerido.',
      NaN: 'Este campo es requerido.',
      isType: 'Este campo es requerido.',
      max: ({ max }) =>
        `La fecha tiene que ser menor que ${moment(max).format('MMMM D, YYYY hh:mm A')}`,
      min: ({ min }) =>
        `La fecha tiene que ser mayor que ${moment(min).format('MMMM D, YYYY hh:mm A')}`,
    },
    boolean: {
      default: 'Este campo es requerido.',
      required: 'Este campo es requerido.',
    },
  })
}

const findTypeByValue = (values) => (key) => {
  const value = values[key]
  return Object.keys(value).find((key) => Object.keys(_.pickBy(value[key], _.identity)).length)
}

const findValuesBy = (values) => (key) => {
  const type = findTypeByValue(values)(key)
  return values[key][type]
}

const transformFilter = (values = {}) => {
  const getType = findTypeByValue(values)
  const getValues = findValuesBy(values)
  return Object.keys(values).reduce((acc, key) => {
    const type = getType(key)
    if (!type) return acc
    return { ...acc, [key]: { type, ...getValues(key) } }
  }, {})
}

const yupFilterString = () =>
  yup.object().shape({
    IS_EMPTY: yup.object().shape({ value: yup.boolean() }),
    EQUALS: yup.object().shape({ value: yup.string() }),
    START_WITH: yup.object().shape({ value: yup.string() }),
    END_WITH: yup.object().shape({ value: yup.string() }),
    CONTAINS: yup.object().shape({ value: yup.string() }),
    IN: yup.object().shape({ value: yup.array() }),
  })

const yupFilterDate = () =>
  yup.object().shape({
    IS_EMPTY: yup.object().shape({ value: yup.boolean() }),
    EQUALS: yup.object().shape({ value: yup.string() }),
    BETWEEN: yup.object().shape({
      start: yup.string().nullable().transform(momentDateTransform).default(null),
      end: yup.string().nullable().transform(momentDateTransform).default(null),
    }),
    MORE_THAN: yup.object().shape({ value: yup.string() }),
    MORE_THAN_OR_EQUAL: yup.object().shape({ value: yup.string() }),
    LESS_THAN: yup.object().shape({ value: yup.string() }),
    LESS_THAN_OR_EQUAL: yup.object().shape({ value: yup.string() }),
    IN: yup.object().shape({ value: yup.array() }),
  })

const partialFormValidation = yup
  .object()
  .shape({
    type: yup.string().required(),
    value: yup.mixed().when('type', {
      is: (value) => ['IS_EMPTY', 'IS_NOT_EMPTY'].includes(value),
      then: yup.string().nullable().notRequired(),
      otherwise: yup.string().required(),
    }),
  })
  .nullable()
  .notRequired()

export {
  yupFilterString,
  yupFilterDate,
  transformFilter,
  yupDate,
  partialFormValidation,
  yupSetLocale,
}
