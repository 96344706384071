import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { v4 } from 'uuid'
import moment from 'moment-timezone'
import _ from '@recmed-do/template/lib/@lodash'
import { useForm, FormProvider } from 'react-hook-form'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import { useMutation } from '@apollo/client'
import { CREATE_PATIENT } from '@recmed/appsync/lib/mutations/patient'
import {
  AppBar,
  Button,
  DialogActions,
  Toolbar,
  Typography,
  Dialog,
  DialogContent,
} from '@material-ui/core'

import { SnackbarContext } from 'components/hocs/with-snackbar'
import { useSplashScreen } from 'components/hocs/with-splash-screen'
import PatientForm from 'components/molecules/patient-form'
import { PatientSchema } from 'shared/utils/patient'
import { yupResolver } from '@hookform/resolvers/yup'

const PatientCreateModal = ({ fullScreen, visible, onClose }) => {
  const { setSplashVisible } = useSplashScreen()
  const methods = useForm({
    defaultValues: { dateOfBirth: null },
    resolver: yupResolver(PatientSchema),
  })
  const { handleSubmit } = methods
  const { displayMessage } = useContext(SnackbarContext)
  const [onCreatePatient] = useMutation(CREATE_PATIENT)

  const handleAction = handleSubmit(async (payload) => {
    try {
      setSplashVisible(true)
      await onCreatePatient({
        variables: {
          args: {
            ...payload,
            dateOfBirth: moment(_.get(payload, 'dateOfBirth')).format('YYYY-MM-DD'),
            document: _.get(payload, 'document', '').replace(/-/g, ''),
            id: v4(),
          },
        },
      })

      displayMessage({ message: 'Paciente creado correctamente.' })
      if (onClose) {
        onClose(true)
      }
    } catch (e) {
      displayMessage({
        message: e,
        variant: 'error',
      })
    } finally {
      setSplashVisible(false)
    }
  })

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
      open={visible}
      onClose={() => onClose()}
    >
      <AppBar position="static" elevation={1}>
        <Toolbar className="flex">
          <Typography variant="subtitle1" color="inherit">
            Creación Rapida de Paciente
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <FormProvider {...methods}>
          <PatientForm />
        </FormProvider>
      </DialogContent>

      <DialogActions className="justify-between pl-8 sm:pl-16">
        <Button variant="contained" color="primary" onClick={() => onClose()}>
          Cerrar
        </Button>
        <Button variant="contained" color="primary" onClick={handleAction}>
          Crear Paciente
        </Button>
      </DialogActions>
    </Dialog>
  )
}

PatientCreateModal.propTypes = {
  fullScreen: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
}

export default withMobileDialog()(PatientCreateModal)
