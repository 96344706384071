import FuseLoadable from 'shared/utils/FuseLoadable'

const accountingConfig = {
  setting: {
    layout: {
      config: {},
    },
  },

  routes: [
    {
      path: '/accounting/income',
      viewPermission: 'GA-CC-VO',
      component: FuseLoadable({
        loader: () => import('modules/accounting/Income'),
      }),
    },
    {
      path: '/accounting/expenses',
      viewPermission: 'GA-CC-VO',
      component: FuseLoadable({
        loader: () => import('modules/accounting/Expenses'),
      }),
    },
    {
      path: '/accounting/transactions',
      viewPermission: 'GA-CC-VO',
      component: FuseLoadable({
        loader: () => import('modules/accounting/Transactions'),
      }),
    },
    {
      path: '/accounting/daily-entry',
      viewPermission: 'GA-DE-VO',
      component: FuseLoadable({
        loader: () => import('modules/accounting/DailyEntry'),
      }),
    },
  ],
}
const accountingNavigation = {
  id: 'accounting',
  title: 'Contabilidad',
  viewPermission: 'GA-VO',
  type: 'collapse',
  icon: 'account_balance',
  children: [
    {
      id: 'accounting-inconme',
      title: 'Ingresos',
      viewPermission: 'GA-CC-VO',
      type: 'item',
      icon: 'trending_up',
      url: '/accounting/income',
    },
    {
      id: 'accounting-outcome',
      title: 'Gastos',
      viewPermission: 'GA-CC-VO',
      type: 'item',
      icon: 'trending_down',
      url: '/accounting/expenses',
    },
    {
      id: 'transactions',
      title: 'Transacciones',
      viewPermission: 'GA-CC-VO',
      type: 'item',
      icon: 'import_export',
      url: '/accounting/transactions',
    },
    {
      id: 'daily-entry',
      title: 'Entrada de Diario',
      viewPermission: 'GA-DE-VO',
      type: 'item',
      icon: 'library_books',
      url: '/accounting/daily-entry',
    },
  ],
}

export { accountingConfig, accountingNavigation }
