import React from 'react'
import PropTypes from 'prop-types'

import cs from 'clsx'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  '@keyframes latidos': {
    from: { transform: 'none' },
    '50%': { transform: 'scale(1.2)' },
    to: { transform: 'none' },
  },
  animate: {
    animation: '$latidos 0.8s infinite',
  },
  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    justifyContent: 'center',
    padding: theme.spacing(5),
  },
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  circular: {
    marginTop: theme.spacing(2),
  },
  loading: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    fontSize: 18,
  },
}))

const SplashScreenContext = React.createContext({
  setSplashVisible: (visible) => console.info(visible),
})

const SplashScreenWrapper = ({ children }) => {
  const [visible, setVisible] = React.useState(false)
  const classes = useStyles()

  return (
    <SplashScreenContext.Provider value={{ setSplashVisible: setVisible }}>
      {visible && (
        <Dialog
          open
          fullScreen
          classes={{
            root: classes.dialogContainer,
            paper: classes.paper,
          }}
        >
          <div className={classes.center}>
            <div className={cs('logo', classes.animate)}>
              <img width="128" src="assets/images/logos/recmed_heart.svg" alt="logo" />
            </div>
            <CircularProgress className={classes.circular} />
            <Typography variant="button" className={classes.loading}>
              Procesando, un momento por favor...
            </Typography>
          </div>
        </Dialog>
      )}
      {children}
    </SplashScreenContext.Provider>
  )
}

SplashScreenWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

const useSplashScreen = () => {
  return React.useContext(SplashScreenContext)
}

export { SplashScreenWrapper, useSplashScreen }
