import React from 'react'

import _ from '@recmed-do/template/lib/@lodash'
import moment from 'moment-timezone'
import * as yup from 'yup'

import clsx from 'clsx'
import { useMutation, useQuery } from '@apollo/client'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import FormControl from '@material-ui/core/FormControl'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FaceIcon from '@material-ui/icons/Face'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Popover from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/core/styles'
import PersonIcon from '@material-ui/icons/Person'
import NoteIcon from '@material-ui/icons/Notes'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import TimerIcon from '@material-ui/icons/Timer'
import InfoIcon from '@material-ui/icons/Info'
import Box from '@material-ui/core/Box'

import TextField from 'components/atoms/text-field'
import AssignmentIcon from '@material-ui/icons/Assignment'

import { SnackbarContext } from 'components/hocs/with-snackbar'

import { UPDATE_APPOINTMENT, UPDATE_STATUS } from '@recmed/appsync/lib/mutations/event'
import { GET_KEYLIST } from '@recmed/appsync/lib/queries'
import { DynamicUsersDropdown } from 'components/atoms/DynamicUsersDropdown'
import { DynamicFormDatePicker } from 'components/atoms/dynamic-form-date-picker'

const validationSchema = yup.object().shape({
  description: yup.string().required(),
  procedure: yup.string(),
  startDate: yup.date().required(),
  duration: yup.number().integer().positive().min(10).required(),
})

const getDefaultValues = ({ start, resource: { duration, timeZone, description, procedure } }) => ({
  description,
  duration,
  procedure,
  startDate: moment.tz(start, timeZone).toDate(),
})
const useStyles = makeStyles((theme) => ({
  root: {
    width: '40vw',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
    [theme.breakpoints.up('sm')]: {
      width: 580,
    },
    '&.fullscreen': {
      width: '100vw',
      height: '100vh',
    },
  },
  icon: {
    left: 0,
    marginRight: 20,
  },
}))
const UpdateAppointmentModal = ({ anchorEl, fullScreen, visible, onClose, initialValues }) => {
  const classes = useStyles()
  const methods = useForm({
    validationSchema,
    defaultValues: getDefaultValues(initialValues),
  })
  const {
    id,
    resource: { allowUpdate, allowedStatus = [], status },
  } = initialValues
  const { displayMessage } = React.useContext(SnackbarContext)

  const { control, handleSubmit } = methods
  const [updateAppointment] = useMutation(UPDATE_APPOINTMENT)
  const [updateStatus] = useMutation(UPDATE_STATUS)

  const { data, loading } = useQuery(GET_KEYLIST('eventStatuses'), {})

  const eventStatuses = _.get(data, 'keylist.eventStatuses', [])

  const handleUpdateStatus = (statusId) => async () => {
    try {
      await updateStatus({
        variables: {
          data: {
            id,
            status: statusId,
          },
        },
      })
      displayMessage({
        message: 'Estado actualizado existosamente',
      })
      if (onClose) {
        onClose(true)
      }
    } catch (e) {
      displayMessage({
        message: e,
        variant: 'error',
      })
      console.log(e)
    }
  }

  const handleAction = handleSubmit(async (payload) => {
    try {
      await updateAppointment({
        variables: {
          data: {
            ...payload,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            id: _.get(initialValues, 'id'),
          },
        },
      })
      displayMessage({
        message: 'Cita Actualizada Correctamente',
      })
      if (onClose) {
        onClose(true)
      }
    } catch (e) {
      displayMessage({
        message: e,
        variant: 'error',
      })
    }
  })

  return (
    <Popover
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={visible}
      onClose={onClose}
      classes={{ paper: clsx(classes.root, { fullscreen: fullScreen }) }}
      anchorEl={anchorEl}
    >
      <AppBar position="static" elevation={1}>
        <Toolbar className="flex">
          <Typography variant="subtitle1" color="inherit">
            Actualizar Consulta
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <FormProvider {...methods}>
          <Grid item xs={12} sm={12}>
            <Box display="flex" alignItems="center">
              <PersonIcon fontSize="medium" className={classes.icon} color="primary" />
              <Typography variant="h6" color="inherit">
                <strong>{_.get(initialValues, 'resource.patientName')}</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box display="flex" alignItems="center" className="mt-8 mb-8">
              <InfoIcon fontSize="medium" className={classes.icon} color="primary" />
              <Typography variant="h6" color="inherit">
                <strong>
                  {_.get(
                    eventStatuses.find(({ id }) => id === status),
                    'name',
                    status,
                  )}
                </strong>
              </Typography>
            </Box>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Box display="flex" alignItems="center">
                <FaceIcon fontSize="medium" className={classes.icon} color="primary" />
                <DynamicUsersDropdown
                  id="doctorId"
                  title="Doctor"
                  variant="outlined"
                  xs={12}
                  sm={12}
                />
              </Box>
            </Grid>
            <FormControl variant="outlined" component={Grid} item xs={12} sm={12}>
              <Box display="flex" alignItems="center">
                <CalendarTodayIcon color="primary" className={classes.icon} fontSize="medium" />
                <DynamicFormDatePicker title="Fecha de Inicio" id="startDate" xs={12} sm={12} />
                {/* <Datepicker mask="datetime" label="Fecha de Inicio" name="startDate" /> */}
              </Box>
            </FormControl>
            <FormControl component={Grid} item xs={12} sm={12}>
              <Box display="flex" alignItems="center">
                <TimerIcon className={classes.icon} color="primary" fontSize="medium" />
                <Controller
                  name="duration"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      type="number"
                      variant="outlined"
                      autoComplete="false"
                      label="Duración (minutos)"
                      {...field}
                    />
                  )}

                  // onChange={([
                  //   {
                  //     target: { value },
                  //   },
                  // ]) => {
                  //   return parseInt(value, 10)
                  // }}
                />
              </Box>
            </FormControl>
            <FormControl component={Grid} item xs={12} sm={12}>
              <Box display="flex" alignItems="center">
                <AssignmentIcon fontSize="medium" className={classes.icon} color="primary" />
                <Controller
                  render={({ field }) => (
                    <TextField
                      autoComplete="false"
                      label="Procedimiento"
                      variant="outlined"
                      fullWidth
                      {...field}
                    />
                  )}
                  name="procedure"
                  control={control}
                />
              </Box>
            </FormControl>
            <FormControl component={Grid} item xs={12} sm={12} className="sm:pb-8">
              <Box display="flex" alignItems="center">
                <NoteIcon className={classes.icon} fontSize="medium" color="primary" />
                <Controller
                  render={({ field }) => (
                    <TextField
                      multiline
                      rows={3}
                      autoComplete="false"
                      label="Razón de la cita"
                      variant="outlined"
                      fullWidth
                      {...field}
                    />
                  )}
                  name="description"
                  control={control}
                />
              </Box>
            </FormControl>
            {!loading && allowedStatus.length > 0 && (
              <Grid xs={12} sm={12} item>
                <Typography>Cambiar estado:</Typography>
              </Grid>
            )}
            {!loading &&
              allowedStatus.map((status) => (
                <Grid xs={12} sm={6} item key={status}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    onClick={handleUpdateStatus(status)}
                  >
                    {_.get(
                      eventStatuses.find(({ id }) => id === status),
                      'name',
                      status,
                    )}
                  </Button>
                </Grid>
              ))}
          </Grid>
        </FormProvider>
      </DialogContent>
      <DialogActions className="justify-between pl-8 sm:pl-16">
        <Button variant="contained" color="primary" onClick={onClose}>
          Cerrar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAction}
          disabled={!allowUpdate || loading}
        >
          Actualizar Cita
        </Button>
      </DialogActions>
    </Popover>
  )
}

export default withMobileDialog()(UpdateAppointmentModal)
