import React from 'react'
import { useLazyQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import TodayIcon from '@material-ui/icons/Today'
import NoteIcon from '@material-ui/icons/Note'
import formatDate from 'date-fns/format'
import isValidDate from 'date-fns/isValid'
import TimerIcon from '@material-ui/icons/Timer'
import PersonIcon from '@material-ui/icons/Person'
import AssignmentIcon from '@material-ui/icons/Assignment'
import { useSelector } from 'react-redux'
import { DynamicFormSwitch } from 'components/atoms/dynamic-form-switch'
import { DynamicFormInput } from 'components/atoms/dynamic-form-input'
import { DynamicFormDatePicker } from 'components/atoms/dynamic-form-date-picker'
import PatientLookup from 'components/organisisms/form-patient-lookup-input'
import Grid from '@material-ui/core/Grid'
import { FeatureFlagsEnum } from 'shared/enums/user'
import FaceIcon from '@material-ui/icons/Face'
import { DynamicFormDropdown } from 'components/atoms/dynamic-form-dropdown'
import { DynamicUsersDropdown } from 'components/atoms/DynamicUsersDropdown'
import { GET_EVENT_DOCTORS_BY_ORGANIZATION } from '@recmed/appsync/lib/queries/user'
import { useFormContext } from 'react-hook-form'
import { RoleEnum } from 'shared/enums/user'

const useStyles = makeStyles({
  icon: {
    left: 0,
    marginRight: 20,
  },
})

const CreateAppointmentForm = ({ hidePatient, disabledDoctor }) => {
  const { watch } = useFormContext()
  const { featureFlags = [], organizationId } = useSelector(({ auth }) => ({
    organizationId: auth.user.activeOrganization.organizationId,
    featureFlags: auth.user.user?.featureFlags,
  }))
  const includeFeature = featureFlags.includes(FeatureFlagsEnum.MEETINGS)
  const classes = useStyles()

  const [refetchDoctors, { data }] = useLazyQuery(GET_EVENT_DOCTORS_BY_ORGANIZATION, {
    fetchPolicy: 'cache-and-network',
  })

  const date = watch('event.date')
  const doctorId = watch('doctorId')

  React.useEffect(() => {
    if (!date || !isValidDate(new Date(date))) return
    refetchDoctors({
      variables: {
        args: {
          date: formatDate(date || new Date(), 'yyyy-MM-dd'),
          organizationId,
        },
      },
    })
  }, [date, organizationId, refetchDoctors])

  const slots = React.useMemo(() => {
    if (!doctorId || !data?.eventDoctorsByOrganization?.length) return []
    const doctor = data.eventDoctorsByOrganization.find(({ id }) => id === doctorId)

    return (
      doctor?.schedule?.map(({ from, to, id }) => ({
        value: id,
        name: `${formatDate(new Date(from), 'hh:mm a')} - ${formatDate(new Date(to), 'hh:mm a')}`,
      })) ?? []
    )
  }, [doctorId, data])

  return (
    <>
      {hidePatient && !includeFeature && <Grid xs={12} sm={12} item />}
      {includeFeature && (
        <DynamicFormSwitch id="isVirtual" title="Crear teleconsulta" xs={12} sm={6} />
      )}
      <Grid item xs={12} sm={12}>
        <Box display="flex" alignItems="center">
          <TodayIcon className={classes.icon} color="primary" />
          <DynamicFormDatePicker id="event.date" title="Fecha" xs={12} sm={12} disablePast />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box display="flex" alignItems="center">
          <FaceIcon className={classes.icon} color="primary" />
          <DynamicUsersDropdown
            id="doctorId"
            title="Médico"
            variant="outlined"
            xs={12}
            sm={12}
            disabled={disabledDoctor}
            roleId={RoleEnum.DOCTOR}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box display="flex" alignItems="center" width="100%">
          <TimerIcon className={classes.icon} color="primary" />
          <DynamicFormDropdown
            id="event.slot"
            title="Horario"
            options={slots}
            xs={12}
            sm={12}
            disabled={!doctorId || !slots.length}
          />
        </Box>
      </Grid>
      {!hidePatient && (
        <Grid xs={12} sm={12} item>
          <Box display="flex" alignItems="center">
            <PersonIcon className={classes.icon} color="primary" />
            <PatientLookup
              name="event.patientId"
              variant="outlined"
              title="Paciente"
              fullWidth
              disabled={!date}
            />
          </Box>
        </Grid>
      )}
      <Grid item xs={12} sm={12}>
        <Box display="flex" alignItems="center" width="100%">
          <AssignmentIcon className={classes.icon} color="primary" />
          <DynamicFormInput
            id="procedure"
            title="Procedimientos"
            xs={12}
            sm={12}
            disabled={!date}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box display="flex" alignItems="center">
          <NoteIcon className={classes.icon} color="primary" />
          <DynamicFormInput
            id="event.description"
            title="Razón de la cita"
            xs={12}
            sm={12}
            rows={3}
            multiline
            defaultValue=""
            disabled={!date}
          />
        </Box>
      </Grid>
    </>
  )
}

CreateAppointmentForm.propTypes = {
  hidePatient: PropTypes.bool.isRequired,
}

export { CreateAppointmentForm }
