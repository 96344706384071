"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDominantSpeaker = void 0;

var _react = require("react");

var _useVideoContext2 = require("./useVideoContext");

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var useDominantSpeaker = function useDominantSpeaker() {
  var _useVideoContext = (0, _useVideoContext2.useVideoContext)(),
      room = _useVideoContext.room;

  var _useState = (0, _react.useState)(room.dominantSpeaker),
      _useState2 = _slicedToArray(_useState, 2),
      dominantSpeaker = _useState2[0],
      setDominantSpeaker = _useState2[1];

  (0, _react.useEffect)(function () {
    // Sometimes, the 'dominantSpeakerChanged' event can emit 'null', which means that
    // there is no dominant speaker. If we change the main participant when 'null' is
    // emitted, the effect can be jarring to the user. Here we ignore any 'null' values
    // and continue to display the previous dominant speaker as the main participant.
    var handleDominantSpeakerChanged = function handleDominantSpeakerChanged(newDominantSpeaker) {
      if (newDominantSpeaker !== null) {
        setDominantSpeaker(newDominantSpeaker);
      }
    }; // Since 'null' values are ignored, we will need to listen for the 'participantDisconnected'
    // event, so we can set the dominantSpeaker to 'null' when they disconnect.


    var handleParticipantDisconnected = function handleParticipantDisconnected(participant) {
      setDominantSpeaker(function (prevDominantSpeaker) {
        return prevDominantSpeaker === participant ? null : prevDominantSpeaker;
      });
    };

    room.on('dominantSpeakerChanged', handleDominantSpeakerChanged);
    room.on('participantDisconnected', handleParticipantDisconnected);
    return function () {
      room.off('dominantSpeakerChanged', handleDominantSpeakerChanged);
      room.off('participantDisconnected', handleParticipantDisconnected);
    };
  }, [room]);
  return dominantSpeaker;
};

exports.useDominantSpeaker = useDominantSpeaker;