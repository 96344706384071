"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getResolution = exports.RenderDimensions = void 0;
var RenderDimensions = [{
  label: 'Low (160 x 90)',
  value: 'low',
  resolution: {
    width: 160,
    height: 90
  }
}, {
  label: 'CIF (352 x 288)',
  value: 'cif',
  resolution: {
    width: 352,
    height: 288
  }
}, {
  label: 'VGA (640 x 480)',
  value: 'vga',
  resolution: {
    width: 640,
    height: 480
  }
}, {
  label: 'WVGA (800 x 480)',
  value: 'wvga',
  resolution: {
    width: 800,
    height: 480
  }
}, {
  label: 'HD 540P (960 x 540)',
  value: '540p',
  resolution: {
    width: 960,
    height: 540
  }
}, {
  label: 'HD 720P (1280 x 720)',
  value: '720p',
  resolution: {
    width: 1280,
    height: 720
  }
}, {
  label: 'HD 960P (1280 x 960)',
  value: '960p',
  resolution: {
    width: 1280,
    height: 960
  }
}, {
  label: 'HD Standard 1080P (1440 x 1080)',
  value: 'standard1080p',
  resolution: {
    width: 1440,
    height: 1080
  }
}, {
  label: 'HD Widescreen 1080P (1920 x 1080)',
  value: 'wide1080p',
  resolution: {
    width: 1920,
    height: 1080
  }
}, {
  label: 'Server Default',
  value: 'default',
  resolution: undefined
}];
exports.RenderDimensions = RenderDimensions;

var getResolution = function getResolution(value) {
  var _RenderDimensions$fin;

  if (typeof value === 'undefined') {
    return undefined;
  }

  return (_RenderDimensions$fin = RenderDimensions.find(function (item) {
    return item.value === value;
  })) === null || _RenderDimensions$fin === void 0 ? void 0 : _RenderDimensions$fin.resolution;
};

exports.getResolution = getResolution;