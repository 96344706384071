import React from 'react'

import moment from 'moment-timezone'

import { useFormContext } from 'react-hook-form'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'

import withErrors from 'components/hocs/with-errors'

const masks = {
  date: {
    format: 'dd/MM/yyyy',
    regex: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  },
  datetime: {
    format: 'dd/MM/yyyy hh:mm a',
    regex: [
      /\d/,
      /\d/,
      '/',
      /\d/,
      /\d/,
      '/',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      ' ',
      /\d/,
      /\d/,
      ':',
      /\d/,
      /\d/,
      ' ',
      /(A|P)/,
      'M',
    ],
  },
}

const Datepicker = ({ mask = 'date', registerOptions, helperText, defaultValue, ...props }) => {
  const { name } = props
  const wasSet = React.useRef(false)
  const { setValue, register, clearErrors, watch } = useFormContext()
  const { format } = React.useMemo(() => masks[mask], [mask])
  const [value, setVal] = React.useState(() => watch(name))

  const Component = mask === 'date' ? KeyboardDatePicker : KeyboardDateTimePicker

  React.useEffect(() => {
    register(name, { ...registerOptions })
  }, [register, registerOptions, name])

  React.useEffect(() => {
    if (!defaultValue || wasSet.current) return
    setVal(defaultValue)
    setValue(name, defaultValue)
    wasSet.current = true
  }, [defaultValue, name, setValue])

  const handleChange = (date) => {
    const val = mask === 'date' && date ? moment(date).endOf('day').toDate() : date
    setVal(val)
    setValue(name, val)
    if (date) {
      clearErrors(name)
    }
  }

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <Component
          inputVariant="outlined"
          invalidDateMessage="Fecha Invalida"
          helperText={helperText}
          showTodayButton
          format={format}
          name={name}
          ampm
          {...props}
          value={value}
          onChange={handleChange}
        />
      </MuiPickersUtilsProvider>
    </>
  )
}

export default withErrors(Datepicker)
