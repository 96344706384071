import PropTypes from 'prop-types'

const FormField = {
  id: PropTypes.string.isRequired,
  parentPrefix: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  xs: PropTypes.number,
  sm: PropTypes.number,
  suffix: PropTypes.string,
  mask: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

export { FormField }
