import React from 'react'

import { useFormContext } from 'react-hook-form'
import Icon from '@material-ui/core/Icon'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TodayIcon from '@material-ui/icons/Today'
import TitleIcon from '@material-ui/icons/Title'
import FaceIcon from '@material-ui/icons/Face'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import HourglassFullIcon from '@material-ui/icons/HourglassFull'
import DescriptionIcon from '@material-ui/icons/Description'
import { makeStyles } from '@material-ui/core/styles'

import { DynamicFormInput } from 'components/atoms/dynamic-form-input'
import { DynamicFormDateTimePicker } from 'components/atoms/DynamicFormDateTimePicker'
import { DynamicFormSwitch } from 'components/atoms/dynamic-form-switch'
import { DynamicFormDatePicker } from 'components/atoms/dynamic-form-date-picker'
import { DynamicUsersDropdown } from 'components/atoms/DynamicUsersDropdown'
import { RoleEnum } from 'shared/enums/user'

const useStyles = makeStyles({
  icon: {
    left: 0,
    marginRight: 20,
  },
})

const CreateEventForm = ({ disabledDoctor }) => {
  const { watch } = useFormContext()
  const allDay = watch('event.allDay')
  const classes = useStyles()
  return (
    <>
      <DynamicFormSwitch id="event.allDay" title="Todo el dia" xs={6} sm={6} />
      <Grid item xs={12} sm={12}>
        <Box display="flex" alignItems="center">
          <FaceIcon className={classes.icon} color="primary" />
          <DynamicUsersDropdown
            id="doctorId"
            title="Doctor"
            variant="outlined"
            xs={12}
            disabled={disabledDoctor}
            sm={12}
            roleId={RoleEnum.DOCTOR}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box display="flex" alignItems="center">
          <TitleIcon className={classes.icon} color="primary" />
          <DynamicFormInput id="title" title="Título" xs={12} sm={12} fullWidth />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box display="flex" alignItems="center">
          <LocationOnIcon className={classes.icon} color="primary" />
          <DynamicFormInput
            id="event.location"
            prefix={<Icon>place_circle</Icon>}
            title="Ubicación del evento"
            xs={12}
            sm={12}
          />
        </Box>
      </Grid>
      {!allDay && (
        <>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center">
              <HourglassEmptyIcon className={classes.icon} color="primary" />
              <DynamicFormDateTimePicker
                id="event.startDate"
                title="Fecha Inicio"
                xs={12}
                sm={12}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center">
              <HourglassFullIcon className={classes.icon} color="primary" />
              <DynamicFormDateTimePicker id="event.endDate" title="Fecha Fin" xs={12} sm={12} />
            </Box>
          </Grid>
        </>
      )}
      {allDay && (
        <Grid item xs={12} sm={12}>
          <Box display="flex" alignItems="center">
            <TodayIcon className={classes.icon} color="primary" />
            <DynamicFormDatePicker id="event.startDate" title="Fecha" xs={12} sm={12} />
          </Box>
        </Grid>
      )}
      <Grid item xs={12} sm={12}>
        <Box display="flex" alignItems="center">
          <DescriptionIcon className={classes.icon} color="primary" />
          <DynamicFormInput
            id="event.description"
            title="Añade una descripción"
            xs={12}
            sm={12}
            rows={3}
            multiline
          />
        </Box>
      </Grid>
    </>
  )
}

export { CreateEventForm }
