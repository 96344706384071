"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_INSURANCE_PLANS = void 0;

var _graphqlTag = _interopRequireDefault(require("graphql-tag"));

var _templateObject;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var GET_INSURANCE_PLANS = (0, _graphqlTag["default"])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query insurancePlans($args: ID!) {\n    insurancePlans(insuranceId: $args) {\n      id\n      name\n    }\n  }\n"])));
exports.GET_INSURANCE_PLANS = GET_INSURANCE_PLANS;