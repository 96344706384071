import * as yup from 'yup'

const PatientSchema = yup.object().shape({
  documentType: yup.string().required(),
  document: yup.mixed().when('documentType', {
    is: (documentType) => documentType === 'CE',
    then: yup
      .string()
      .test('invalidId', 'Cédula inválida', (value) => {
        return value.replace(/-/g, '').trim().length === 11
      })
      .required(),
    otherwise: yup.mixed().when('documentType', {
      is: (documentType) => documentType === 'IS_UNDER_AGE',
      then: yup.string().nullable().notRequired(),
      otherwise: yup.string().min(8).max(15).required(),
    }),
  }),
  name: yup.string().required(),
  lastName: yup.string().required(),
  gender: yup.string().required(),
  dateOfBirth: yup.string().required().nullable(),
  phone: yup.string().required(),
  insurance: yup.string().required(),
  insurancePlan: yup.mixed().when('insurance', {
    is: (value) => ['PP'].includes(value),
    then: yup.string().nullable().notRequired(),
    otherwise: yup.string().required(),
  }),
  affiliationNo: yup.mixed().when('insurance', {
    is: (value) => ['PP'].includes(value),
    then: yup.string().nullable().notRequired(),
    otherwise: yup.string().required(),
  }),
  email: yup.string().email().nullable(),
  address: yup.string(),
  legalTutorDocument: yup.mixed().when('documentType', {
    is: (documentType) => documentType === 'IS_UNDER_AGE',
    otherwise: yup.string().nullable().notRequired(),
    then: yup.string().required(),
  }),
  legalTutorName: yup.mixed().when('documentType', {
    is: (documentType) => documentType === 'IS_UNDER_AGE',
    otherwise: yup.string().nullable().notRequired(),
    then: yup.string().required(),
  }),
})

export { PatientSchema }
