import React from 'react'

import _ from '@recmed-do/template/lib/@lodash'
import { useFormContext, Controller } from 'react-hook-form'

import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'

import { PlanKeylistSelect } from 'components/atoms/PlanKeylistSelect'
import { FormField } from 'shared/prop-types/form'

const PlanFormKeyList = ({ id, xs = 12, sm = 6, title, defaultValue, insurance, ...props }) => {
  const { control } = useFormContext()

  return (
    <FormControl
      component={Grid}
      item
      xs={xs}
      sm={sm}
      id={`grid-${id}`}
      style={{ display: 'flex' }}
    >
      <Controller
        render={({ field: { value = defaultValue, ...rest }, fieldState: { error } }) => {
          return (
            <PlanKeylistSelect
              id={id}
              insurance={insurance}
              error={error}
              title={title}
              value={value}
              {...props}
              {...rest}
            />
          )
        }}
        name={id}
        control={control}
      />
    </FormControl>
  )
}

PlanFormKeyList.propTypes = {
  ...FormField,
}

export { PlanFormKeyList }
