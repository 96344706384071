import React from 'react'

import _ from '@recmed-do/template/lib/@lodash'

import { useFormContext, Controller } from 'react-hook-form'
import MaskedInput from 'react-text-mask'

import InputAdornment from '@material-ui/core/InputAdornment'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { FormField } from 'shared/prop-types/form'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

function IDInput(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}
function PhoneInput(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

function RNCInput(props) {
  const { inputRef, ...other } = props

  const mask = (maskedValue = '') => {
    const value = maskedValue.replace(/-/g, '').trim()
    if (value.length <= 9) {
      return [/\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/]
    }

    return [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/]
  }

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={mask}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}
const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 12, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

const CurrencyInput = ({ inputRef, maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  })
  return (
    <MaskedInput
      mask={currencyMask}
      // ref={ref => {
      //   inputRef(ref ? ref.inputElement : null)
      // }}
      // placeholderChar={'\u2000'}
      // showMask
      {...inputProps}
    />
  )
}

const getMaskComponent = (mask) => {
  switch (mask) {
    case 'identification':
      return IDInput
    case 'phone':
      return PhoneInput
    case 'rnc':
      return RNCInput
    case 'currency':
      return CurrencyInput
    default:
      return undefined
  }
}

const DynamicFormInput = React.memo(
  ({
    rules,
    id,
    xs = 12,
    sm = 6,
    prefix,
    suffix,
    title,
    onChange,
    mask,
    defaultValue = '',
    type,
    multiline,
    rows,
    inputProps,
    ...props
  }) => {
    const { control } = useFormContext()

    return (
      <Controller
        render={({ fieldState: { error }, field: { value = '', ...rest } }) => {
          return (
            <FormControl
              component={Grid}
              item
              xs={xs}
              sm={sm}
              id={`grid-${id}`}
              style={{ display: 'flex' }}
            >
              <TextField
                {...rest}
                value={value}
                id={id}
                error={Boolean(error)}
                label={title}
                helperText={error ? error.message : ''}
                fullWidth
                variant="outlined"
                type={type}
                placeholder={title}
                multiline={multiline}
                rows={rows}
                autoComplete="off"
                InputProps={{
                  inputComponent: getMaskComponent(mask),
                  startAdornment:
                    typeof prefix === 'string' ? (
                      <InputAdornment position="start">
                        <Typography color="secondary">{prefix}</Typography>
                      </InputAdornment>
                    ) : (
                      prefix
                    ),
                  autoComplete: 'off',
                  endAdornment:
                    typeof suffix === 'string' ? (
                      <InputAdornment position="start">
                        <Typography color="secondary">{suffix}</Typography>
                      </InputAdornment>
                    ) : (
                      suffix
                    ),
                  ..._.omit({ ...inputProps }),
                }}
                {..._.omit({ ...props }, [
                  'margin',
                  'value',
                  'parentPrefix',
                  'InputProps',
                  'onChange',
                ])}
              />
            </FormControl>
          )
        }}
        rules={rules}
        control={control}
        name={id}
      />
    )
  },
)

DynamicFormInput.displayName = 'DynamicFormInput'

DynamicFormInput.propTypes = {
  ...FormField,
}

export { DynamicFormInput }
