"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AttachVisibilityHandler = void 0;

var _react = require("react");

var _utils = require("../../shared/utils");

var _useVideoContext2 = require("../../hooks/useVideoContext");

var _useLocalVideoToggle3 = require("../../hooks/useLocalVideoToggle");

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

/*
  This component adds a visibilitychange handler to the document when
  the user is using a mobile device. When the handler detects that
  the browser has been backgrounded, it unpublishes the users local
  video track. The browser cannot send video to the room when it has
  been backgrounded, so unpublishing the track stops video capture
  on the device, and triggers a UI update for all other participants
  to show that this user's video track has been turned off.
*/
var AttachVisibilityHandler = function AttachVisibilityHandler() {
  var _useVideoContext = (0, _useVideoContext2.useVideoContext)(),
      room = _useVideoContext.room;

  var _useLocalVideoToggle = (0, _useLocalVideoToggle3.useLocalVideoToggle)(),
      _useLocalVideoToggle2 = _slicedToArray(_useLocalVideoToggle, 2),
      isVideoEnabled = _useLocalVideoToggle2[0],
      toggleVideoEnabled = _useLocalVideoToggle2[1];

  var shouldRepublishVideoOnForeground = (0, _react.useRef)(false);
  (0, _react.useEffect)(function () {
    if (_utils.isMobile) {
      var handleVisibilityChange = function handleVisibilityChange() {
        // We don't need to unpublish the local video track if it has already been unpublished
        if (document.visibilityState === 'hidden' && isVideoEnabled) {
          shouldRepublishVideoOnForeground.current = true;
          toggleVideoEnabled(); // Don't publish the local video track if it wasn't published before the app was backgrounded
        } else if (shouldRepublishVideoOnForeground.current) {
          shouldRepublishVideoOnForeground.current = false;
          toggleVideoEnabled();
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);
      return function () {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }

    return function () {};
  }, [isVideoEnabled, room, toggleVideoEnabled]);
  return null;
};

exports.AttachVisibilityHandler = AttachVisibilityHandler;