import { v4 } from 'uuid'

import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import { useSnackbar } from 'components/hocs/with-snackbar'
import { CREATE_MEDICAL_CONSULTATION } from '@recmed/appsync/lib/mutations/medical-consultation'

const useCreateMedicalConsultation = ({ turnId, patientId, refetchQueries = [] }) => {
  const history = useHistory()

  const { displayMessage } = useSnackbar()
  const [createMedicalConsultation, { loading }] = useMutation(CREATE_MEDICAL_CONSULTATION)

  const handleCreate = async () => {
    try {
      const { data } = await createMedicalConsultation({
        awaitRefetchQueries: true,
        refetchQueries: [...refetchQueries, 'getUserInformation'],
        variables: {
          args: {
            patientId,
            turnId,
            id: v4(),
          },
        },
      })
      const id = data?.createMedicalConsultation
      if (id) {
        history.push(`/patient/medical-consultation/${id}`)
      }
    } catch (e) {
      displayMessage({ message: e, variant: 'error' })
    }
  }

  return { createMedicalConsultation: handleCreate, loading }
}

export { useCreateMedicalConsultation }
