"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useParticipants = void 0;

var _react = require("react");

var _useVideoContext2 = require("./useVideoContext");

var _useDominantSpeaker = require("./useDominantSpeaker");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var useParticipants = function useParticipants() {
  var _room$participants;

  var _useVideoContext = (0, _useVideoContext2.useVideoContext)(),
      room = _useVideoContext.room;

  var dominantSpeaker = (0, _useDominantSpeaker.useDominantSpeaker)();

  var _useState = (0, _react.useState)(Array.from((room === null || room === void 0 ? void 0 : (_room$participants = room.participants) === null || _room$participants === void 0 ? void 0 : _room$participants.values()) || [])),
      _useState2 = _slicedToArray(_useState, 2),
      participants = _useState2[0],
      setParticipants = _useState2[1]; // When the dominant speaker changes, they are moved to the front of the participants array.
  // This means that the most recent dominant speakers will always be near the top of the
  // ParticipantStrip component.


  (0, _react.useEffect)(function () {
    if (dominantSpeaker) {
      setParticipants(function (prevParticipants) {
        return [dominantSpeaker].concat(_toConsumableArray(prevParticipants.filter(function (participant) {
          return participant !== dominantSpeaker;
        })));
      });
    }
  }, [dominantSpeaker]);
  (0, _react.useEffect)(function () {
    var participantConnected = function participantConnected(participant) {
      return setParticipants(function (prevParticipants) {
        return [].concat(_toConsumableArray(prevParticipants), [participant]);
      });
    };

    var participantDisconnected = function participantDisconnected(participant) {
      return setParticipants(function (prevParticipants) {
        return prevParticipants.filter(function (p) {
          return p !== participant;
        });
      });
    };

    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    return function () {
      room.off('participantConnected', participantConnected);
      room.off('participantDisconnected', participantDisconnected);
    };
  }, [room]);
  return participants;
};

exports.useParticipants = useParticipants;